import React from 'react'
import {Button, Form, Input, message, Modal, Select, Space, Table, Upload, Image, DatePicker, Checkbox} from "antd";
import PATH_URL from "../../../utils/url";
import Axios from "../../../utils/axios";
import Util from "../../../utils/utils";
import style from "../Content.module.css";
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import zh_CN from "antd/es/date-picker/locale/zh_CN";

const {TextArea} = Input;
let userRole = null;
let userName = null;
const {RangePicker} = DatePicker;

class WorkOrderInfo extends React.Component {
    addFormRef = React.createRef();
    viewFormRef = React.createRef();
    editFormRef = React.createRef();
    updateFormRef = React.createRef();
    state = {
        title: null,
        realName: null,
        status: null,
        content: null,
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        addVisible: false,
        viewVisible: false,
        editVisible: false,
        updateVisible: false,
        wordOrderStartTime: null,
        wordOrderEndTime: null,
        fileList: [],
        imageList: null,
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        viewImageList: [],
        updateImageList: [],
        addWorkOrderSubmitLoading: false,
        isExporting: false,
        userPermission: null,
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
        selectedRowKeys: [],
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        userName = JSON.parse(window.sessionStorage.getItem("userName"));
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.getUserPermission();
        }
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: PATH_URL.GET_USER_MENU_URL + "/" + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_WORK_ORDER_URL,
            params: {
                title: this.state.title,
                realName: this.state.realName,
                userName: userRole !== 0 ? userName : null,
                wordOrderStartTime: this.state.wordOrderStartTime,
                wordOrderEndTime: this.state.wordOrderEndTime,
                status: this.state.status,
                content: this.state.content,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchWorkOrderInfo = () => {
        this.setState({
            loading: true,
        });
        this.initData()
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            title: null,
            realName: null,
            status: null,
            content: null,
            wordOrderStartTime: null,
            wordOrderEndTime: null,
        });
    }
    showWorkOrderInfo = (record) => {
        this.setState({
            viewVisible: true,
        })
        setTimeout(() => {
            this.viewFormRef.current.setFieldsValue(record);
            this.viewFormRef.current.setFieldsValue({
                'status': record.status.toString(),
            });
            let viewImageList = [];
            if (record.imageList != null) {
                viewImageList = record.imageList.split(";")
            }
            this.setState({
                viewImageList: viewImageList,
            })
        }, 100);
    }
    showReplyModal = (record) => {
        this.setState({
            editVisible: true,
        })
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
        }, 100);
    }
    showHandleModal = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认处理该工单信息？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: PATH_URL.HANDLE_WORK_ORDER_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    showDeleteWorkOrderInfo = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该工单信息？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.DELETE_WORK_ORDER_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    updateWorkOrder = () => {
        if (!this.state.addWorkOrderSubmitLoading) {
            this.setState({
                addWorkOrderSubmitLoading: true,
            })
            this.updateFormRef.current.validateFields().then(values => {
                values.userName = userName;
                values.imageList = this.state.imageList;
                if (Util.isLicenseNo(values.title)) {
                    Axios({
                        method: 'post',
                        url: PATH_URL.UPDATE_WORK_ORDER_URL,
                        data: JSON.stringify(values),
                    }).then(res => {
                        if (res.status === 200) {
                            if (res.data.code === 200) {
                                this.setState({
                                    loading: true,
                                    updateVisible: false,
                                    addWorkOrderSubmitLoading: false,
                                });
                                message.success(res.data.message);
                                this.initData();
                                this.updateFormRef.current.resetFields();
                            } else {
                                message.error(res.data.message);
                                this.setState({
                                    addWorkOrderSubmitLoading: false,
                                })
                            }
                        } else if (res.code === 401) {
                            message.error(res.message);
                            this.setState({
                                addWorkOrderSubmitLoading: false,
                            })
                        }
                    }).catch(err => {
                        console.log(err);
                        this.setState({
                            addWorkOrderSubmitLoading: false,
                        })
                    });
                } else {
                    this.setState({
                        addWorkOrderSubmitLoading: false,
                    })
                    message.error("标题只能是车牌")
                }
            }).catch(info => {
                console.log(info);
                this.setState({
                    addWorkOrderSubmitLoading: false,
                })
            });
        }
    }
    replyWorkOrder = () => {
        this.editFormRef.current.validateFields().then(values => {
            values.status = 1
            Axios({
                method: 'post',
                url: PATH_URL.UPDATE_WORK_ORDER_URL,
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    showAddWorkOrder = () => {
        this.setState({
            addVisible: true,
            fileList: [],
        })
    }
    addWorkOrder = () => {
        if (!this.state.addWorkOrderSubmitLoading) {
            this.setState({
                addWorkOrderSubmitLoading: true,
            })
            this.addFormRef.current.validateFields().then(values => {
                values.userName = userName;
                values.imageList = this.state.imageList;
                if (Util.isLicenseNo(values.title)) {
                    Axios({
                        method: 'post',
                        url: PATH_URL.ADD_WORK_ORDER_URL,
                        data: JSON.stringify(values),
                    }).then(res => {
                        if (res.status === 200) {
                            if (res.data.code === 200) {
                                this.setState({
                                    loading: true,
                                    addVisible: false,
                                    addWorkOrderSubmitLoading: false,
                                });
                                message.success(res.data.message);
                                this.initData();
                                this.addFormRef.current.resetFields();
                            } else {
                                message.error(res.data.message);
                                this.setState({
                                    addWorkOrderSubmitLoading: false,
                                })
                            }
                        } else if (res.code === 401) {
                            message.error(res.message);
                            this.setState({
                                addWorkOrderSubmitLoading: false,
                            })
                        }
                    }).catch(err => {
                        console.log(err);
                        this.setState({
                            addWorkOrderSubmitLoading: false,
                        })
                    });
                } else {
                    this.setState({
                        addWorkOrderSubmitLoading: false,
                    })
                    message.error("标题只能是车牌")
                }
            }).catch(info => {
                console.log(info);
                this.setState({
                    addWorkOrderSubmitLoading: false,
                })
            });
        }
    }
    showUpdateOrderInfo = (record) => {
        this.setState({
            updateVisible: true,
        });
        if (record.imageList) {
            let arr = record.imageList.split(';')
            let fileList = []

            arr.forEach(res => {
                let data = {
                    url: res
                }
                if (!Util.isEmpty(res)) {
                    fileList.push(data)
                }
            })
            this.setState({
                fileList: fileList,
            });
        }
        setTimeout(() => {
            this.updateFormRef.current.setFieldsValue(record);
            this.updateFormRef.current.setFieldsValue({
                'status': record.status.toString(),
            });
        }, 100);
    }
    handleCancel = () => {
        this.setState({
            addVisible: false,
            viewVisible: false,
            editVisible: false,
            updateVisible: false,
            fileList: [],
        });
    }
    handleWorkOrderTime = (value, dateString) => {
        this.setState({
            wordOrderStartTime: dateString[0],
            wordOrderEndTime: dateString[1],
        })
    }
    handleUploadImageCancel = () => {
        this.setState({
            previewVisible: false,
        });
    }
    beforeUpload = (file, fileList) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('图片格式必须是jpeg或者png的!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片大小最大2M!');
        }
        return isJpgOrPng && isLt2M;
    }
    handleChange = ({fileList}) => {
        let imageList = "";
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].response !== undefined) {
                imageList += fileList[i].response.data + ";";
            }
            if (fileList[i].url !== undefined) {
                imageList += fileList[i].url + ";";
            }

        }
        this.setState({fileList, imageList});
    }
    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    exportWorkOrderInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: PATH_URL.EXPORT_WORK_ORDER_INFO_URL + "/" + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '工单数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }
    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: PATH_URL.LIST_WORK_ORDER_IDS_URL,
                params: {
                    title: this.state.title,
                    realName: this.state.realName,
                    userName: userRole !== 0 ? userName : null,
                    wordOrderStartTime: this.state.wordOrderStartTime,
                    wordOrderEndTime: this.state.wordOrderEndTime,
                    status: this.state.status,
                    content: this.state.content,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll
        };
        const layout = {
            labelCol: {span: 5},
            wrapperCol: {span: 19},
        };
        const columns = [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                ellipsis: true,
            },
            {
                title: '工单内容',
                dataIndex: 'content',
                key: 'content',
                width: 200,
                ellipsis: true,
            },
            {
                title: '发起人',
                dataIndex: 'realName',
                key: 'realName',
            },
            {
                title: '发起人账户',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: '发起时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 170,
            },
            {
                title: '回复内容',
                dataIndex: 'replyContent',
                key: 'replyContent',
                width: 200,
                ellipsis: true,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    switch (status) {
                        case 0:
                            return '待回复';
                        case 1:
                            return '已回复';
                        case 2:
                            return '处理中';
                        default:
                            return '待回复';
                    }
                }
            },
            {
                title: '操作',
                key: 'action',
                width: 160,
                render: (text, record) => (
                    <Space size="small">
                        {
                            userRole === 0 && this.state.userPermission != null && this.state.userPermission.includes('300085') ? (
                                <a onClick={this.showWorkOrderInfo.bind(this, record)}>查看</a>) : (userRole !== 0 ? (
                                <a onClick={this.showWorkOrderInfo.bind(this, record)}>查看</a>) : null)
                        }
                        {
                            (userRole !== 0 && record.status === 0 ? (
                                <a onClick={this.showUpdateOrderInfo.bind(this, record)}>修改</a>) : null)
                        }
                        {
                            this.state.userPermission != null && this.state.userPermission.includes('300079') ? (record.status === 0 || record.status === 2 ? (
                                <a onClick={this.showReplyModal.bind(this, record)}>回复</a>) : null) : null
                        }
                        {
                            this.state.userPermission != null && this.state.userPermission.includes('300113') ? (record.status === 0 ? (
                                <a onClick={this.showHandleModal.bind(this, record)}>处理</a>) : null) : null
                        }
                        {
                            this.state.userPermission != null && this.state.userPermission.includes('300080') ? (
                                <a style={{'color': '#d43f3a'}}
                                   onClick={this.showDeleteWorkOrderInfo.bind(this, record)}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        const uploadButton = (
            <div>
                <PlusOutlined/>
                <div style={{marginTop: 8}}>Upload</div>
            </div>
        );
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Form layout='inline'>
                        <Form.Item
                            label="标题"
                            name="title"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入标题"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="内容"
                            name="content"
                            style={{marginTop: '5px', marginBottom: '5px', width: "300px"}}>
                            <Input placeholder="请输入内容"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="发起时间"
                            name="workOrderTime"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <RangePicker locale={zh_CN} showTime onChange={this.handleWorkOrderTime}/>
                        </Form.Item>
                        {
                            userRole === 0 ? (<Form.Item
                                label="发起人"
                                name="realName"
                                style={{width: 222, marginTop: '5px', marginBottom: '5px'}}>
                                <Input placeholder="请输入姓名"
                                       onChange={this.inputChange}/>
                            </Form.Item>) : null
                        }
                        <Form.Item
                            label="状态"
                            name="status"
                            style={{width: 146, marginTop: '5px', marginBottom: '5px'}}>
                            <Select onChange={this.statusChange}>
                                <Select.Option value="0">待回复</Select.Option>
                                <Select.Option value="1">已回复</Select.Option>
                                <Select.Option value="2">处理中</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <Button type="primary" onClick={this.searchWorkOrderInfo}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {
                                userRole !== 0 ? (
                                    <Button type="primary" style={{'marginLeft': '10px'}}
                                            onClick={this.showAddWorkOrder}>添加</Button>) : null
                            }
                            {this.state.userPermission != null && this.state.userPermission.includes('300114') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.exportWorkOrderInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                提交工单
                            </div>
                        }
                        width={900}
                        visible={this.state.addVisible}
                        onOk={this.addWorkOrder}
                        onCancel={this.handleCancel}
                        destroyOnClose={true}>
                        <Form {...layout}
                              ref={this.addFormRef}>
                            <Form.Item
                                label="标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题'}]}>
                                <Input placeholder="标题只能是车牌"/>
                            </Form.Item>
                            <Form.Item
                                label="内容"
                                name="content"
                                rules={[{required: true, message: '请输入内容'}]}>
                                <TextArea rows={4}/>
                            </Form.Item>
                            <Form.Item
                                label="图片">
                                <Upload
                                    action={PATH_URL.BASE_URL + "/" + PATH_URL.WORK_ORDER_UPLOAD_FILE_URL}
                                    listType="picture-card"
                                    accept=".png,.jpg,.jpeg"
                                    fileList={this.state.fileList}
                                    name="imageFile"
                                    onPreview={this.handlePreview}
                                    beforeUpload={this.beforeUpload}
                                    onChange={this.handleChange}
                                    maxCount={3}>
                                    {this.state.fileList.length >= 3 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                修改工单
                            </div>
                        }
                        width={900}
                        visible={this.state.updateVisible}
                        onOk={this.updateWorkOrder}
                        onCancel={this.handleCancel}
                        destroyOnClose={true}>
                        <Form {...layout}
                              ref={this.updateFormRef}>
                            <Form.Item
                                label="工单id"
                                name="id"
                                hidden="true">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题'}]}>
                                <Input placeholder="标题只能是车牌"/>
                            </Form.Item>
                            <Form.Item
                                label="内容"
                                name="content"
                                rules={[{required: true, message: '请输入内容'}]}>
                                <TextArea rows={4}/>
                            </Form.Item>
                            <Form.Item
                                label="图片">
                                <Upload
                                    action={PATH_URL.BASE_URL + "/" + PATH_URL.WORK_ORDER_UPLOAD_FILE_URL}
                                    listType="picture-card"
                                    accept=".png,.jpg,.jpeg"
                                    fileList={this.state.fileList}
                                    name="imageFile"
                                    onPreview={this.handlePreview}
                                    beforeUpload={this.beforeUpload}
                                    onChange={this.handleChange}
                                    maxCount={3}>
                                    {this.state.fileList.length >= 3 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                查看
                            </div>
                        }
                        width={900}
                        visible={this.state.viewVisible}
                        onOk={this.handleCancel}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.viewFormRef}>
                            <Form.Item
                                label="工单id"
                                name="id"
                                hidden="true">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="发起人"
                                name="realName">
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="发起人账户"
                                name="userName">
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="发起时间"
                                name="gmtCreate">
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="工单状态"
                                name="status">
                                <Select disabled>
                                    <Select.Option value="0">待回复</Select.Option>
                                    <Select.Option value="1">已回复</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="标题"
                                name="title">
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="内容"
                                name="content">
                                <TextArea disabled rows={4}/>
                            </Form.Item>
                            <Form.Item
                                label="回复内容"
                                name="replyContent">
                                <TextArea disabled rows={4}/>
                            </Form.Item>
                            <Form.Item
                                label="图片"
                                name="imageList">
                                {
                                    this.state.viewImageList !== null && this.state.viewImageList.length !== 0 ? (
                                        this.state.viewImageList.map(item => {
                                            return (<Image
                                                width={100}
                                                src={item}/>)
                                        })) : null
                                }
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                回复
                            </div>
                        }
                        visible={this.state.editVisible}
                        onOk={this.replyWorkOrder}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.editFormRef}>
                            <Form.Item
                                label="工单id"
                                name="id"
                                hidden="true">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="回复内容"
                                name="replyContent"
                                rules={[{required: true, message: '请输入内容'}]}>
                                <TextArea rows={4}/>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        visible={this.state.previewVisible}
                        title={this.state.previewTitle}
                        footer={null}
                        onCancel={this.handleUploadImageCancel}>
                        <img alt="example" style={{width: '100%'}} src={this.state.previewImage}/>
                    </Modal>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.data}
                           rowSelection={rowSelection}
                           rowKey={columns => columns.id}
                           loading={this.state.loading}
                           style={{'marginTop': '20px'}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                    <div style={{
                        marginTop: "-38px",
                        marginLeft: "8px",
                        display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                    }}>
                        < Checkbox onChange={this.exportAllSelect}
                                   checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                    </div>
                </div>
            </div>
        )
    }
}

export default WorkOrderInfo;