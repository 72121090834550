import React from 'react';
import Header from './Header';
import LeftMenu from './LeftMenu'
import Content from './Content';
import Util from '../../utils/utils';
import style from './Home.module.css';


class Home extends React.Component {
    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (Util.isEmpty(userName)) {
            this.props.history.push("/login")
        }
    }

    render() {
        return (
            <div className={style['home']}>
                <Header history={this.props.history}/>
                <LeftMenu history={this.props.history}/>
                <Content history={this.props.history}/>
            </div>

        )
    }
}

export default Home;