import React from 'react'
import {Button, Form, Image, Input, message, Modal, Space, Table, Upload} from "antd";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import {ExclamationCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";

class ExchangeRollManage extends React.Component {
    addFormRef = React.createRef();
    editFormRef = React.createRef();
    transferFormRef = React.createRef();
    state = {
        name: null,
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        visible: false,
        editVisible: false,
        transferVisible: false,
        addExchangeRollSubmitLoading: false,
        fileList: [],
        iconFile: null,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_EXCHANGE_ROLL_RUL,
            params: {
                name: this.state.name,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    searchExchangeRoll = () => {
        this.setState({
            loading: true,
        })
        this.initData();
    }
    resetSearch = () => {
        this.setState({
            name: null,
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            editVisible: false,
            transferVisible: false,
        });
        if (this.addFormRef.current != null) {
            this.addFormRef.current.resetFields();
        }
        if (this.editFormRef.current != null) {
            this.editFormRef.current.resetFields();
        }
        if (this.transferFormRef.current != null) {
            this.transferFormRef.current.resetFields();
        }
    }
    openAddExchangeRollModel = () => {
        this.setState({
            visible: true,
        })
    }
    showEditExchangeRoll = (record) => {
        this.setState({
            editVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
        }, 100);
    }
    showDeleteExchangeRoll = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该兑换券？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.DELETE_EXCHANGE_ROLL_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    addExchangeRoll = () => {
        if (!this.state.addExchangeRollSubmitLoading) {
            this.setState({
                addExchangeRollSubmitLoading: true,
            })
            this.addFormRef.current.validateFields().then(values => {
                let formData = new FormData();
                formData.append('exchangeRoll', new Blob([JSON.stringify(values)], {type: "application/json"}));
                formData.append('rollImage', this.state.iconFile);
                Axios({
                    method: 'post',
                    url: PATH_URL.ADD_EXCHANGE_ROLL_URL,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.setState({
                                loading: true,
                                visible: false,
                                addExchangeRollSubmitLoading: false,
                                iconFile: null,
                                fileList: [],
                            });
                            message.success(res.data.message);
                            this.initData();
                            this.addFormRef.current.resetFields();
                        } else {
                            this.setState({
                                addExchangeRollSubmitLoading: false,
                            })
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        this.setState({
                            addExchangeRollSubmitLoading: false,
                        })
                        message.error(res.message);
                    }
                }).catch(err => {
                    this.setState({
                        addExchangeRollSubmitLoading: false,
                    })
                    console.log(err);
                });
            }).catch(info => {
                this.setState({
                    addExchangeRollSubmitLoading: false,
                })
                console.log(info);
            });
        }
    }
    updateExchangeRoll = () => {
        this.editFormRef.current.validateFields().then(values => {
            let formData = new FormData();
            formData.append('exchangeRoll', new Blob([JSON.stringify(values)], {type: "application/json"}));
            formData.append('rollImage', this.state.iconFile);
            Axios({
                method: 'post',
                url: PATH_URL.UPDATE_EXCHANGE_ROLL_URL,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                            iconFile: null,
                            fileList: [],
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    showTransferRoll = (record) => {
        this.setState({
            transferVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.transferFormRef.current.setFieldsValue(record);
        }, 100);
    }
    transferRoll = () => {
        let _this = this
        this.transferFormRef.current.validateFields().then(values => {
            Modal.confirm({
                icon: <InfoCircleOutlined/>,
                content: "确认转赠该兑换卷给" + values.transferUserName,
                onOk: () => {

                    Axios({
                        method: 'post',
                        url: PATH_URL.TRANSFER_EXCHANGE_ROLL_URL,
                        params: {
                            rollId: values.id,
                            quantity: values.transferQuantity,
                            userName: JSON.parse(window.sessionStorage.getItem("userName")),
                            transferUserName: values.transferUserName,
                        }
                    }).then(res => {
                        if (res.status === 200) {
                            if (res.data.code === 200) {
                                _this.setState({
                                    loading: true,
                                    transferVisible: false,
                                });
                                message.success(res.data.message);
                                _this.initData();
                                _this.transferFormRef.current.resetFields();
                            } else {
                                message.error(res.data.message);
                            }
                        } else if (res.code === 401) {
                            message.error(res.message);
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                },
                onCancel: () => {

                },
            });
        }).catch(info => {
            console.log(info);
        });
    }
    fileChange = (e) => {
        this.setState({
            iconFile: (e.fileList.length > 0 && (e.file.size / (1024 * 1024) < 10)) ? e.fileList[0].originFileObj : null,
        })
    }
    beforeUpload = (file, fileList) => {
        if (file.size / (1024 * 1024) >= 10) {
            message.error("文件大小不能超过10M！", 2);
            this.setState({
                fileList: [],
                iconFile: null
            })
        } else {
            this.setState({
                fileList: fileList,
                iconFile: file
            })
        }
        return false;
    }
    onRemove = (file) => {
        this.setState({
            fileList: []
        })
    }

    render() {
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const columns = [
            {
                title: '兑换卷名称',
                dataIndex: 'name',
                key: 'name',
                width: 300,
                ellipsis: true,
            },
            {
                title: '兑换卷图片',
                dataIndex: 'rollImage',
                key: 'rollImage',
                render: rollImage => {
                    if (!Util.isEmpty(rollImage)) {
                        return (
                            <Image
                                width={20}
                                height={20}
                                src={rollImage}/>
                        )
                    }
                }
            },
            {
                title: '所需积分',
                dataIndex: 'integral',
                key: 'integral',
            },
            {
                title: '剩余数量',
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: '过期时间（自兑换后/天）',
                dataIndex: 'expirationDate',
                key: 'expirationDate',
                width: 180,
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 165,
            },
            {
                title: '操作',
                key: 'action',
                width: 120,
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300096') ? (
                                <a onClick={this.showTransferRoll.bind(this, record)}>转赠</a>) : null
                        }
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300097') ? (
                                <a onClick={this.showEditExchangeRoll.bind(this, record)}>修改</a>) : null
                        }
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300098') ? (
                                <a style={{'color': '#d43f3a'}}
                                   onClick={this.showDeleteExchangeRoll.bind(this, record)}>删除</a>) : null
                        }

                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item
                        label="兑换卷名称"
                        name="name"
                        style={{width: 270}}>
                        <Input placeholder="请输入兑换卷名称"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.searchExchangeRoll}
                                style={{'marginLeft': '10px'}}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300095') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.openAddExchangeRollModel}>新增</Button>) : null
                        }
                    </Form.Item>
                </Form>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            新增
                        </div>
                    }
                    visible={this.state.visible}
                    onOk={this.addExchangeRoll}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.addFormRef}>
                        <Form.Item
                            label="兑换卷名称"
                            name="name"
                            rules={[{required: true, message: '请输入兑换卷名称'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="兑换卷数量"
                            name="quantity"
                            rules={[{
                                required: true,
                                validator: async (_, value, callback) => {
                                    if (Util.isNumber(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确数量');
                                    }
                                }
                            }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="所需积分"
                            name="integral"
                            rules={[{
                                required: true,
                                validator: async (_, value, callback) => {
                                    if (Util.isNumber(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确积分数量');
                                    }
                                }
                            }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="过期时间"
                            name="expirationDate"
                            rules={[{
                                required: true,
                                validator: async (_, value, callback) => {
                                    if (Util.isNumber(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确过期时间');
                                    }
                                }
                            }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="兑换卷图片">
                            <Upload
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.fileChange}
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}>
                                <Button type="primary">
                                    选择文件
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            修改
                        </div>
                    }
                    visible={this.state.editVisible}
                    onOk={this.updateExchangeRoll}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.editFormRef}>
                        <Form.Item
                            label="兑换卷ID"
                            name="id"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="兑换卷名称"
                            name="name"
                            rules={[{required: true, message: '请输入兑换卷名称'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="兑换卷数量"
                            name="quantity"
                            rules={[{
                                required: true,
                                validator: async (_, value, callback) => {
                                    if (Util.isNumber(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确数量');
                                    }
                                }
                            }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="所需积分"
                            name="integral"
                            rules={[{
                                required: true,
                                validator: async (_, value, callback) => {
                                    if (Util.isNumber(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确积分数量');
                                    }
                                }
                            }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="过期时间"
                            name="expirationDate"
                            rules={[{
                                required: true,
                                validator: async (_, value, callback) => {
                                    if (Util.isNumber(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确过期时间');
                                    }
                                }
                            }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="兑换卷图片">
                            <Upload
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.fileChange}
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}>
                                <Button type="primary">
                                    选择文件
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            转赠
                        </div>
                    }
                    visible={this.state.transferVisible}
                    onOk={this.transferRoll}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.transferFormRef}>
                        <Form.Item
                            label="兑换卷ID"
                            name="id"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="账户账户"
                            name="transferUserName"
                            rules={[{
                                required: true, validator: async (_, value, callback) => {
                                    if (Util.isTel(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确的账户');
                                    }
                                }
                            }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="转赠数量"
                            name="transferQuantity"
                            rules={[{
                                required: true,
                                validator: async (_, value, callback) => {
                                    if (Util.isNumber(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确数量');
                                    }
                                }
                            }]}>
                            <Input/>
                        </Form.Item>
                    </Form>
                </Modal>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
            </div>
        )
    }
}

export default ExchangeRollManage;