import React from 'react'
import {
    Button,
    Form,
    Input,
    message,
    Modal,
    Row,
    Space,
    Table,
    InputNumber,
    Upload, Image,
} from "antd";
import Axios from "../../../utils/axios";
import Util from "../../../utils/utils";
import {ExclamationCircleOutlined} from "@ant-design/icons";

class PrizeInfo extends React.Component {
    addFormRef = React.createRef();
    editFormRef = React.createRef();
    state = {
        data: null,
        loading: true,
        prizeName: null,
        iconFile: null,
        fileList: [],
        addVisible: false,
        editVisible: false,
        integralInputVisible: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: '/activity/prizeList',
            params: {
                prizeName: this.state.prizeName,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data,
                        loading: false,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    addPrize = () => {
        this.addFormRef.current.validateFields().then(values => {
            let formData = new FormData();
            formData.append('prize', new Blob([JSON.stringify(values)], {type: "application/json"}));
            formData.append('iconFile', this.state.iconFile);
            Axios({
                method: 'post',
                url: '/activity/addPrize',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            addVisible: false,
                            iconFile: null,
                            fileList: [],
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.addFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    showAddPrizeInfo = () => {
        this.setState({
            addVisible: true,
        })
    }
    showEditPrize = (record) => {
        if (record.prizeName === "积分") {
            this.setState({
                integralInputVisible: true
            })
        } else {
            this.setState({
                integralInputVisible: false
            })
        }
        this.setState({
            editVisible: true,
        })
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
        }, 100);
    }
    updatePrize = () => {
        this.editFormRef.current.validateFields().then(values => {
            let formData = new FormData();
            formData.append('prize', new Blob([JSON.stringify(values)], {type: "application/json"}));
            formData.append('iconFile', this.state.iconFile);
            Axios({
                method: 'post',
                url: '/activity/updatePrize',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                            iconFile: null,
                            fileList: [],
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    searchPrize = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleCancel = () => {
        this.setState({
            addVisible: false,
            editVisible: false,
        });
    }
    prizeNameInputChange = (e) => {
        if (e.target.value === "积分") {
            this.setState({
                integralInputVisible: true
            })
        } else {
            this.setState({
                integralInputVisible: false
            })
        }
    }
    fileChange = (e) => {
        this.setState({
            iconFile: (e.fileList.length > 0 && (e.file.size / (1024 * 1024) < 10)) ? e.fileList[0].originFileObj : null,
        })
    }
    beforeUpload = (file, fileList) => {
        if (file.size / (1024 * 1024) >= 10) {
            message.error("文件大小不能超过10M！", 2);
            this.setState({
                fileList: [],
                iconFile: null
            })
        } else {
            this.setState({
                fileList: fileList,
                iconFile: file
            })
        }
        return false;
    }
    onRemove = (file) => {
        this.setState({
            fileList: []
        })
    }
    resetSearch = () => {
        this.setState({
            prizeName: null,
        })
    }
    showDeletePrize = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该奖品？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: '/activity/deletePrize/' + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }

    render() {
        const layout = {
            labelCol: {span: 5},
            wrapperCol: {span: 19},
        };
        const columns = [
            {
                title: '奖品名称',
                dataIndex: 'prizeName',
                key: 'prizeName',
            },
            {
                title: '抽中概率',
                dataIndex: 'probability',
                key: 'probability',
            },
            {
                title: '奖品编号',
                dataIndex: 'number',
                key: 'number',
            },
            {
                title: '图片',
                dataIndex: 'icon',
                key: 'icon',
                render: icon => {
                    if (!Util.isEmpty(icon)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={icon}/>
                        )
                    }
                }
            },
            {
                title: '积分',
                dataIndex: 'integral',
                key: 'integral',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <Space size="small">
                        {this.props.userPermission != null && this.props.userPermission.includes('300057') ? (
                            <a onClick={this.showEditPrize.bind(this, record)}>修改</a>) : null
                        }
                        {this.props.userPermission != null && this.props.userPermission.includes('300058') ? (
                            <a style={{'color': '#d43f3a'}}
                               onClick={this.showDeletePrize.bind(this, record)}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Row>
                    <Form layout='inline'>
                        <Form.Item
                            label="奖品名称"
                            name="prizeName"
                            style={{width: 200}}>
                            <Input placeholder="请输入名称"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.searchPrize}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {this.props.userPermission != null && this.props.userPermission.includes('300047') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.showAddPrizeInfo}>添加</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                </Row>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            新增
                        </div>
                    }
                    visible={this.state.addVisible}
                    onOk={this.addPrize}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.addFormRef}>
                        <Form.Item
                            label="奖品名称"
                            name="prizeName"
                            rules={[{required: true, message: '请输入奖品名称'}]}
                            onChange={this.prizeNameInputChange}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="抽中概率"
                            name="probability"
                            rules={[{required: true, message: '请选择概率'}]}>
                            <InputNumber
                                min="0"
                                max="1"
                                step="0.01"/>
                        </Form.Item>
                        <Form.Item
                            label="奖品编号"
                            name="number"
                            rules={[{required: true, message: '奖品编号'}]}>
                            <InputNumber
                                min="1"
                                max="6"/>
                        </Form.Item>
                        <Form.Item
                            label="奖品图片">
                            <Upload
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.fileChange}
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}>
                                <Button type="primary">
                                    选择文件
                                </Button>
                            </Upload>
                        </Form.Item>
                        {
                            this.state.integralInputVisible === true ?
                                (<Form.Item
                                        label="积分"
                                        name="integral"
                                        rules={[{required: true, message: '请输入积分'}]}>
                                        <InputNumber
                                            min="1"
                                            max="10000"/>
                                    </Form.Item>
                                ) : ""
                        }

                    </Form>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            修改
                        </div>
                    }
                    visible={this.state.editVisible}
                    onOk={this.updatePrize}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.editFormRef}>
                        <Form.Item
                            label="奖品ID"
                            name="id"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="奖品名称"
                            name="prizeName"
                            rules={[{required: true, message: '请输入奖品名称'}]}
                            onChange={this.prizeNameInputChange}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="抽中概率"
                            name="probability"
                            rules={[{required: true, message: '请选择概率'}]}>
                            <InputNumber
                                min="0"
                                max="1"
                                step="0.01"/>
                        </Form.Item>
                        <Form.Item
                            label="奖品编号"
                            name="number"
                            rules={[{required: true, message: '奖品编号'}]}>
                            <InputNumber
                                min="1"
                                max="8"/>
                        </Form.Item>
                        <Form.Item
                            label="奖品图片">
                            <Upload
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.fileChange}
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}>
                                <Button type="primary">
                                    选择文件
                                </Button>
                            </Upload>
                        </Form.Item>
                        {
                            this.state.integralInputVisible === true ?
                                (<Form.Item
                                        label="积分"
                                        name="integral"
                                        rules={[{required: true, message: '请输入积分'}]}>
                                        <InputNumber
                                            min="1"
                                            max="10000"/>
                                    </Form.Item>
                                ) : ""
                        }

                    </Form>
                </Modal>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                />
            </div>
        )
    }

}

export default PrizeInfo;