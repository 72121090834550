import React from 'react'
import {Button, Form, Image, Input, InputNumber, message, Modal, Row, Select, Space, Table, Upload} from "antd";
import Axios from "../../../utils/axios";
import Util from "../../../utils/utils";
import Editor from 'wangeditor-antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import PATH_URL from "../../../utils/url";

const {TextArea} = Input;

class CommodityInfo extends React.Component {
    addFormRef = React.createRef();
    editFormRef = React.createRef();
    editorRef = React.createRef()
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        commodityName: null,
        status: null,
        typeId: null,
        imageFile: null,
        commodityCategory: null,
        details: null,
        fileList: [],
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.initCommodityCategory();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: '/commodity/listCommodity',
            params: {
                commodityName: this.state.commodityName,
                status: this.state.status,
                typeId: this.state.typeId,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    initCommodityCategory = () => {
        Axios({
            method: 'get',
            url: '/commodityCategory/listCommodityCategory',
            params: {}
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        commodityCategory: res.data.data,
                    });
                } else {
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    addCommodity = () => {
        this.addFormRef.current.validateFields().then(values => {
            let formData = new FormData();
            values.details = this.state.details;
            formData.append('commodity', new Blob([JSON.stringify(values)], {type: "application/json"}));
            formData.append('imageFile', this.state.imageFile);
            Axios({
                method: 'post',
                url: '/commodity/addCommodity',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            addVisible: false,
                            imageFile: null,
                            fileList: [],
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.addFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    updateCommodity = () => {
        this.editFormRef.current.validateFields().then(values => {
            let formData = new FormData();
            values.details = this.state.details;
            formData.append('commodity', new Blob([JSON.stringify(values)], {type: "application/json"}));
            formData.append('imageFile', this.state.imageFile);
            Axios({
                method: 'post',
                url: '/commodity/updateCommodity',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                            imageFile: null,
                            fileList: [],
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    showAddCommodity = () => {
        this.setState({
            addVisible: true,
        })
        setTimeout(() => {
            this.initEditor('');
        }, 100);
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    searchCommodityInfo = () => {
        this.initData();
    }
    resetSearch = () => {
        this.setState({
            commodityName: null,
            status: null,
            typeId: null,
        })
    }
    handleCancel = () => {
        this.setState({
            addVisible: false,
            editVisible: false,
        });
    }
    showEditCommodityInfo = (record) => {
        this.setState({
            editVisible: true,
        })
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
            this.initEditor(record.details);
        }, 100);
    }
    showDeleteCommodity = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该商品？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: '/commodity/deleteCommodity/' + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    //上架商品
    showUpCommodity = (record) => {
        let formData = new FormData();
        record.status = 0;
        formData.append('commodity', new Blob([JSON.stringify(record)], {type: "application/json"}));
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认上架该商品？",
            onOk: () => {
                Axios({
                    method: 'post',
                    url: '/commodity/updateCommodity',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    //下架商品
    showDownCommodity = (record) => {
        let formData = new FormData();
        record.status = 1;
        formData.append('commodity', new Blob([JSON.stringify(record)], {type: "application/json"}));
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认下架该商品？",
            onOk: () => {
                Axios({
                    method: 'post',
                    url: '/commodity/updateCommodity',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    fileChange = (e) => {
        this.setState({
            imageFile: (e.fileList.length > 0 && (e.file.size / (1024 * 1024) < 10)) ? e.fileList[0].originFileObj : null,
        })
    }
    beforeUpload = (file, fileList) => {
        if (file.size / (1024 * 1024) >= 10) {
            message.error("文件大小不能超过10M！", 2);
            this.setState({
                fileList: [],
                imageFile: null
            })
        } else {
            this.setState({
                fileList: fileList,
                imageFile: file
            })
        }
        return false;
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    typeChange = (value) => {
        this.setState({
            typeId: value,
        })
    }
    onRemove = (file) => {
        this.setState({
            fileList: []
        })
    }
    initEditor = (html) => {
        const _this = this;
        this.editor = new Editor(this.editorRef.current)
        this.editor.customConfig.menus = [
            'head',  // 标题
            'bold',  // 粗体
            'fontSize',  // 字号
            'fontName',  // 字体
            'italic',  // 斜体
            'underline',  // 下划线
            'strikeThrough',  // 删除线
            'foreColor',  // 文字颜色
            'backColor',  // 背景颜色
            'link',  // 插入链接
            'list',  // 列表
            'justify',  // 对齐方式
            'quote',  // 引用
            'image',  // 插入图片
            'table',  // 表格
            'undo',  // 撤销
            'redo'  // 重复
        ]
        this.editor.customConfig.uploadImgServer = PATH_URL.BASE_URL + '/commodity/uploadFile'
        this.editor.customConfig.uploadFileName = 'imageFile'
        // 将图片大小限制为 3M
        this.editor.customConfig.uploadImgMaxSize = 3 * 1024 * 1024
        this.editor.customConfig.onchange = (html) => {
            // html为拿到的在div富文本框中输入的内容（使用样式时会自动带上标签）
            // 如：<p><span style="font-weight: bold;">演示<span style="color: rgb(249, 150, 59);">文本</span></span></p>
            _this.setState({
                details: html,
            })
        }
        this.editor.create();
        this.editor.txt.html(html);
    }

    render() {
        const layout = {
            labelCol: {span: 2},
            wrapperCol: {span: 22},
        };
        const columns = [
            {
                title: '商品名称',
                dataIndex: 'commodityName',
                key: 'commodityName',
            },
            {
                title: '所需积分',
                dataIndex: 'integral',
                key: 'integral',
            },
            {
                title: '商品图片',
                dataIndex: 'image',
                key: 'image',
                render: image => {
                    if (!Util.isEmpty(image)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={image}/>
                        )
                    }
                }
            },
            {
                title: '商品类别',
                dataIndex: 'categoryName',
                key: 'categoryName',
            },
            {
                title: '商品描述',
                dataIndex: 'commodityDescribe',
                key: 'commodityDescribe',
                ellipsis: true,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    switch (status) {
                        case 0:
                            return '已上架';
                        case 1:
                            return '已下架';
                        default:
                            return '已上架';
                    }
                }
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <Space size="small">
                        {this.props.userPermission != null && this.props.userPermission.includes('300063') ? (
                            <a onClick={this.showEditCommodityInfo.bind(this, record)}>修改</a>) : null
                        }
                        {record.status !== 0 ? (this.props.userPermission != null && this.props.userPermission.includes('300065') ? (
                            <a onClick={this.showUpCommodity.bind(this, record)}>上架</a>) : null) : null
                        }
                        {record.status !== 1 ? (this.props.userPermission != null && this.props.userPermission.includes('300066') ? (
                            <a onClick={this.showDownCommodity.bind(this, record)}>下架</a>) : null) : null
                        }
                        {this.props.userPermission != null && this.props.userPermission.includes('300064') ? (
                            <a style={{'color': '#d43f3a'}}
                               onClick={this.showDeleteCommodity.bind(this, record)}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Row>
                    <Form layout='inline'>
                        <Form.Item
                            label="商品名称"
                            name="commodityName"
                            style={{width: 200}}>
                            <Input placeholder="请输入商品名称"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="status"
                            style={{width: 200}}>
                            <Select onChange={this.statusChange}>
                                <Select.Option value="0">已上架</Select.Option>
                                <Select.Option value="1">已下架</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="所属类别"
                            name="typeId"
                            style={{width: 200}}>
                            <Select onChange={this.typeChange}>
                                {
                                    this.state.commodityCategory != null ?
                                        this.state.commodityCategory.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}>{item.categoryName}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.searchCommodityInfo}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {this.props.userPermission != null && this.props.userPermission.includes('300049') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.showAddCommodity}>新增</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                新增
                            </div>
                        }
                        width={1000}
                        visible={this.state.addVisible}
                        onOk={this.addCommodity}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.addFormRef}>
                            <Form.Item
                                label="商品名称"
                                name="commodityName"
                                rules={[{required: true, message: '请输入商品名称'}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="商品描述"
                                name="commodityDescribe"
                                rules={[{required: true, message: '请输入商品描述'}]}>
                                <TextArea/>
                            </Form.Item>
                            <Form.Item
                                label="所属类别"
                                name="typeId"
                                rules={[{required: true, message: '请选择所属类别'}]}>
                                <Select>
                                    {
                                        this.state.commodityCategory != null ?
                                            this.state.commodityCategory.map(item => {
                                                return (<Select.Option key={item.id}
                                                                       value={item.id}>{item.categoryName}</Select.Option>)
                                            }) : null
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="商品详情"
                                name="details">
                                <div className='rich-text-field' ref={this.editorRef}></div>
                            </Form.Item>
                            <Form.Item
                                label="所需积分"
                                name="integral"
                                rules={[{required: true, message: '请输入积分'}]}>
                                <InputNumber
                                    min="0"
                                    max="100000"/>
                            </Form.Item>
                            <Form.Item
                                label="商品图片">
                                <Upload
                                    name="file"
                                    accept=".png,.jpg,.jpeg"
                                    maxCount={1}
                                    fileList={this.state.fileList}
                                    onChange={this.fileChange}
                                    onRemove={this.onRemove}
                                    beforeUpload={this.beforeUpload}>
                                    <Button type="primary">
                                        选择文件
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                修改
                            </div>
                        }
                        width={1000}
                        visible={this.state.editVisible}
                        onOk={this.updateCommodity}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.editFormRef}>
                            <Form.Item
                                label="商品ID"
                                name="id"
                                hidden="true">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="商品名称"
                                name="commodityName"
                                rules={[{required: true, message: '请输入商品名称'}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="商品描述"
                                name="commodityDescribe"
                                rules={[{required: true, message: '请输入商品描述'}]}>
                                <TextArea/>
                            </Form.Item>
                            <Form.Item
                                label="所属类别"
                                name="typeId"
                                rules={[{required: true, message: '请选择所属类别'}]}>
                                <Select>
                                    {
                                        this.state.commodityCategory != null ?
                                            this.state.commodityCategory.map(item => {
                                                return (<Select.Option key={item.id}
                                                                       value={item.id}>{item.categoryName}</Select.Option>)
                                            }) : null
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="商品详情"
                                name="details">
                                <div className='rich-text-field' ref={this.editorRef}></div>
                            </Form.Item>
                            <Form.Item
                                label="所需积分"
                                name="integral"
                                rules={[{required: true, message: '请输入积分'}]}>
                                <InputNumber
                                    min="0"
                                    max="100000"/>
                            </Form.Item>
                            <Form.Item
                                label="商品图片">
                                <Upload
                                    name="file"
                                    accept=".png,.jpg,.jpeg"
                                    maxCount={1}
                                    fileList={this.state.fileList}
                                    onChange={this.fileChange}
                                    onRemove={this.onRemove}
                                    beforeUpload={this.beforeUpload}>
                                    <Button type="primary">
                                        选择文件
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.data}
                           rowKey={columns => columns.id}
                           loading={this.state.loading}
                           style={{'marginTop': '20px'}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                </Row>
            </div>
        )
    }

}

export default CommodityInfo;