import React from 'react';
import {Button, DatePicker, Form, message, Modal, Select, Space, Table} from "antd";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import TravelOrderGroupDetailed from "./TravelOrderGroupDetailed";

let userRole;
export default class TravelOrderSummary extends React.Component {
    state = {
        groupName: null,
        status: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        data: null,
        allGroupUserData: null,
        visible: false,
        year: null,
        month: null,
        showGroupName: null,
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.initGroupUserDate();
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.TRAVEL_ACHIEVEMENT.LIST_TRAVEL_ORDER_INFO_BY_GROUP_URL,
            params: {
                groupName: this.state.groupName,
                year: this.state.year,
                month: this.state.month,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    initGroupUserDate = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_GROUP_USER + "/" + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    let other = {};
                    other.userName = JSON.parse(window.sessionStorage.getItem("userName"));
                    other.groupName = "其他";
                    let data = res.data.data;
                    data.push(other);
                    this.setState({
                        allGroupUserData: data
                    });
                } else {
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    groupChange = (value, option) => {
        this.setState({
            groupName: value,
        })
    }
    showTravelOrderInfo = (record) => {
        this.setState({
            showGroupName: Util.isEmpty(record.groupName) ? "其他" : record.groupName,
            visible: true,
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }
    search = () => {
        this.setState({
            loading: true,
        })
        this.initData();
    }
    resetSearch = () => {
        this.setState({
            groupName: null,
            year: null,
            month: null
        })
    }
    onDatePickerYearChange = (value, dateString) => {
        this.setState({
            year: dateString
        })
    }
    monthChange = (value) => {
        this.setState({
            month: value
        })
    }

    render() {
        const columns = [
            {
                title: '组名',
                dataIndex: 'groupName',
                key: 'groupName',
                render: groupName => {
                    if (Util.isEmpty(groupName)) {
                        return "其他";
                    } else {
                        return groupName;
                    }
                }
            },
            {
                title: '总订单',
                dataIndex: 'sumTravelOrder',
                key: 'sumTravelOrder',
            },
            {
                title: '已完成订单',
                dataIndex: 'sumCompleted',
                key: 'sumCompleted',
            },
            {
                title: '已取消订单',
                dataIndex: 'sumCanceled',
                key: 'sumCanceled',
            },
            {
                title: '未付款订单',
                dataIndex: 'sumUnPay',
                key: 'sumUnPay',
            },
            {
                title: '已付款订单',
                dataIndex: 'sumPayed',
                key: 'sumPayed',
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        <a onClick={this.showTravelOrderInfo.bind(this, record)}>明细</a>
                    </Space>
                ),
            }
        ]
        return (
            <div>
                <Form layout='inline'>
                    {
                        userRole === 8 ? (
                            <Form.Item
                                label="所属组"
                                name="groupUser"
                                style={{width: 250}}>
                                <Select onChange={this.groupChange}
                                        showSearch>
                                    {
                                        this.state.allGroupUserData != null ?
                                            this.state.allGroupUserData.map(item => {
                                                return (<Select.Option key={item.groupName}
                                                                       value={item.groupName}>{item.groupName}</Select.Option>)
                                            }) : null
                                    }
                                </Select>
                            </Form.Item>
                        ) : null
                    }
                    <Form.Item
                        label="年度"
                        name="year"
                        style={{width: 200}}>
                        <DatePicker onChange={this.onDatePickerYearChange} picker="year"/>
                    </Form.Item>
                    <Form.Item
                        label="月份"
                        name="month"
                        style={{width: 200}}>
                        <Select onChange={this.monthChange}>
                            <Select.Option value="01">1月</Select.Option>
                            <Select.Option value="02">2月</Select.Option>
                            <Select.Option value="03">3月</Select.Option>
                            <Select.Option value="04">4月</Select.Option>
                            <Select.Option value="05">5月</Select.Option>
                            <Select.Option value="06">6月</Select.Option>
                            <Select.Option value="07">7月</Select.Option>
                            <Select.Option value="08">8月</Select.Option>
                            <Select.Option value="09">9月</Select.Option>
                            <Select.Option value="10">10月</Select.Option>
                            <Select.Option value="11">11月</Select.Option>
                            <Select.Option value="12">12月</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.search}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                    </Form.Item>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.groupName}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            明细
                        </div>
                    }
                    visible={this.state.visible}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    width={1000}>
                    <TravelOrderGroupDetailed groupName={this.state.showGroupName} year={this.state.year}
                                              month={this.state.month}/>
                </Modal>
            </div>
        )
    }
}