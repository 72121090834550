import React from 'react'
import {Tabs, Form, Input, Button, message} from "antd";
import Axios from "../../../utils/axios";
import Util from "../../../utils/utils";
import style from "../Content.module.css";

const {TabPane} = Tabs;

const layout = {
    labelCol: {span: 3},
    wrapperCol: {span: 9},
};

const tailLayout = {
    wrapperCol: {offset: 3, span: 9},
};

class UserSetting extends React.Component {
    formRef = React.createRef();
    state = {
        userName: '',
        realName: '',
        tel: '',
        email: '',
        qq: '',
        weiXin: '',
        address: '',
        idCard: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (userName === null || Util.isEmpty(userName)) {
            this.props.history.push("/login")
        } else {
            Axios({
                method: 'get',
                url: '/user/getUserInfo/' + userName,
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            userName: res.data.data.userName,
                            realName: res.data.data.realName,
                            tel: res.data.data.tel,
                            email: res.data.data.email,
                            qq: res.data.data.qq,
                            weiXin: res.data.data.weiXin,
                            address: res.data.data.address,
                            idCard: res.data.data.idCard
                        });
                        this.formRef.current.setFieldsValue({
                            userName: res.data.data.userName,
                            realName: res.data.data.realName,
                            tel: res.data.data.tel,
                            email: res.data.data.email,
                            qq: res.data.data.qq,
                            weiXin: res.data.data.weiXin,
                            address: res.data.data.address,
                            idCard: res.data.data.idCard
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }

    onSubmit = () => {
        const user = {
            userName: this.state.userName,
            realName: this.state.realName,
            tel: this.state.tel,
            email: this.state.email,
            qq: this.state.qq,
            weiXin: this.state.weiXin,
            address: this.state.address,
            idCard: this.state.idCard
        }
        Axios({
            method: 'post',
            url: '/user/updateUser',
            data: JSON.stringify(user)
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    message.success(res.data.message);
                } else {
                    message.error(res.data.message);
                }
            } else {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    updatePassword = () => {
        if (this.state.newPassword !== this.state.confirmPassword) {
            message.error("确认密码与新密码不一致！");
        } else {
            Axios({
                method: 'post',
                url: '/user/updateUserPassword',
                params: {
                    userName: this.state.userName,
                    password: this.state.newPassword,
                    oldPassword: this.state.oldPassword
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        message.success(res.data.message);
                    } else {
                        message.error(res.data.message);
                    }
                } else {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    render() {
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="个人信息" key="1">
                            <Form
                                {...layout}
                                ref={this.formRef}
                                onFinish={this.onSubmit}>
                                <Form.Item
                                    label="账户"
                                    name="userName">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="姓名"
                                    name="realName">
                                    <Input onChange={this.inputChange}/>
                                </Form.Item>
                                <Form.Item
                                    label="电话"
                                    name="tel"
                                    rules={[{
                                        validator: async (_, value, callback) => {
                                            if (Util.isTel(value)) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject('请输入正确的手机号码');
                                            }
                                        }
                                    }]}>
                                    <Input onChange={this.inputChange}/>
                                </Form.Item>
                                {/*<Form.Item*/}
                                {/*    label="邮箱"*/}
                                {/*    name="email"*/}
                                {/*    rules={[{*/}
                                {/*        validator: async (_, value) => {*/}
                                {/*            if (Util.isEmpty(value)) {*/}
                                {/*                return true;*/}
                                {/*            } else {*/}
                                {/*                if (Util.isEmail(value)) {*/}
                                {/*                    return Promise.resolve()*/}
                                {/*                } else {*/}
                                {/*                    return Promise.reject('请输入正确的邮箱地址');*/}
                                {/*                }*/}
                                {/*            }*/}
                                {/*        }*/}
                                {/*    }]}>*/}
                                {/*    <Input onChange={this.inputChange}/>*/}
                                {/*</Form.Item>*/}
                                {/*<Form.Item*/}
                                {/*    label="QQ"*/}
                                {/*    name="qq">*/}
                                {/*    <Input onChange={this.inputChange}/>*/}
                                {/*</Form.Item>*/}
                                {/*<Form.Item*/}
                                {/*    label="微信"*/}
                                {/*    name="weiXin">*/}
                                {/*    <Input onChange={this.inputChange}/>*/}
                                {/*</Form.Item>*/}
                                <Form.Item
                                    label="收货地址"
                                    name="address">
                                    <Input onChange={this.inputChange}/>
                                </Form.Item>
                                <Form.Item
                                    label="身份证号"
                                    name="idCard"
                                    rules={[{
                                        validator: async (_, value) => {
                                            if (Util.isEmpty(value)) {
                                                return true;
                                            } else {
                                                if (Util.isIdCard(value)) {
                                                    return Promise.resolve()
                                                } else {
                                                    return Promise.reject('请输入正确的身份证号');
                                                }
                                            }
                                        }
                                    }]}>
                                    <Input onChange={this.inputChange}/>
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                        修改
                                    </Button>
                                </Form.Item>
                            </Form>
                        </TabPane>
                        <TabPane tab="安全信息" key="2">
                            <Form onFinish={this.updatePassword}
                                  {...layout}>
                                <Form.Item
                                    label="原始密码"
                                    name="oldPassword"
                                    rules={[
                                        {required: true, message: '请输入原始密码!'},
                                    ]}>
                                    <Input.Password onChange={this.inputChange}/>
                                </Form.Item>
                                <Form.Item
                                    label="新密码"
                                    name="newPassword"
                                    rules={[
                                        {required: true, message: '请输入新密码!'},
                                        {min: 4, message: '密码不能少于6个字符'},
                                        {max: 20, message: '密码不能多于20个字符'}
                                    ]}>
                                    <Input.Password onChange={this.inputChange}/>
                                </Form.Item>
                                <Form.Item
                                    label="确认密码"
                                    name="confirmPassword"
                                    rules={[
                                        {required: true, message: '请输入确认密码!'},
                                    ]}>
                                    <Input.Password onChange={this.inputChange}/>
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                        修改
                                    </Button>
                                </Form.Item>
                            </Form>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default UserSetting;