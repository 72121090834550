import React from 'react'
import {
    Button,
    Form,
    Input,
    message,
    Modal,
    Row,
    Space,
    Table,
    Image,
    Upload,
} from "antd";
import style from "../Content.module.css";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import Editor from 'wangeditor-antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import PATH_URL from "../../../utils/url";

class CarKnowledgeInfo extends React.Component {
    addFormRef = React.createRef();
    viewFormRef = React.createRef();
    editFormRef = React.createRef();
    editorRef = React.createRef();
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        title: '',
        addVisible: false,
        viewVisible: false,
        editVisible: false,
        imageFile: null,
        content: null,
        fileList: [],
        userPermission: null,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.getUserPermission();
        }
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: '/menu/getUserMenu/' + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    initData = () => {
        Axios({
            method: 'get',
            url: '/carKnowledge/listCarKnowledge',
            params: {
                title: this.state.title,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchCarKnowledge = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    handleCancel = () => {
        this.setState({
            viewVisible: false,
            editVisible: false,
            addVisible: false,
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            title: '',
        });
    }
    showAddCarKnowledge = () => {
        this.setState({
            addVisible: true,
        })
        setTimeout(() => {
            this.initEditor('');
        }, 100);
    }
    showCarKnowledge = (record) => {
        this.setState({
            viewVisible: true,
        })
        setTimeout(() => {
            this.viewFormRef.current.setFieldsValue(record);
            this.initEditor(record.content);
        }, 100);
    }
    showEditCarKnowledge = (record) => {
        this.setState({
            editVisible: true,
        })
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
            this.initEditor(record.content);
        }, 100);
    }
    showDeleteCarKnowledge = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该车辆知识？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: '/carKnowledge/deleteCarKnowledge/' + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    addCarKnowledge = () => {
        this.addFormRef.current.validateFields().then(values => {
            if (Util.isEmpty(this.state.content)) {
                message.error("请输入内容！");
                return;
            }
            let formData = new FormData();
            values.content = this.state.content;
            formData.append('carKnowledge', new Blob([JSON.stringify(values)], {type: "application/json"}));
            formData.append('imageFile', this.state.imageFile);
            Axios({
                method: 'post',
                url: '/carKnowledge/addCarKnowledge',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            addVisible: false,
                            imageFile: null,
                            fileList: [],
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.addFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    updateCarKnowledge = () => {
        this.editFormRef.current.validateFields().then(values => {
            let formData = new FormData();
            values.content = this.state.content;
            formData.append('carKnowledge', new Blob([JSON.stringify(values)], {type: "application/json"}));
            formData.append('imageFile', this.state.imageFile);
            Axios({
                method: 'post',
                url: '/carKnowledge/updateCarKnowledge',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                            imageFile: null,
                            fileList: [],
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    onRemove = (file) => {
        this.setState({
            fileList: []
        })
    }
    initEditor = (html) => {
        const _this = this;
        this.editor = new Editor(this.editorRef.current)
        this.editor.customConfig.menus = [
            'head',  // 标题
            'bold',  // 粗体
            'fontSize',  // 字号
            'fontName',  // 字体
            'italic',  // 斜体
            'underline',  // 下划线
            'strikeThrough',  // 删除线
            'foreColor',  // 文字颜色
            'backColor',  // 背景颜色
            'link',  // 插入链接
            'list',  // 列表
            'justify',  // 对齐方式
            'quote',  // 引用
            'image',  // 插入图片
            'table',  // 表格
            'undo',  // 撤销
            'redo'  // 重复
        ]
        this.editor.customConfig.uploadImgServer = PATH_URL.BASE_URL+'/carKnowledge/uploadFile'
        this.editor.customConfig.uploadFileName = 'imageFile'
        // 将图片大小限制为 3M
        this.editor.customConfig.uploadImgMaxSize = 3 * 1024 * 1024
        this.editor.customConfig.onchange = (html) => {
            // html为拿到的在div富文本框中输入的内容（使用样式时会自动带上标签）
            // 如：<p><span style="font-weight: bold;">演示<span style="color: rgb(249, 150, 59);">文本</span></span></p>
            _this.setState({
                content: html,
            })
        }
        this.editor.create();
        this.editor.txt.html(html);
    }
    fileChange = (e) => {
        this.setState({
            imageFile: (e.fileList.length > 0 && (e.file.size / (1024 * 1024) < 10)) ? e.fileList[0].originFileObj : null,
        })
    }
    beforeUpload = (file, fileList) => {
        if (file.size / (1024 * 1024) >= 10) {
            message.error("文件大小不能超过10M！", 2);
            this.setState({
                fileList: [],
                imageFile: null
            })
        } else {
            this.setState({
                fileList: fileList,
                imageFile: file
            })
        }
        return false;
    }

    render() {
        const layout = {
            labelCol: {span: 2},
            wrapperCol: {span: 22},
        };
        const columns = [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '图标',
                dataIndex: 'image',
                key: 'image',
                render: image => {
                    if (!Util.isEmpty(image)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={image}/>
                        )
                    }
                }
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <Space size="small">
                        {this.state.userPermission != null && this.state.userPermission.includes('300069') ? (
                            <a onClick={this.showCarKnowledge.bind(this, record)}>查看</a>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('300070') ? (
                            <a onClick={this.showEditCarKnowledge.bind(this, record)}>修改</a>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('300071') ? (
                            <a style={{'color': '#d43f3a'}}
                               onClick={this.showDeleteCarKnowledge.bind(this, record)}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Row>
                        <Form layout='inline'>
                            <Form.Item
                                label="标题"
                                name="title"
                                style={{width: 200}}>
                                <Input placeholder="请输入标题"
                                       onChange={this.inputChange}/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={this.searchCarKnowledge}>查询</Button>
                                <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                        onClick={this.resetSearch}>重置</Button>
                                {this.state.userPermission != null && this.state.userPermission.includes('300050') ? (
                                    <Button type="primary" style={{'marginLeft': '10px'}}
                                            onClick={this.showAddCarKnowledge}>添加</Button>) : null
                                }
                            </Form.Item>
                        </Form>
                    </Row>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                新增
                            </div>
                        }
                        width={1000}
                        visible={this.state.addVisible}
                        onOk={this.addCarKnowledge}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.addFormRef}>
                            <Form.Item
                                label="标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题'}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="内容"
                                name="content">
                                <div className='rich-text-field' ref={this.editorRef}></div>
                            </Form.Item>
                            <Form.Item
                                label="图标">
                                <Upload
                                    name="file"
                                    accept=".png,.jpg,.jpeg"
                                    maxCount={1}
                                    fileList={this.state.fileList}
                                    onChange={this.fileChange}
                                    onRemove={this.onRemove}
                                    beforeUpload={this.beforeUpload}>
                                    <Button type="primary">
                                        选择文件
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                查看
                            </div>
                        }
                        width={1000}
                        visible={this.state.viewVisible}
                        onOk={this.handleCancel}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.viewFormRef}>
                            <Form.Item
                                label="车辆知识ID"
                                name="id"
                                hidden="true">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题'}]}>
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="内容"
                                name="content">
                                <div className='rich-text-field' ref={this.editorRef}></div>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                修改
                            </div>
                        }
                        width={1000}
                        visible={this.state.editVisible}
                        onOk={this.updateCarKnowledge}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.editFormRef}>
                            <Form.Item
                                label="车辆知识ID"
                                name="id"
                                hidden="true">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题'}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="内容"
                                name="content">
                                <div className='rich-text-field' ref={this.editorRef}></div>
                            </Form.Item>
                            <Form.Item
                                label="商品图片">
                                <Upload
                                    name="file"
                                    accept=".png,.jpg,.jpeg"
                                    maxCount={1}
                                    fileList={this.state.fileList}
                                    onChange={this.fileChange}
                                    onRemove={this.onRemove}
                                    beforeUpload={this.beforeUpload}>
                                    <Button type="primary">
                                        选择文件
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.data}
                           rowKey={columns => columns.id}
                           loading={this.state.loading}
                           style={{'marginTop': '20px'}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                </div>
            </div>
        )
    }
}

export default CarKnowledgeInfo;