const PATH_URL = {
    BASE_URL: "http://www.szjws.top:8081/jws",
    //BASE_URL: "http://106.14.29.12:8081/jws",
    GET_USER_MENU_URL: "menu/getUserMenu",
    LIST_GROUPS_URL: "groups/listGroups",
    ADD_GROUPS_URL: "groups/addGroups",
    DELETE_GROUPS_URL: "groups/deleteGroups",
    UPDATE_GROUPS_URL: "groups/updateGroups",
    AGREEMENT_UPLOAD_FILE_URL: "agreement/uploadFile",
    LIST_AGREEMENT_URL: "agreement/listAgreement",
    ADD_AGREEMENT_URL: "agreement/addAgreement",
    UPDATE_AGREEMENT_URL: "agreement/updateAgreement",
    DELETE_AGREEMENT_URL: "agreement/deleteAgreement",
    UPDATE_AGREEMENT_STATUS_URL: "agreement/changeAgreementStatus",
    ADD_WORK_ORDER_URL: "workOrder/addWorkOrder",

    LIST_WORK_ORDER_URL: "workOrder/listWorkOrder",
    UPDATE_WORK_ORDER_URL: "workOrder/updateWorkOrder",
    DELETE_WORK_ORDER_URL: "workOrder/deleteWorkOrder",
    HANDLE_WORK_ORDER_URL: "workOrder/handleWorkOrder",
    WORK_ORDER_UPLOAD_FILE_URL: "workOrder/uploadFile",
    EXPORT_WORK_ORDER_INFO_URL: "workOrder/exportWorkOrderInfo",
    LIST_WORK_ORDER_IDS_URL: "workOrder/listWorkOrderIds",
    TEAM_MEMBERS_QR_CODE_URL: "user/getTeamMembersCode",
    MAJOR_ACCOUNT_QR_CODE_URL: "user/getMajorAccountCode",
    GET_ACHIEVEMENT_INFO_URL: "achievement/getAchievementInfo",
    LIST_ACHIEVEMENT_INFO_URL: "achievement/listAchievement",
    LIST_COMPLETED_ORDER_INFO_URL: "achievement/listCompletedOrderInfo",
    LIST_CANCEL_ORDER_INF_URL: "achievement/listCancelAchievement",
    LIST_INVITATION_USER_INFO_URL: "achievement/listInvitationUserInfo",
    LIST_INVITATION_USER_URL: "achievement/invitationUser",
    LIST_ORDER_SUMMARY_URL: "order/listOrderSummary",
    LIST_GROUP_USER: "user/listGroupUser",
    LIST_ACHIEVEMENT_DETAILS_URL: "achievement/listAchievementDetails",
    LIST_MAJOR_ACCOUNT_INFO_URL: "operationOrTeamInfo/listMajorAccountInfo",
    LIST_MAJOR_ACCOUNT_INFO_USER_NAMES_URL: "operationOrTeamInfo/listMajorAccountInfoUserNames",
    EXPORT_MAJOR_ACCOUNT_INFO_URL: "operationOrTeamInfo/exportMajorAccountInfo",
    LIST_SERVICE_SITE_URL: "serviceSite/listServiceSite",
    LIST_SERVICE_ACCOUNT_URL: "user/listServiceAccount",
    ADD_SERVICE_SITE_URL: "serviceSite/addServiceSite",
    UPDATE_SERVICE_SITE_URL: "serviceSite/updateServiceSite",
    CHANGE_SITE_STATUS_URL: "serviceSite/changeServiceSiteStatus",
    DELETE_SERVICE_SITE_URL: "serviceSite/deleteServiceSite",
    LIST_SERVICE_SITE_IDS_URL: "serviceSite/listServiceSiteIds",
    EXPORT_SERVICE_SITE_INFO_URL: "serviceSite/exportServiceSiteInfo",
    LIST_EXCHANGE_ORDER_URL: "exchangeOrder/listExchangeOrder",
    LIST_EXCHANGE_ROLL_RUL: "exchangeRoll/listExchangeRoll",
    ADD_EXCHANGE_ROLL_URL: "exchangeRoll/addExchangeRoll",
    DELETE_EXCHANGE_ROLL_URL: "exchangeRoll/deleteExchangeRoll",
    UPDATE_EXCHANGE_ROLL_URL: "exchangeRoll/updateExchangeRoll",
    LIST_EXCHANGE_RECORD_URL: "integralExchangeRecord/listExchangeRecord",
    DELETE_EXCHANGE_RECORD_URL: "integralExchangeRecord/deleteExchangeRecord",
    LIST_ROLL_FLOW_RECORD_URL: "rollFlowRecord/listRollFlowRecord",
    DELETE_ROLL_FLOW_RECORD_URL: "rollFlowRecord/deleteRollFlowRecord",
    LIST_USER_ROLL_INFO_URL: "userRollInfo/listUserRollInfo",
    TRANSFER_EXCHANGE_ROLL_URL: "exchangeRoll/transferExchangeRoll",
    EXPORT_ACHIEVEMENT_INFO_URL: "achievement/exportAchievementInfo",
    LIST_TEAM_OR_OPERATOR_ACHIEVEMENT_NO_PAGE_URL: "achievement/listTeamOrOperatorAchievementNoPage",
    LIST_TEAM_OR_OPERATOR_ACHIEVEMENT_URL: "achievement/listTeamOrOperatorAchievement",
    LIST_TURN_TO_INCREASE_RECORD_URL: "turnToIncreaseRecord/listTurnToIncreaseRecord",
    DELETE_TURN_TO_INCREASE_RECORD_URL: "turnToIncreaseRecord/deleteTurnToIncreaseRecord",
    DELETE_USER_ROLL_INFO_URL: "userRollInfo/deleteUserRollInfo",
    TRANSFER_USER_ROLL_INFO_URL: "userRollInfo/transferUserRollInfo",
    LIST_USER_RILL_INFO_IDS_URL: "userRollInfo/listUserRollInfoIds",
    BATCH_TRANSFER_USER_ROLL_INFO_URL: "userRollInfo/batchTransferUserRollInfo",
    DELETE_EXCHANGE_ORDER_URL: "exchangeOrder/deleteExchangeOrder",
    LIST_EXCHANGE_ORDER_IDS_URL: "exchangeOrder/listExchangeOrderIds",
    EXPORT_EXCHANGE_ORDER_INFO_URL: "exchangeOrder/exportExchangeOrderInfo",
    GET_SERVICE_SITE_BY_USER_NAME_URL: "serviceSite/getServiceSiteByUserName",
    WRITE_OFF_EXCHANGE_ORDER_URL: "exchangeOrder/writeOffExchangeOrder",
    LIST_SUBSTITUTE_DRIVING_URL: "substituteDriving/listSubstituteDriving",
    EXPORT_SUBSTITUTE_DRIVING_INFO_URL: "substituteDriving/exportSubstituteDrivingInfo",
    LIST_SUBSTITUTE_DRIVING_IDS_URL: "substituteDriving/listSubstituteDrivingIds",
    DELETE_SUBSTITUTE_DRIVING_URL: "substituteDriving/deleteSubstituteDriving",
    CANCEL_SUBSTITUTE_DRIVING_URL: "substituteDriving/cancelSubstituteDriving",
    COMPLETE_SUBSTITUTE_DRIVING_URL: "substituteDriving/completeSubstituteDriving",
    GET_USER_INFO_URL: "user/getUserInfo",
    sendReserveCode: "order/sendReserveCode"
}
PATH_URL.TRAVEL = {
    LIST_TRAVEL_INFO_URL: "travel/list",
    ADD_TRAVEL_INFO_URL: "travel/add",
    DELETE_TRAVEL_INFO_URL: "travel/delete",
    UPDATE_TRAVEL_INFO_STATUS_URL: "travel/changeTravelStatus",
    UPDATE_TRAVEL_INFO_URL: "travel/update",
    UPLOAD_FILE_URL: "travel/updateFile",
    GET_TRAVEL_USER_INVITATION_CODE_URL: "travel/getTravelUserInvitationCode",
    GET_TRAVEL_USER_INVITATION_QR_CODE_URL: "travel/getTravelUserInvitationQrCode",
    ADD_TRAVEL_BANNER_IMAGE_URL: "travel/travelBannerImage/add",
    DELETE_TRAVEL_BANNER_IMAGE_URL: "travel/travelBannerImage/delete",
    LIST_TRAVEL_BANNER_IMAGE_URL: "travel/travelBannerImage/list",
}
PATH_URL.TRAVEL_ORDER = {
    LIST_TRAVEL_ORDER_INFO_URL: "travelOrder/list",
    DELETE_TRAVEL_ORDER_INFO_URL: "travelOrder/delete",
    CANCEL_TRAVEL_ORDER_INFO_URL: "travelOrder/cancel",
    TRAVELED_TRAVEL_ORDER_INFO_URL: "travelOrder/traveled",
    UPLOAD_PAYMENT_VOUCHER_URL: "travelOrder/uploadPaymentVoucher",
    LIST_TRAVEL_USER_INFO_URL: "travelOrder/listTravelUserInfo",
    LIST_TRAVEL_USER_INFO_NO_PAGE_URL: "travelOrder/listTravelUserInfoNoPage",
    LIST_TRAVEL_ORDER_NO_PAGE_INFO_URL: "travelOrder/listTravelOrderNoPage",
    EXPORT_TRAVEL_ORDER_INFO_URL: "travelOrder/exportTravelOrder",
    EXPORT_TRAVEL_USER_INFO_URL: "travelOrder/exportTravelUserInfo",
}
PATH_URL.TRAVEL_ACHIEVEMENT = {
    GET_TRAVEL_ACHIEVEMENT_URL: "travelAchievement/getTravelAchievement",
    LIST_COMPLETED_TRAVEL_ORDER_URL: "travelAchievement/listCompletedTravelOrder",
    LIST_CANCELED_TRAVEL_ORDER_URL: "travelAchievement/listCanceledTravelOrder",
    LIST_TRAVEL_ORDER_DETAILED_URL: "travelAchievement/listTravelOrderDetailed",
    LIST_TRAVEL_ORDER_INFO_BY_GROUP_URL: "travelAchievement/listTravelOrderByGroup",
    LIST_TRAVEL_TEAM_INFO_URL: "travelAchievement/listTravelTeamInfo",
    LIST_TRAVEL_TEAM_INFO_NO_PAGE_URL: "travelAchievement/listTravelTeamInfoNoPage",
    EXPORT_TRAVEL_TEAM_INFO_URL: "travelAchievement/exportTravelTeamInfo",
}
PATH_URL.TRAVEL_USER = {
    LIST_TRAVEL_USER_URL: "travelUserInfo/list",
    LIST_TRAVEL_USER_NO_PAGE_URL: "travelUserInfo/listNoPage",
    EXPORT_TRAVEL_USER_INFO_URL: "travelUserInfo/exportTravelUserInfo",
}
export default PATH_URL;