import React from 'react'
import {Button, Form, Input, Row, message, DatePicker, Table, Select, Checkbox} from "antd";
import zh_CN from "antd/es/date-picker/locale/zh_CN";
import 'moment/locale/zh-cn';
import moment from 'moment';
import Axios from "../../../utils/axios";
import "../../../utils/date"

const {RangePicker} = DatePicker;
moment.locale('zh-cn');


class UserOrderList extends React.Component {
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        licensePlate: '',
        orderStartTime: '',
        orderEndTime: '',
        completeStartTime: '',
        completeEndTime: '',
        status: '',
        isExporting: false,
        selectedRowKeys: [],
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (userRole !== 0 && userRole !== 1) {
            message.error("你没有权限访问!");
            this.props.history.push("/login");
        } else {
            this.initData();
        }
    }

    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleOrderSelectTime = (value, dateString) => {
        this.setState({
            orderStartTime: dateString[0],
            orderEndTime: dateString[1],
        })
    }
    handleCompleteSelectTime = (value, dateString) => {
        this.setState({
            completeStartTime: dateString[0],
            completeEndTime: dateString[1],
        })
    }
    resetSearch = () => {
        this.setState({
            licensePlate: '',
            orderStartTime: '',
            orderEndTime: '',
            completeStartTime: '',
            completeEndTime: '',
            status: '',
        });
    }
    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: '/userOrderInfo/listUserOrderListIds',
                params: {
                    userName: this.props.userName,
                    licensePlate: this.state.licensePlate,
                    orderStartTime: this.state.orderStartTime,
                    orderEndTime: this.state.orderEndTime,
                    completeStartTime: this.state.completeStartTime,
                    completeEndTime: this.state.completeEndTime,
                    status: this.state.status,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    initData = () => {
        Axios({
            method: 'get',
            url: '/userOrderInfo/listUserOrderList',
            params: {
                userName: this.props.userName,
                licensePlate: this.state.licensePlate,
                orderStartTime: this.state.orderStartTime,
                orderEndTime: this.state.orderEndTime,
                completeStartTime: this.state.completeStartTime,
                completeEndTime: this.state.completeEndTime,
                status: this.state.status,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchOrder = () => {
        this.initData();
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    exportOrderInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            let exportOrderDTO={};
            exportOrderDTO.ids=this.state.selectedRowKeys;
            exportOrderDTO.userRole=JSON.parse(window.sessionStorage.getItem("userRole"));
            Axios({
                method: 'post',
                url: '/order/exportOrderInfo',
                data: JSON.stringify(exportOrderDTO),
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '订单数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll
        };
        const columns = [
            {
                title: '车牌',
                dataIndex: 'licensePlate',
                key: 'licensePlate',
                fixed: 'left',
                width: 100,
            },
            {
                title: '车主姓名',
                dataIndex: 'ownerName',
                key: 'ownerName',
                fixed: 'left',
                width: 150,
            },
            // {
            //     title: '车型',
            //     dataIndex: 'carType',
            //     key: 'carType',
            // },
            {
                title: '车辆类型',
                dataIndex: 'carModel',
                key: 'carModel',
            },
            {
                title: '车辆注册时间',
                dataIndex: 'registrationTime',
                key: 'registrationTime',
            },
            {
                title: '车主手机号',
                dataIndex: 'ownerTel',
                key: 'ownerTel',
            },
            {
                title: '预约站点',
                dataIndex: 'siteName',
                key: 'siteName',
                ellipsis: true,
            },
            {
                title: '站点电话',
                dataIndex: 'siteTel',
                key: 'siteTel',
            },
            {
                title: '预约人',
                dataIndex: 'orderByName',
                key: 'orderByName',
            },
            {
                title: '预约人手机号',
                dataIndex: 'orderBy',
                key: 'orderBy',
            },
            {
                title: '预约时间',
                dataIndex: 'orderTime',
                key: 'orderTime',
            },
            {
                title: '渠道',
                dataIndex: 'source',
                key: 'source',
                render: source => {
                    switch (source) {
                        case 0:
                            return '管理员';
                        case 1:
                            return '线上电呼';
                        case 2:
                            return '业务团队';
                        case 4:
                            return '车主';
                        default:
                            return '管理员';
                    }
                }
            },
            {
                title: '完成验车时间',
                dataIndex: 'completeTime',
                key: 'completeTime',
            },
            {
                title: '订单创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 165,
            },
            {
                title: '取消验车时间',
                dataIndex: 'gmtCancel',
                key: 'gmtCancel',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },
            {
                title: '预约状态',
                dataIndex: 'status',
                key: 'status',
                fixed: 'right',
                width: 80,
                render: status => {
                    switch (status) {
                        case 0:
                            return '已预约';
                        case 1:
                            return '已完成';
                        case 2:
                            return '已取消';
                        default:
                            return '已预约';
                    }
                }
            }
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Row>
                        <Form.Item
                            label="预约车牌"
                            name="licensePlate"
                            style={{width: 270, marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入车牌"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="预约状态"
                            name="status"
                            style={{width: 250, marginTop: '5px', marginBottom: '5px'}}>
                            <Select onChange={this.statusChange}>
                                <Select.Option value="0">已预约</Select.Option>
                                <Select.Option value="1">已完成</Select.Option>
                                <Select.Option value="2">已取消</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="预约时间"
                            name="orderTime"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <RangePicker locale={zh_CN} onChange={this.handleOrderSelectTime}/>
                        </Form.Item>
                        <Form.Item
                            label="完成时间"
                            name="completeTime"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <RangePicker locale={zh_CN} onChange={this.handleCompleteSelectTime}/>
                        </Form.Item>
                        <Form.Item
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <Button type="primary" onClick={this.searchOrder}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '5px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {this.props.userPermission != null && this.props.userPermission.includes('400006') ? (
                                <Button type="primary" style={{'marginLeft': '5px'}}
                                        onClick={this.exportOrderInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null
                            }
                        </Form.Item>
                    </Row>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       rowSelection={rowSelection}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       scroll={{x: 2500}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <div style={{
                    marginTop: "-38px",
                    marginLeft: "8px",
                    display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                }}>
                    < Checkbox onChange={this.exportAllSelect}
                               checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                </div>
            </div>
        )
    }
}

export default UserOrderList;