import React from 'react'
import {
    message,
    Tabs,
} from "antd";
import style from "../Content.module.css";
import Integral from "./Integral";
import IntegralRecord from "./IntegralRecord";
import Axios from "../../../utils/axios";

const {TabPane} = Tabs;

class IntegralInfo extends React.Component {
    state = {
        userPermission: null,
    }

    componentDidMount() {
        this.getUserPermission();
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: '/menu/getUserMenu/' + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Tabs defaultActiveKey="1">
                        {this.state.userPermission != null && this.state.userPermission.includes('200011') ? (
                            <TabPane tab="积分信息" key="1">
                                <Integral userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200012') ? (
                            <TabPane tab="积分记录" key="2">
                                <IntegralRecord userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default IntegralInfo;