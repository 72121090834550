import React from 'react'
import {Button, Form, Input, message, Modal, Row, Space, Table} from "antd";
import Axios from "../../../utils/axios";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Util from "../../../utils/utils";

const {TextArea} = Input;

class CommodityCategory extends React.Component {
    addFormRef = React.createRef();
    editFormRef = React.createRef();
    state = {
        data: null,
        loading: true,
        categoryName: null,
        addVisible: false,
        editVisible: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: '/commodityCategory/listCommodityCategory',
            params: {
                categoryName: this.state.categoryName,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data,
                        loading: false,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    addCategory = () => {
        this.addFormRef.current.validateFields().then(values => {
            Axios({
                method: 'post',
                url: '/commodityCategory/addCommodityCategory',
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            addVisible: false,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.addFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    editCategory = () => {
        this.editFormRef.current.validateFields().then(values => {
            Axios({
                method: 'post',
                url: '/commodityCategory/updateCommodityCategory',
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    resetSearch = () => {
        this.setState({
            categoryName: null
        })
    }
    searchCategory = () => {
        this.initData();
    }
    showAddCategory = () => {
        this.setState({
            addVisible: true,
        });
    }
    showEditCategory = (record) => {
        this.setState({
            editVisible: true,
        })
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
        }, 100);
    }
    showDeleteCategory = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该类别？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: '/commodityCategory/deleteCommodityCategory/' + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleCancel = () => {
        this.setState({
            editVisible: false,
            addVisible: false,
        });
    }

    render() {
        const layout = {
            labelCol: {span: 5},
            wrapperCol: {span: 19},
        };
        const columns = [
            {
                title: '类别名称',
                dataIndex: 'categoryName',
                key: 'categoryName',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <Space size="small">
                        {this.props.userPermission != null && this.props.userPermission.includes('300061') ? (
                            <a onClick={this.showEditCategory.bind(this, record)}>修改</a>) : null
                        }
                        {this.props.userPermission != null && this.props.userPermission.includes('300062') ? (
                            <a style={{'color': '#d43f3a'}}
                               onClick={this.showDeleteCategory.bind(this, record)}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Row>
                    <Form layout='inline'>
                        <Form.Item
                            label="类别名称"
                            name="categoryName"
                            style={{width: 200}}>
                            <Input placeholder="请输入类别"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.searchCategory}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {this.props.userPermission != null && this.props.userPermission.includes('300048') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.showAddCategory}>添加</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                </Row>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            新增
                        </div>
                    }
                    visible={this.state.addVisible}
                    onOk={this.addCategory}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.addFormRef}>
                        <Form.Item
                            label="类别"
                            name="categoryName"
                            rules={[{required: true, message: '请输入类别'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="备注"
                            name="remarks">
                            <TextArea rows={4}/>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            修改
                        </div>
                    }
                    visible={this.state.editVisible}
                    onOk={this.editCategory}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.editFormRef}>
                        <Form.Item
                            label="类别ID"
                            name="id"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="类别"
                            name="categoryName"
                            rules={[{required: true, message: '请输入类别'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="备注"
                            name="remarks">
                            <TextArea rows={4}/>
                        </Form.Item>
                    </Form>
                </Modal>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}/>
            </div>
        )
    }

}

export default CommodityCategory;