import React from 'react'
import style from "../Content.module.css";
import {Table, Space, Input, Form, Row, Button, message, Modal} from "antd";
import Axios from "../../../utils/axios";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Util from "../../../utils/utils";

const {TextArea} = Input;

class CarModelInfo extends React.Component {
    addFormRef = React.createRef();
    viewFormRef = React.createRef();
    editFormRef = React.createRef();
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        modelName: '',
        addVisible: false,
        viewVisible: false,
        editVisible: false,
        userPermission: null,
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.getUserPermission();
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: '/menu/getUserMenu/' + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    searchCarModel = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    addCarModel = () => {
        this.addFormRef.current.validateFields().then(values => {
            Axios({
                method: 'post',
                url: '/carModel/addCarModel',
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            addVisible: false,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.addFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    updateCarModel = () => {
        this.editFormRef.current.validateFields().then(values => {
            Axios({
                method: 'post',
                url: '/carModel/updateCarModel',
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    initData = () => {
        Axios({
            method: 'get',
            url: '/carModel/listCarModel',
            params: {
                modelName: this.state.modelName,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    showAddCarModel = () => {
        this.setState({
            addVisible: true,
        });
    }
    showEditCarModel = (record) => {
        this.setState({
            editVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
        }, 100);
    }
    showCarModel = (record) => {
        this.setState({
            viewVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.viewFormRef.current.setFieldsValue(record);
        }, 100);
    }
    showDeleteCarModel = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该车型？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: '/carModel/deleteCarModel/' + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleCancel = () => {
        this.setState({
            viewVisible: false,
            editVisible: false,
            addVisible: false,
        });
    }

    render() {
        const layout = {
            labelCol: {span: 5},
            wrapperCol: {span: 19},
        };
        const columns = [
            {
                title: '车辆类型',
                dataIndex: 'modelName',
                key: 'modelName',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <Space size="small">
                        {this.state.userPermission != null && this.state.userPermission.includes('300027') ? (
                            <a onClick={this.showCarModel.bind(this, record)}>查看</a>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('300028') ? (
                            <a onClick={this.showEditCarModel.bind(this, record)}>修改</a>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('300029') ? (
                            <a style={{'color': '#d43f3a'}}
                               onClick={this.showDeleteCarModel.bind(this, record)}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];

        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Row>
                        <Form layout='inline'>
                            <Form.Item
                                label="车辆类型"
                                name="modelName"
                                style={{width: 200}}>
                                <Input placeholder="请输入类型"
                                       onChange={this.inputChange}/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={this.searchCarModel}>查询</Button>
                                {this.state.userPermission != null && this.state.userPermission.includes('300026') ? (
                                    <Button type="primary" style={{'marginLeft': '10px'}}
                                            onClick={this.showAddCarModel}>添加</Button>) : null
                                }
                            </Form.Item>
                        </Form>
                    </Row>
                    <div>
                        <Modal
                            title={
                                <div
                                    style={{
                                        width: '100%',
                                        cursor: 'move',
                                    }}>
                                    新增
                                </div>
                            }
                            visible={this.state.addVisible}
                            onOk={this.addCarModel}
                            onCancel={this.handleCancel}>
                            <Form {...layout}
                                  ref={this.addFormRef}>
                                <Form.Item
                                    label="车型"
                                    name="modelName"
                                    rules={[{required: true, message: '请输入车型'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="备注"
                                    name="remarks">
                                    <TextArea rows={4}/>
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            title={
                                <div
                                    style={{
                                        width: '100%',
                                        cursor: 'move',
                                    }}>
                                    查看
                                </div>
                            }
                            visible={this.state.viewVisible}
                            onOk={this.handleCancel}
                            onCancel={this.handleCancel}>
                            <Form {...layout}
                                  ref={this.viewFormRef}>
                                <Form.Item
                                    label="车型"
                                    name="modelName">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="备注"
                                    name="remarks">
                                    <TextArea disabled rows={4}/>
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            title={
                                <div
                                    style={{
                                        width: '100%',
                                        cursor: 'move',
                                    }}>
                                    修改
                                </div>
                            }
                            visible={this.state.editVisible}
                            onOk={this.updateCarModel}
                            onCancel={this.handleCancel}>
                            <Form {...layout}
                                  ref={this.editFormRef}>
                                <Form.Item
                                    label="车型ID"
                                    name="id"
                                    hidden="true">
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="车型"
                                    name="modelName"
                                    rules={[{required: true, message: '请输入车型'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="备注"
                                    name="remarks">
                                    <TextArea rows={4}/>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.data}
                           rowKey={columns => columns.id}
                           loading={this.state.loading}
                           style={{'marginTop': '20px'}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                </div>
            </div>
        );
    }
}

export default CarModelInfo;