import React from 'react'
import {Button, Checkbox, Form, Input, message, Modal, Row, Select, Space, Table} from "antd";
import Axios from "../../../utils/axios";
import UserOrderList from "./UserOrderList";

class UserOrderInfo extends React.Component {
    state = {
        data: null,
        visible: false,
        realName: '',
        userName: '',
        status: '',
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
        viewUserName: null,
        selectedRowKeys: [],
        isExporting: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (userRole !== 0) {
            message.error("你没有权限访问!");
            this.props.history.push("/login");
        } else {
            this.initData();
        }
    }

    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: '/userOrderInfo/listUserOrderInfoUserNames',
                params: {
                    userName: this.state.userName,
                    status: this.state.status,
                    realName: this.state.realName,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    initData = () => {
        Axios({
            method: 'get',
            url: '/userOrderInfo/listUserOrderInfo',
            params: {
                userName: this.state.userName,
                status: this.state.status,
                realName: this.state.realName,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchUserOrderInfo = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    showOrderInfo = (record) => {
        this.setState({
            visible: true,
            viewUserName: record.userName,
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            userName: '',
            realName: '',
            status: '',
        });
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            viewUserName: null,
        });
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.userName);
        } else {
            const index = selectedRowKeys.indexOf(record.userName);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    exportUserOrderInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: '/userOrderInfo/exportUserOrderInfo/' + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '车主数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll
        };
        const columns = [
            {
                title: '账户',
                dataIndex: 'userName',
                key: 'userName',
                fixed: 'left',
            },
            {
                title: '姓名',
                dataIndex: 'realName',
                key: 'realName',
                fixed: 'left',
            },
            {
                title: '累计获取积分',
                dataIndex: 'sumIntegral',
                key: 'sumIntegral',
            },
            {
                title: '已取消订单数',
                dataIndex: 'sumCanceled',
                key: 'sumCanceled',
            },
            {
                title: '累计完成订单数',
                dataIndex: 'sumCompleted',
                key: 'sumCompleted',
            },
            {
                title: '注册时间',
                dataIndex: 'registerTime',
                key: 'registerTime',
                width: 200,
            },
            {
                title: '用户状态',
                dataIndex: 'status',
                key: 'status',
                render: userStatus => {
                    switch (userStatus) {
                        case 0:
                            return '启用中';
                        case 1:
                            return '已禁用';
                        default:
                            return '启用中';
                    }
                }
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },

            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        {this.props.userPermission != null && this.props.userPermission.includes('300018') ? (
                            <a onClick={this.showOrderInfo.bind(this, record)}>订单查看</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Row>
                    <Form layout='inline'>
                        <Form.Item
                            label="账户"
                            name="userName"
                            style={{width: 200}}>
                            <Input placeholder="请输入账户"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="姓名"
                            name="realName"
                            style={{width: 200}}>
                            <Input placeholder="请输入姓名"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="status"
                            style={{width: 130}}>
                            <Select onChange={this.statusChange}>
                                <Select.Option value="0">启用中</Select.Option>
                                <Select.Option value="1">禁用中</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.searchUserOrderInfo}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {this.props.userPermission != null && this.props.userPermission.includes('300017') ? (
                                <Button type="primary" style={{'marginLeft': '5px'}}
                                        onClick={this.exportUserOrderInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                </Row>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            订单查看
                        </div>
                    }
                    visible={this.state.visible}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    width={1000}>
                    <UserOrderList userName={this.state.viewUserName} userPermission={this.props.userPermission}/>
                </Modal>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.userName}
                       rowSelection={rowSelection}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       scroll={{x: 1500}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <div style={{
                    marginTop: "-38px",
                    marginLeft: "8px",
                    display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                }}>
                    < Checkbox onChange={this.exportAllSelect}
                               checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                </div>
            </div>
        )
    }
}

export default UserOrderInfo;