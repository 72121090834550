import React from 'react';
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import {message} from "antd";

export default class TravelAchievementInfo extends React.Component {
    state = {
        sumCompleted: 0,
        sumCanceled: 0,
        sumUser: 0,
        sumTravelOrder: 0,
        sumUnPay: 0,
        sumPayed: 0,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.TRAVEL_ACHIEVEMENT.GET_TRAVEL_ACHIEVEMENT_URL,
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        sumUser: res.data.data.sumUser === null ? 0 : res.data.data.sumUser,
                        sumTravelOrder: res.data.data.sumTravelOrder === null ? 0 : res.data.data.sumTravelOrder,
                        sumUnPay: res.data.data.sumUnPay === null ? 0 : res.data.data.sumUnPay,
                        sumPayed: res.data.data.sumPayed === null ? 0 : res.data.data.sumPayed,
                        sumCompleted: res.data.data.sumCompleted === null ? 0 : res.data.data.sumCompleted,
                        sumCanceled: res.data.data.sumCanceled === null ? 0 : res.data.data.sumCanceled,
                    });
                } else {
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <div>
                <p>累计邀请人数: {this.state.sumUser}</p>
                <p>累计订单数: {this.state.sumTravelOrder}</p>
                <p>累计未付款订单数: {this.state.sumUnPay}</p>
                <p>累计已付款订单数: {this.state.sumPayed}</p>
                <p>累计已完成订单数: {this.state.sumCompleted}</p>
                <p>累计已取消订单数: {this.state.sumCanceled}</p>
            </div>
        )
    }
}
