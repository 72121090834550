import axios from 'axios';
import {message} from "antd";
import PATH_URL from "./url";

const Axios = axios.create({
    baseURL: PATH_URL.BASE_URL, // 设置请求的base url
    timeout: 5 * 60 * 1000, // 设置超时时长
    withCredentials: true,// 是否跨站点访问控制请求
    headers: {
        get: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        post: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
})
/**
 * 请求拦截器
 */
Axios.interceptors.request.use(
    config => {
        const token = window.sessionStorage.getItem("token");
        token && (config.headers.token = token);
        return config;
    },
    error => {
        Promise.error(error);
    }
)
/**
 * 响应拦截器
 */
Axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.toString() === "Error: Network Error") {
            message.error("网络错误！");
            return Promise.reject(error);
        } else if (error.response.status) {
            if (error.response.status === 403) {
                window.sessionStorage.clear();
                window.location.href = "/login";
            } else {
                return Promise.reject(error.response);
            }
        } else {
            return Promise.reject(error);
        }
    }
)

export default Axios

