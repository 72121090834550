import React from 'react';
import style from "../Content.module.css";
import {Tabs} from "antd";
import UserRollInfo from "../exchangeManage/UserRollInfo";
import TransferRecord from "../exchangeManage/TransferRecord";

const {TabPane} = Tabs;

class MyExchangeRollTab extends React.Component {
    state = {
        activeKey: 1,
    }
    onChange = (key) => {
        this.setState({
            activeKey: key
        })
    }

    render() {
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Tabs defaultActiveKey="1" onChange={this.onChange}>
                        <TabPane tab="我的兑换卷" key="1">
                            <UserRollInfo userName={JSON.parse(window.sessionStorage.getItem("userName"))}/>
                        </TabPane>
                        <TabPane tab="卷转赠记录" key="2">
                            <TransferRecord userName={JSON.parse(window.sessionStorage.getItem("userName"))}/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default MyExchangeRollTab;