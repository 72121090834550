import React from 'react'
import style from "../Content.module.css";
import {Tabs} from "antd";
import AchievementInfoTab from "./AchievementInfoTab";
import CompletedOrderTab from "./CompletedOrderTab";
import CancelOrderTab from "./CancelOrderTab";
import InvitationUserTab from "./InvitationUserTab";
import OrderSummaryTab from "./OrderSummaryTab";

const {TabPane} = Tabs;

let userRole = null;

class AchievementInfo extends React.Component {
    constructor(props) {
        super(props);
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
    }

    render() {
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="业绩信息" key="1">
                            <AchievementInfoTab/>
                        </TabPane>
                        <TabPane tab="已完成订单" key="2">
                            <CompletedOrderTab/>
                        </TabPane>
                        <TabPane tab="已取消订单" key="3">
                            <CancelOrderTab/>
                        </TabPane>
                        {
                            userRole === 6 ? null : (
                                <TabPane tab="邀请用户" key="4">
                                    <InvitationUserTab source={0}/>
                                </TabPane>)
                        }
                        {
                            userRole === 5 ? (
                                <TabPane tab="订单汇总" key="5">
                                    <OrderSummaryTab/>
                                </TabPane>
                            ) : null
                        }
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default AchievementInfo;