import React from 'react'
import {Button, Form, Input, message, Row, Tree, Table, Space, Modal} from "antd";
import style from "../Content.module.css";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";

class JurisdictionInfo extends React.Component {
    state = {
        data: null,
        treeData: null,
        userMenuCodeList: [],
        defaultUserMenuCodeList: null,
        userName: null,
        permissionVisible: false,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        permissionUserName: null,
        userPermission: null,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.initTreeData();
            this.getUserPermission(userName, true);
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: '/user/listUser',
            params: {
                userName: this.state.userName,
                role: 0,
                status: 0,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    initTreeData = () => {
        Axios({
            method: 'get',
            url: '/menu/getTreeData',
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    treeData: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    getUserPermission = (userName, isInit) => {
        Axios({
            method: 'get',
            url: '/menu/getUserMenu/' + userName,
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    defaultUserMenuCodeList: isInit ? null : res.data.data,
                    permissionVisible: isInit ? false : true,
                });
                if (userName === JSON.parse(window.sessionStorage.getItem("userName"))) {
                    this.setState({
                        userPermission: res.data.data,
                    })
                }
                if(!isInit){
                    this.setState({
                        userMenuCodeList:res.data.data,
                    })
                }
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    searchUser = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            userName: null,
        });
    }
    showPermissionSetting = (record) => {
        this.setState({
            permissionUserName: record.userName,
        })
        this.getUserPermission(record.userName, false);

    }
    handleCancel = () => {
        this.setState({
            permissionVisible: false,
        });
    }
    savePermission = () => {
        if (this.state.userMenuCodeList != null) {
            Axios({
                method: 'post',
                url: '/menu/addUserMenuList/' + this.state.permissionUserName,
                data: this.state.userMenuCodeList,
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            permissionVisible: false,
                        });
                        message.success(res.data.message);
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
    permissionCheck = (checkedKeys) => {
        this.setState({
            userMenuCodeList: checkedKeys.checked,
        })
    }

    render() {
        const columns = [
            {
                title: '账户',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <Space size="small">
                        {this.state.userPermission != null && this.state.userPermission.includes('300051') ? (
                            <a onClick={this.showPermissionSetting.bind(this, record)}>权限设置</a>) : null
                        }
                    </Space>
                ),
            },

        ];
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Row>
                        <Form layout='inline'>
                            <Form.Item
                                label="账号"
                                name="userName"
                                style={{width: 200}}>
                                <Input placeholder="请输入账号"
                                       onChange={this.inputChange}/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={this.searchUser}>查询</Button>
                                <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                        onClick={this.resetSearch}>重置</Button>
                            </Form.Item>
                        </Form>
                    </Row>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                权限设置
                            </div>
                        }
                        visible={this.state.permissionVisible}
                        onOk={this.savePermission}
                        onCancel={this.handleCancel}
                        destroyOnClose={true}>
                        <Tree
                            checkable
                            checkStrictly={true}
                            onCheck={this.permissionCheck}
                            defaultCheckedKeys={this.state.defaultUserMenuCodeList}
                            treeData={this.state.treeData}
                        />
                    </Modal>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.data}
                           rowKey={columns => columns.id}
                           loading={this.state.loading}
                           style={{'marginTop': '20px'}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                </div>
            </div>
        );
    }
}

export default JurisdictionInfo;