import React from 'react';
import Header from '../Header';
import LeftMenu from '../LeftMenu'
import Util from '../../../utils/utils';
import style from '../Home.module.css';
import UserSetting from "./UserSetting";


class Setting extends React.Component {
    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (userName === null || Util.isEmpty(userName)) {
            this.props.history.push("/login")
        }
    }

    render() {
        return (
            <div className={style['home']}>
                <Header history={this.props.history}/>
                <LeftMenu history={this.props.history}/>
                <UserSetting history={this.props.history}/>
            </div>

        )
    }
}

export default Setting;