import React from 'react';
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import {message} from "antd";
import PATH_URL from "../../../utils/url";

let userRole = null;
let userName = null;

class AchievementInfoTab extends React.Component {
    state = {
        sumIntegral: 0,
        sumCompleted: 0,
        sumCanceled: 0,
        sumUser: 0,
    }

    constructor(props) {
        super(props);
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initAchievementInfo();
        }
    }

    initAchievementInfo = () => {
        Axios({
            method: 'get',
            url: PATH_URL.GET_ACHIEVEMENT_INFO_URL,
            params: {
                userName: userName,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        sumUser: res.data.data.sumUser === null ? 0 : res.data.data.sumUser,
                        sumIntegral: userRole === 1 ? (res.data.data.sumOperatorIntegral === null ? 0 : res.data.data.sumOperatorIntegral) : (res.data.data.sumTeamIntegral === null ? 0 : res.data.data.sumTeamIntegral),
                        sumCompleted: res.data.data.sumCompleted === null ? 0 : res.data.data.sumCompleted,
                        sumCanceled: res.data.data.sumCanceled === null ? 0 : res.data.data.sumCanceled,
                    });
                } else {
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <div>
                {
                    (userRole === 5 || userRole === 6) ? null : (
                        <p>累计获取积分: {this.state.sumIntegral}</p>)
                }
                {
                    userRole === 6 ? null : (<p>累计邀请人数: {this.state.sumUser}</p>)
                }
                <p>累计已完成订单数: {this.state.sumCompleted}</p>
                <p>累计已取消订单数: {this.state.sumCanceled}</p>
            </div>
        )
    }
}

export default AchievementInfoTab;