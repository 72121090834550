import React from 'react';
import {Tabs} from "antd";
import TravelAchievementInfo from "./TravelAchievementInfo";
import TravelOrderCompleted from "./TravelOrderCompleted";
import TravelOrderCanceled from "./TravelOrderCanceled";
import InvitationUserTab from "../achievement/InvitationUserTab";
import TravelOrderSummary from "./TravelOrderSummary";

const {TabPane} = Tabs;
export default class TravelAchievement extends React.Component {
    render() {
        return (
            <div>
                <Tabs defaultActiveKey="1" onChange={this.onChange} tabPosition="left" size="small">
                    <TabPane tab="业绩信息" key="1">
                        <TravelAchievementInfo/>
                    </TabPane>
                    <TabPane tab="已完成订单" key="2">
                        <TravelOrderCompleted/>
                    </TabPane>
                    <TabPane tab="取消订单" key="3">
                        <TravelOrderCanceled/>
                    </TabPane>
                    <TabPane tab="邀请用户" key="4">
                        <InvitationUserTab source={1}/>
                    </TabPane>
                    <TabPane tab="订单汇总" key="5">
                        <TravelOrderSummary/>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}