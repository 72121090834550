import React from 'react';
import style from "../Content.module.css";
import ExchangeOrderInfo from "../serviceSite/ExchangeOrderInfo";

class ExchangeOrder extends React.Component {
    render() {
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <ExchangeOrderInfo />
                </div>
            </div>
        )
    }
}

export default ExchangeOrder;