import React from 'react';
import style from './Content.module.css'

class Content extends React.Component{
    render() {
        return(
            <div className={style['content']} id='content'>
                <div id="content" className={style['content_info']}>
                    <div className={style['content_home']}></div>
                </div>
            </div>
        )
    }
}
export default Content;