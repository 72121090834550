import React from 'react';
import {Button, Checkbox, DatePicker, Form, Input, message, Modal, Select, Table} from "antd";
import Axios from "../../../utils/axios";
import Util from "../../../utils/utils";
import PATH_URL from "../../../utils/url";
import style from "../Content.module.css";
import OrderSummaryTab from "../achievement/OrderSummaryTab";

const qs = require('qs');

class AchievementManageInfo extends React.Component {
    state = {
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        data: null,
        year: null,
        month: null,
        userName: null,
        realName: null,
        isExporting: false,
        selectedRowKeys: [],
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
        userPermission: null,
        roleList: [],
        rolePermission: [],
        achievementUserName: null,
        showAchievementModel: false,
    }

    componentDidMount() {
        if (!Util.isEmpty(JSON.parse(window.sessionStorage.getItem("userName")))) {
            this.getUserPermission();
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: PATH_URL.GET_USER_MENU_URL + "/" + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
                const rolePermission = this.state.rolePermission;
                if (res.data.data !== null) {
                    if (res.data.data.includes('300110')) {
                        rolePermission.push(1)
                    }
                    if (res.data.data.includes('300111')) {
                        rolePermission.push(2)
                    }
                    if (res.data.data.includes('300112')) {
                        rolePermission.push(5)
                    }
                    this.setState({
                        rolePermission: rolePermission
                    })
                    this.initData();
                }
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_TEAM_OR_OPERATOR_ACHIEVEMENT_URL,
            params: {
                userName: this.state.userName,
                realName: this.state.realName,
                year: this.state.year,
                month: this.state.month,
                roleList: this.state.roleList.length === 0 ? (this.state.rolePermission.length === 0 ? [-1] : this.state.rolePermission) : this.state.roleList,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            },
            paramsSerializer: function (params) {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchAchievement = () => {
        this.setState({
            loading: true
        })
        this.initData();
    }
    onDatePickerYearChange = (value, dateString) => {
        this.setState({
            year: dateString
        })
    }
    monthChange = (value) => {
        this.setState({
            month: value
        })
    }
    roleChange = (value) => {
        const roleList = [];
        roleList.push(value);
        this.setState({
            roleList: roleList,
        })
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            year: null,
            month: null,
            userName: null,
            realName: null,
            roleList: [],
        })
    }
    exportAchievementInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: PATH_URL.EXPORT_ACHIEVEMENT_INFO_URL + "/" + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '业绩数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }

    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: PATH_URL.LIST_TEAM_OR_OPERATOR_ACHIEVEMENT_NO_PAGE_URL,
                params: {
                    userName: this.state.userName,
                    realName: this.state.realName,
                    roleList: this.state.roleList.length === 0 ? (this.state.rolePermission.length === 0 ? [-1] : this.state.rolePermission) : this.state.roleList,
                    year: this.state.year,
                    month: this.state.month,
                },
                paramsSerializer: function (params) {
                    return qs.stringify(params, {arrayFormat: 'repeat'})
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        this.setState({
                            loading: false,
                        });
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.message);
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.userName + "-" + record.date);
        } else {
            const index = selectedRowKeys.indexOf(record.userName + "-" + record.date);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].userName + "-" + changeRows[i].date);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    showAchievement = (record) => {
        this.setState({
            achievementUserName: record.userName,
            showAchievementModel: true,
        })
    }
    closeAchievementModel = () => {
        this.setState({
            showAchievementModel: false,
        })
    }

    render() {
        const columns = [
            {
                title: '账户',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: '姓名',
                dataIndex: 'realName',
                key: 'realName',
                render: (text, record) => {
                    if (record.role === 5) {
                        return <span>{text}<a onClick={this.showAchievement.bind(this, record)}> (详情)</a></span>
                    } else {
                        return text
                    }
                }
            },
            {
                title: '角色',
                dataIndex: 'role',
                key: 'role',
                render: role => {
                    switch (role) {
                        case 1:
                            return '线上电呼';
                        case 2:
                            return '业务团队';
                        case 5:
                            return '大客户';
                        default:
                            return '线上电呼';
                    }
                }
            },
            {
                title: '年度',
                dataIndex: 'year',
                key: 'year',
            },
            {
                title: '月份',
                dataIndex: 'month',
                key: 'month',
            },
            {
                title: '完成验车数',
                dataIndex: 'sumCompleted',
                key: 'sumCompleted',
            },
            {
                title: '累计获取积分',
                dataIndex: 'sumIntegral',
                key: 'sumIntegral',
                render: (text, record, index) => {
                    if (record.sumTeamIntegral > 0) {
                        return record.sumTeamIntegral;
                    } else if (record.sumOperatorIntegral > 0) {
                        return record.sumOperatorIntegral;
                    } else {
                        return 0
                    }
                }
            },
        ];

        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll,
        };
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Form layout='inline'>
                        <Form.Item
                            label="账户"
                            name="userName"
                            style={{width: 180}}>
                            <Input placeholder="请输入账户"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="姓名"
                            name="realName"
                            style={{width: 200}}>
                            <Input placeholder="请输入姓名"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        {this.state.userPermission != null && this.state.userPermission.includes('300109') ? (
                            <Form.Item
                                label="角色"
                                name="role"
                                style={{width: 150}}>
                                <Select onChange={this.roleChange}>
                                    {
                                        this.state.userPermission.includes('300110') ? (
                                            <Select.Option value="1">线上电呼</Select.Option>) : null
                                    }
                                    {
                                        this.state.userPermission.includes('300111') ? (
                                            <Select.Option value="2">业务团队</Select.Option>) : null
                                    }
                                    {
                                        this.state.userPermission.includes('300112') ? (
                                            <Select.Option value="5">大客户</Select.Option>) : null
                                    }
                                </Select>
                            </Form.Item>
                        ) : null}
                        <Form.Item
                            label="年度"
                            name="year"
                            style={{width: 160}}>
                            <DatePicker onChange={this.onDatePickerYearChange} picker="year"/>
                        </Form.Item>
                        <Form.Item
                            label="月份"
                            name="month"
                            style={{width: 110}}>
                            <Select onChange={this.monthChange}>
                                <Select.Option value="01">1月</Select.Option>
                                <Select.Option value="02">2月</Select.Option>
                                <Select.Option value="03">3月</Select.Option>
                                <Select.Option value="04">4月</Select.Option>
                                <Select.Option value="05">5月</Select.Option>
                                <Select.Option value="06">6月</Select.Option>
                                <Select.Option value="07">7月</Select.Option>
                                <Select.Option value="08">8月</Select.Option>
                                <Select.Option value="09">9月</Select.Option>
                                <Select.Option value="10">10月</Select.Option>
                                <Select.Option value="11">11月</Select.Option>
                                <Select.Option value="12">12月</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.searchAchievement}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {
                                this.state.userPermission != null && this.state.userPermission.includes('300102') ? (
                                    <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                            onClick={this.exportAchievementInfo}>导出</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.data}
                           rowKey={columns => (columns.userName + "-" + columns.date)}
                           loading={this.state.loading}
                           rowSelection={rowSelection}
                           style={{'marginTop': '20px'}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                    <div style={{
                        marginTop: "-38px",
                        marginLeft: "15px",
                        display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                    }}>
                        <Checkbox onChange={this.exportAllSelect}
                                  checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                    </div>
                </div>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            业绩汇总
                        </div>
                    }
                    visible={this.state.showAchievementModel}
                    onOk={this.closeAchievementModel}
                    onCancel={this.closeAchievementModel}
                    destroyOnClose={true}
                    width={1000}>
                    <OrderSummaryTab userName={this.state.achievementUserName}/>
                </Modal>
            </div>
        )
    }
}

export default AchievementManageInfo;