import React from 'react'
import Util from "../../../utils/utils";
import {Button, Checkbox, Form, Image, Input, message, Modal, Select, Space, Table} from "antd";
import RollCirculationRecordModel from "./RollCirculationRecordModel";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import {ExclamationCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";

let userRole;
const qs = require('qs');

class UserRollInfo extends React.Component {
    transferFormRef = React.createRef();
    state = {
        rollName: null,
        userName: null,
        rollNumber: null,
        isExpired: null,
        status: null,
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        viewRollNumber: null,
        visible: false,
        transferVisible: false,
        isTransferring: false,
        selectedRowKeys: [],
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
        isBatchTransfer: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_USER_ROLL_INFO_URL,
            params: {
                rollName: this.state.rollName,
                userName: userRole !== 0 ? this.props.userName : this.state.userName,
                rollNumber: this.state.rollNumber,
                status: this.state.status,
                isExpired: this.state.isExpired,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchUserRollInfo = () => {
        this.setState({
            loading: true,
        })
        this.initData();
    }
    resetSearch = () => {
        this.setState({
            rollName: null,
            userName: null,
            rollNumber: null,
            isExpired: null,
            status: null,
        });
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    isExpiredChange = (value) => {
        this.setState({
            isExpired: value,
        })
    }
    showRollCirculationRecord = (e) => {
        this.setState({
            viewRollNumber: e.target.innerText,
            visible: true,
        })

    }
    handleCancel = () => {
        this.setState({
            visible: false,
            transferVisible: false,
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    showTransferRoll = (record) => {
        this.setState({
            transferVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.transferFormRef.current.setFieldsValue(record);
        }, 100);
    }
    transferRoll = () => {
        if (this.state.isBatchTransfer) {//批量转赠
            let _this = this
            this.transferFormRef.current.validateFields().then(values => {
                Modal.confirm({
                    icon: <InfoCircleOutlined/>,
                    content: "确认转赠这些兑换卷给" + values.transferUserName,
                    onOk: () => {
                        Axios({
                            method: 'post',
                            url: PATH_URL.BATCH_TRANSFER_USER_ROLL_INFO_URL,
                            params: {
                                ids: this.state.selectedRowKeys,
                                userName: JSON.parse(window.sessionStorage.getItem("userName")),
                                transferUserName: values.transferUserName,
                            },
                            paramsSerializer: function (params) {
                                return qs.stringify(params, {arrayFormat: 'repeat'})
                            }
                        }).then(res => {
                            if (res.status === 200) {
                                if (res.data.code === 200) {
                                    _this.setState({
                                        loading: true,
                                        transferVisible: false,
                                        isTransferring: false,
                                        isBatchTransfer: false,
                                        selectedRowKeys: [],
                                        selected: [],
                                        isCheckAll: false,
                                        checkboxText: '全选',
                                    });
                                    message.success(res.data.message);
                                    _this.initData();
                                    _this.transferFormRef.current.resetFields();
                                } else {
                                    _this.setState({
                                        transferVisible: false,
                                        isTransferring: false,
                                        isBatchTransfer: false,
                                        selectedRowKeys: [],
                                        selected: [],
                                        isCheckAll: false,
                                        checkboxText: '全选',
                                    });
                                    message.error(res.data.message);
                                }
                            } else if (res.code === 401) {
                                _this.setState({
                                    transferVisible: false,
                                    isTransferring: false,
                                    isBatchTransfer: false,
                                    selectedRowKeys: [],
                                    selected: [],
                                    isCheckAll: false,
                                    checkboxText: '全选',
                                });
                                message.error(res.message);
                            }
                        }).catch(err => {
                            _this.setState({
                                transferVisible: false,
                                isTransferring: false,
                                isBatchTransfer: false,
                                selectedRowKeys: [],
                                selected: [],
                                isCheckAll: false,
                                checkboxText: '全选',
                            });
                            console.log(err);
                        });
                    },
                    onCancel: () => {

                    },
                });
            }).catch(info => {
                console.log(info);
            });
        } else {//单独转赠
            let _this = this
            this.transferFormRef.current.validateFields().then(values => {
                const rollNumberList = [];
                rollNumberList.push(values.rollNumber);
                Modal.confirm({
                    icon: <InfoCircleOutlined/>,
                    content: "确认转赠该兑换卷给" + values.transferUserName,
                    onOk: () => {
                        Axios({
                            method: 'post',
                            url: PATH_URL.TRANSFER_USER_ROLL_INFO_URL,
                            data: JSON.stringify({
                                rollNumberList: rollNumberList,
                                userName: JSON.parse(window.sessionStorage.getItem("userName")),
                                transferUserName: values.transferUserName,
                            })
                        }).then(res => {
                            if (res.status === 200) {
                                if (res.data.code === 200) {
                                    _this.setState({
                                        loading: true,
                                        transferVisible: false,
                                    });
                                    message.success(res.data.message);
                                    _this.initData();
                                    _this.transferFormRef.current.resetFields();
                                } else {
                                    message.error(res.data.message);
                                }
                            } else if (res.code === 401) {
                                message.error(res.message);
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                    },
                    onCancel: () => {

                    },
                });
            }).catch(info => {
                console.log(info);
            });
        }
    }
    showDeleteExchangeRoll = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该兑换卷？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.DELETE_USER_ROLL_INFO_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    transferAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: PATH_URL.LIST_USER_RILL_INFO_IDS_URL,
                params: {
                    userName: userRole !== 0 ? JSON.parse(window.sessionStorage.getItem("userName")) : this.state.userName,
                    rollName: this.state.rollName,
                    rollNumber: this.state.rollNumber,
                    status: this.state.status,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        this.setState({
                            loading: false,
                        });
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.message);
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    batchTransfer = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要转赠的卷!");
        } else {
            this.setState({
                isTransferring: true,
                transferVisible: true,
                isBatchTransfer: true,
            })
        }
    }

    render() {
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const columns = [
            {
                title: '卷编号',
                dataIndex: 'rollNumber',
                key: 'rollNumber',
                width: 300,
                render: rollNumber => {
                    return (<a onClick={this.showRollCirculationRecord.bind(this)}>{rollNumber}</a>)
                }
            },
            {
                title: '兑换卷名称',
                dataIndex: 'rollName',
                key: 'rollName',
                width: 250,
                ellipsis: true,
            },
            {
                title: '兑换卷图片',
                dataIndex: 'rollImage',
                key: 'rollImage',
                render: rollImage => {
                    if (!Util.isEmpty(rollImage)) {
                        return (
                            <Image
                                width={20}
                                height={20}
                                src={rollImage}/>
                        )
                    }
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                width: 65,
                render: (status) => {
                    switch (status) {
                        case 0:
                            return '未使用';
                        case 1:
                            return '已使用';
                        case 2:
                            return '已转赠';
                        default:
                            return '未使用';
                    }
                }
            },
            {
                title: '所属账户',
                dataIndex: 'userName',
                key: 'userName',
                ellipsis: true,
                width: 150
            },
            {
                title: '是否过期',
                dataIndex: 'expirationDate',
                key: 'expirationDate',
                width: 80,
                render: (expirationDate) => {
                    if (expirationDate != null) {
                        if (new Date().format("yyyy-MM-dd HH:mm:ss") > expirationDate) {
                            return "是";
                        } else {
                            return "否";
                        }
                    } else {
                        return "否";
                    }

                }
            },
            {
                title: '卷过期时间',
                dataIndex: 'expirationDate',
                key: 'expirationDate',
                width: 100,
                render: expirationDate => {
                    if (expirationDate != null) {
                        return expirationDate.substring(0, 10);
                    } else {
                        return null;
                    }

                }
            },
            {
                title: '操作',
                key: 'action',
                width: 80,
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        {
                            record.status !== 2 ? (<a onClick={this.showTransferRoll.bind(this, record)}>转赠</a>) : null
                        }
                        {
                            userRole !== 0 ? null:(<a style={{'color': '#d43f3a'}}
                                                     onClick={this.showDeleteExchangeRoll.bind(this, record)}>删除</a>)
                        }
                    </Space>
                ),
            },
        ];
        if (userRole !== 0) {
            columns.splice(3, 3)
        }
        if (userRole === 0) {
            columns.splice(6, 1)
        }
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll,
        };
        return (
            <div>
                <Form layout='inline'>
                    {
                        userRole !== 0 ? null : (<Form.Item
                            label="账户"
                            name="userName"
                            style={{width: 270, marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入账户"
                                   onChange={this.inputChange}/>
                        </Form.Item>)
                    }
                    <Form.Item
                        label="兑换卷名称"
                        name="rollName"
                        style={{width: 270, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入兑换卷名称"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="兑换卷编号"
                        name="rollNumber"
                        style={{width: 400, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入兑换卷编号"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="卷状态"
                        name="status"
                        style={{width: 150, marginTop: '5px', marginBottom: '5px'}}>
                        <Select onChange={this.statusChange}>
                            <Select.Option value="0">未使用</Select.Option>
                            <Select.Option value="1">已使用</Select.Option>
                            <Select.Option value="2">已转赠</Select.Option>
                        </Select>
                    </Form.Item>
                    {
                        userRole !== 0 ? null : (<div>
                                <Form.Item
                                    label="是否过期"
                                    name="isExpired"
                                    style={{width: 150, marginTop: '5px', marginBottom: '5px'}}>
                                    <Select onChange={this.isExpiredChange}>
                                        <Select.Option value="0">未过期</Select.Option>
                                        <Select.Option value="1">已过期</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        )
                    }
                    <Form.Item
                        style={{marginTop: '5px', marginBottom: '5px'}}>
                        <Button type="primary" onClick={this.searchUserRollInfo}
                                style={{'marginLeft': '10px'}}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                        {
                            userRole !== 0 ? (<Button type="primary" style={{'marginLeft': '10px'}}
                                                      disabled={this.state.isTransferring}
                                                      onClick={this.batchTransfer}>批量转赠</Button>) : null
                        }
                    </Form.Item>
                </Form>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            卷流程记录
                        </div>
                    }
                    width={1100}
                    visible={this.state.visible}
                    onOk={this.handleCancel}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}>
                    <RollCirculationRecordModel rollNumber={this.state.viewRollNumber}/>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            转赠
                        </div>
                    }
                    visible={this.state.transferVisible}
                    onOk={this.transferRoll}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.transferFormRef}>
                        <Form.Item
                            label="兑换卷编码"
                            name="rollNumber"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="账户账户"
                            name="transferUserName"
                            rules={[{
                                required: true, validator: async (_, value, callback) => {
                                    if (Util.isTel(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确的账户');
                                    }
                                }
                            }]}>
                            <Input/>
                        </Form.Item>
                    </Form>
                </Modal>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       rowSelection={userRole === 0 ? null : rowSelection}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                {
                    userRole !== 0 ? (<div style={{
                        marginTop: "-38px",
                        marginLeft: "20px",
                        display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                    }}>
                        <Checkbox onChange={this.transferAllSelect}
                                  checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                    </div>) : null
                }
            </div>
        )
    }
}

export default UserRollInfo;