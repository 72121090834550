import React from 'react';
import {Button, Form, Input, message, Row, Table} from "antd";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";

let userName = null;

class InvitationUserDetailed extends React.Component {
    state = {
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        data: null,
        userName: null,
        realName: null,
    }

    componentDidMount() {
        userName = JSON.parse(window.sessionStorage.getItem("userName"));
        this.initData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            userName: null,
            realName: null,
        });
    }
    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_INVITATION_USER_INFO_URL,
            params: {
                source: this.props.source,
                invitationUser: userName,
                userName: this.state.userName,
                realName: this.state.realName,
                year: this.props.year,
                month: this.props.month,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchUserInfo = () => {
        this.initData();
    }

    render() {
        const columns = [
            {
                title: '账户',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: '姓名',
                dataIndex: 'realName',
                key: 'realName',
            },
            {
                title: '电话',
                dataIndex: 'tel',
                key: 'tel',
            },
            {
                title: '地址',
                dataIndex: 'address',
                key: 'address',
                ellipsis: true,
            },
            {
                title: '身份证',
                dataIndex: 'idCard',
                key: 'idCard',
                ellipsis: true,
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            }
        ]
        return (
            <div>
                <Form layout='inline'>
                    <Row>
                        <Form.Item
                            label="账户"
                            name="userName"
                            style={{width: 200}}>
                            <Input placeholder="请输入账户"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="姓名"
                            name="realName"
                            style={{width: 200}}>
                            <Input placeholder="请输入姓名"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.searchUserInfo}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '17px'}}
                                    onClick={this.resetSearch}>重置</Button>
                        </Form.Item>
                    </Row>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
            </div>
        )
    }
}

export default InvitationUserDetailed;