import React from 'react'
import style from "../Content.module.css";
import {message, Tabs} from "antd";
import TravelManage from "./TravelManage";
import TravelOrderManage from "./TravelOrderManage";
import TravelAchievement from "./TravelAchievement";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import TravelBannerImage from "./TravelBannerImage";
import TravelMembers from "./TravelMembers";

let userRole = null;
const {TabPane} = Tabs;
export default class TourismManageTab extends React.Component {
    state = {
        activeKey: 1,
        userPermission: null,
    }

    constructor(props) {
        super(props);
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        this.getUserPermission();
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: PATH_URL.GET_USER_MENU_URL + "/" + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onChange = (key) => {
        this.setState({
            activeKey: key
        })
    }

    render() {
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Tabs defaultActiveKey="1" onChange={this.onChange}>
                        {
                            userRole === 0 && this.state.userPermission != null && this.state.userPermission.includes('200021') ? (
                                <TabPane tab="出行管理" key="1">
                                    <TravelManage userPermission={this.state.userPermission}/>
                                </TabPane>) : null
                        }
                        {
                            userRole !== 0 || (userRole === 0 && this.state.userPermission != null && this.state.userPermission.includes('200022')) ? (
                                <TabPane tab="订单管理" key="2">
                                    <TravelOrderManage userPermission={this.state.userPermission}/>
                                </TabPane>) : null
                        }
                        {
                            userRole !== 0 ? (<TabPane tab="业绩查询" key="3">
                                <TravelAchievement/>
                            </TabPane>) : null
                        }
                        {
                            userRole === 0 && this.state.userPermission != null && this.state.userPermission.includes('200025') ? (
                                <TabPane tab="出行人信息" key="4">
                                    <TravelMembers userPermission={this.state.userPermission}/>
                                </TabPane>) : null
                        }
                        {
                            userRole === 0 && this.state.userPermission != null && this.state.userPermission.includes('200024') ? (
                                <TabPane tab="首页图片" key="5">
                                    <TravelBannerImage userPermission={this.state.userPermission}/>
                                </TabPane>) : null
                        }
                    </Tabs>
                </div>
            </div>
        )
    }
}