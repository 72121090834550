import React from 'react'
import {
    Table, Space, Input, Form, Button, message, Modal, Select, Checkbox,
    Switch,
} from "antd";
import Axios from "../../../utils/axios";
import Util from "../../../utils/utils";
import {ExclamationCircleOutlined} from "@ant-design/icons";

let who = null;
const {TextArea} = Input;

class UserInfo extends React.Component {
    viewFormRef = React.createRef();
    editFormRef = React.createRef();
    addFormRef = React.createRef();
    state = {
        data: null,
        userName: '',
        realName: '',
        role: '',
        remarks: '',
        tel: '',
        idCard: '',
        status: '',
        invitationUserName: '',
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        selectedRowKeys: [],
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
        viewVisible: false,
        editVisible: false,
        addVisible: false,
        isExporting: false,
        isSend: false,
    }

    componentDidMount() {
        who = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(who)) {
            this.initData();
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: '/user/listUserIds',
                params: {
                    userName: this.state.userName,
                    realName: this.state.realName,
                    tel: this.state.tel,
                    idCard: this.state.idCard,
                    role: this.state.role,
                    status: this.state.status,
                    remarks: this.state.remarks,
                    invitationUserName: this.state.invitationUserName,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        let selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selected.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    searchUser = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    resetSearch = () => {
        this.setState({
            userName: '',
            realName: '',
            tel: '',
            idCard: '',
            role: '',
            status: '',
            remarks: '',
            invitationUserName: '',
        });
    }
    initData = () => {
        Axios({
            method: 'get',
            url: '/user/listUser',
            params: {
                userName: this.state.userName,
                realName: this.state.realName,
                tel: this.state.tel,
                idCard: this.state.idCard,
                role: this.state.role,
                status: this.state.status,
                invitationUserName: this.state.invitationUserName,
                remarks: this.state.remarks,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    addUser = () => {
        this.addFormRef.current.validateFields().then(values => {
            values.invitationUser = who;
            Axios({
                method: 'post',
                url: '/user/register',
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            addVisible: false,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.addFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    updateUser = () => {
        this.editFormRef.current.validateFields().then(values => {
            values.isSend = this.state.isSend === true ? 1 : 0
            Axios({
                method: 'post',
                url: '/user/updateUser',
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    showAddUser = () => {
        this.setState({
            addVisible: true,
        });
    }
    showUserInfo = (record) => {
        this.setState({
            viewVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.viewFormRef.current.setFieldsValue(record);
            this.viewFormRef.current.setFieldsValue({
                'role': record.role.toString()
            });
        }, 100);
    }
    showEditUser = (record) => {
        this.setState({
            editVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            console.log(record.isSend)
            this.editFormRef.current.setFieldsValue(record);
            this.editFormRef.current.setFieldsValue({
                'role': record.role.toString(),
                'isSend': record.isSend === '0' ? false : true
            });
        }, 100);
    }
    showDeleteUser = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该用户？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: '/user/deleteUser/' + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    showResetPassword = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认重置该用户密码？重置密码为123456",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: '/user/resetPassword/' + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    handleCancel = () => {
        this.setState({
            viewVisible: false,
            editVisible: false,
            addVisible: false,
        });
    }
    roleChange = (value) => {
        this.setState({
            role: value,
        })
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    exportUserInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: '/user/exportUserInfo/' + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '用户数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }
    changeSend = (checked) => {
        this.setState({
            isSend: checked,
        })
    }
    showChangeUserStatus = (record, status) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认" + (status === 0 ? "启用" : "禁用") + "该用户？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: '/user/changeUserStatus/' + record.id,
                    params: {
                        status: status,
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll
        };
        const layout = {
            labelCol: {span: 5},
            wrapperCol: {span: 19},
        };
        const columns = [
            {
                title: '账户',
                dataIndex: 'userName',
                key: 'userName',
                width: 150,
                fixed: 'left',
            },
            {
                title: '姓名',
                dataIndex: 'realName',
                key: 'realName',
                width: 150,
                fixed: 'left',
            },
            {
                title: '电话',
                dataIndex: 'tel',
                key: 'tel',
            },
            {
                title: '地址',
                dataIndex: 'address',
                key: 'address',
                ellipsis: true,
            },
            {
                title: '身份证',
                dataIndex: 'idCard',
                key: 'idCard',
                ellipsis: true,
            },
            {
                title: '邀请账户',
                dataIndex: 'invitationUser',
                key: 'invitationUser',
            },
            {
                title: '邀请人',
                dataIndex: 'invitationUserName',
                key: 'invitationUserName',
            },
            {
                title: '角色',
                dataIndex: 'role',
                key: 'role',
                render: role => {
                    switch (role) {
                        case 0:
                            return '管理员';
                        case 1:
                            return '线上电呼';
                        case 2:
                            return '业务团队';
                        case 3:
                            return '合作站点';
                        case 4:
                            return '车主';
                        case 5:
                            return '大客户';
                        case 6:
                            return '组员';
                        case 7:
                            return '服务网点';
                        case 8:
                            return '旅游团队';
                        case 9:
                            return '旅游团队成员';
                        default:
                            return '车主';
                    }
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    switch (status) {
                        case 0:
                            return '启用中';
                        case 1:
                            return '已禁用';
                        default:
                            return '启用中';
                    }
                }
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 170,
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                width: 180,
                render: (text, record) => (
                    <Space size="small">
                        {this.props.userPermission != null && this.props.userPermission.includes('300003') ?
                            (<a onClick={this.showUserInfo.bind(this, record)}>查看</a>) : null
                        }
                        {this.props.userPermission != null && this.props.userPermission.includes('300004') ?
                            (<a onClick={this.showEditUser.bind(this, record)}>修改</a>) : null
                        }
                        {this.props.userPermission != null && this.props.userPermission.includes('300005') ?
                            (<a onClick={this.showResetPassword.bind(this, record)}>重置密码</a>) : null
                        }
                        {who.toString() === record.userName.toString() ? ('') : (record.status === 0 ? (
                            this.props.userPermission != null && this.props.userPermission.includes('300006') ?
                                (<a style={{'color': '#f5ce0a'}}
                                    onClick={this.showChangeUserStatus.bind(this, record, 1)}>禁用</a>) : null
                        ) : (this.props.userPermission != null && this.props.userPermission.includes('300007') ?
                            (<a style={{'color': '#f5ce0a'}}
                                onClick={this.showChangeUserStatus.bind(this, record, 0)}>启用</a>) : null))}
                        {/*{who.toString() === record.userName.toString() ? ('') : (*/}
                        {/*    this.props.userPermission != null && this.props.userPermission.includes('300008') ?*/}
                        {/*        (<a style={{'color': '#d43f3a'}}*/}
                        {/*            onClick={this.showDeleteUser.bind(this, record)}>删除</a>) : null)}*/}
                    </Space>
                ),
            },
        ];
        const roleSelect = [
            <Select.Option value="0" key="0">管理员</Select.Option>,
            <Select.Option value="1" key="1">线上电呼</Select.Option>,
            <Select.Option value="2" key="2">业务团队</Select.Option>,
            <Select.Option value="3" key="3">合作站点</Select.Option>,
            <Select.Option value="4" key="4">车主</Select.Option>,
            <Select.Option value="5" key="5">大客户</Select.Option>,
            <Select.Option value="7" key="7">服务网点</Select.Option>,
            <Select.Option value="8" key="8">旅游团队</Select.Option>,
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item
                        label="账户"
                        name="userName"
                        style={{width: 200, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入账户"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="电话"
                        name="tel"
                        style={{width: 200, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入电话"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="姓名"
                        name="realName"
                        style={{width: 200, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入姓名"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="身份证"
                        name="idCard"
                        style={{width: 200, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入身份证号"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="角色"
                        name="role"
                        style={{width: 160, marginTop: '5px', marginBottom: '5px'}}>
                        <Select onChange={this.roleChange}>
                            {roleSelect.concat(<Select.Option value="9" key="9">旅游团队成员</Select.Option>)
                                .concat(<Select.Option value="6" key="6">组员</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="状态"
                        name="status"
                        style={{width: 130, marginTop: '5px', marginBottom: '5px'}}>
                        <Select onChange={this.statusChange}>
                            <Select.Option value="0">启用中</Select.Option>
                            <Select.Option value="1">已禁用</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="邀请人"
                        name="invitationUserName"
                        style={{width: 200, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入邀请人"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remarks"
                        style={{width: 416, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入备注"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        style={{marginTop: '5px', marginBottom: '5px'}}>
                        <Button type="primary" onClick={this.searchUser}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '5px'}}
                                onClick={this.resetSearch}>重置</Button>
                        {this.props.userPermission != null && this.props.userPermission.includes('300001') ?
                            (<Button type="primary" style={{'marginLeft': '5px'}}
                                     onClick={this.showAddUser}>添加</Button>) : null
                        }
                        {this.props.userPermission != null && this.props.userPermission.includes('300002') ?
                            (<Button type="primary" style={{'marginLeft': '5px'}}
                                     onClick={this.exportUserInfo}>导出</Button>) : null
                        }
                    </Form.Item>
                </Form>
                <div>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                新增
                            </div>
                        }
                        visible={this.state.addVisible}
                        onOk={this.addUser}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.addFormRef}>
                            <Form.Item
                                label="账户"
                                name="userName"
                                rules={[{
                                    validator: async (_, value, callback) => {
                                        if (Util.isTel(value)) {
                                            return Promise.resolve()
                                        } else {
                                            return Promise.reject('请输入正确的手机号码');
                                        }
                                    }
                                }]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="角色"
                                name="role"
                                initialValue='4'
                                rules={[{required: true, message: '请选择角色'}]}>
                                <Select>
                                    {roleSelect}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                查看
                            </div>
                        }
                        visible={this.state.viewVisible}
                        onOk={this.handleCancel}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.viewFormRef}>
                            <Form.Item
                                label="账户"
                                name="userName">
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="姓名"
                                name="realName">
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="角色"
                                name="role">
                                <Select disabled>
                                    {roleSelect}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="电话"
                                name="tel">
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="身份证"
                                name="idCard">
                                <Input disabled/>
                            </Form.Item>
                            {/*<Form.Item*/}
                            {/*    label="邮箱"*/}
                            {/*    name="email">*/}
                            {/*    <Input disabled/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item*/}
                            {/*    label="QQ"*/}
                            {/*    name="qq">*/}
                            {/*    <Input disabled/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item*/}
                            {/*    label="微信"*/}
                            {/*    name="weiXin">*/}
                            {/*    <Input disabled/>*/}
                            {/*</Form.Item>*/}
                            <Form.Item
                                label="收货地址"
                                name="address">
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="邀请人"
                                name="invitationUserName">
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="备注"
                                name="remarks">
                                <TextArea disabled rows={4}/>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                修改
                            </div>
                        }
                        visible={this.state.editVisible}
                        onOk={this.updateUser}
                        destroyOnClose={true}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.editFormRef}>
                            <Form.Item
                                label="账户"
                                name="userName">
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="姓名"
                                name="realName">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="角色"
                                name="role">
                                <Select disabled>
                                    {roleSelect}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="电话"
                                name="tel"
                                rules={[{
                                    validator: async (_, value, callback) => {
                                        // if (Util.isTel(value)) {
                                        //     return Promise.resolve()
                                        // } else {
                                        //     return Promise.reject('请输入正确的手机号码');
                                        // }
                                    }
                                }]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="身份证"
                                name="idCard"
                                rules={[{
                                    validator: async (_, value) => {
                                        if (Util.isEmpty(value)) {
                                            return true;
                                        } else {
                                            if (Util.isIdCard(value)) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject('请输入正确的身份证号');
                                            }
                                        }
                                    }
                                }]}>
                                <Input/>
                            </Form.Item>
                            {/*<Form.Item*/}
                            {/*    label="邮箱"*/}
                            {/*    name="email"*/}
                            {/*    rules={[{*/}
                            {/*        validator: async (_, value) => {*/}
                            {/*            if (Util.isEmpty(value)) {*/}
                            {/*                return true;*/}
                            {/*            } else {*/}
                            {/*                if (Util.isEmail(value)) {*/}
                            {/*                    return Promise.resolve()*/}
                            {/*                } else {*/}
                            {/*                    return Promise.reject('请输入正确的邮箱地址');*/}
                            {/*                }*/}
                            {/*            }*/}
                            {/*        }*/}
                            {/*    }]}>*/}
                            {/*    <Input/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item*/}
                            {/*    label="QQ"*/}
                            {/*    name="qq">*/}
                            {/*    <Input/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item*/}
                            {/*    label="微信"*/}
                            {/*    name="weiXin">*/}
                            {/*    <Input/>*/}
                            {/*</Form.Item>*/}
                            <Form.Item
                                label="收货地址"
                                name="address">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="收货地址"
                                name="address">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="是否发送短信"
                                valuePropName="checked"
                                name="isSend">
                                <Switch checkedChildren="是" unCheckedChildren="否"
                                        onChange={this.changeSend}/>
                            </Form.Item>
                            <Form.Item
                                label="备注"
                                name="remarks">
                                <TextArea rows={4}/>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
                <Table columns={columns}
                       size="small"
                       rowSelection={rowSelection}
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       scroll={{x: 2000}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <div style={{
                    marginTop: "-38px",
                    marginLeft: "8px",
                    display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                }}>
                    <Checkbox onChange={this.exportAllSelect}
                              checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                </div>
            </div>
        );
    }
}

export default UserInfo;