import React from 'react';
import {Button, Form, Input, message, Row, Table} from "antd";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";

let userName = null;

class CancelOrderDetailed extends React.Component {
    state = {
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        data: null,
        licensePlate: null,
        ownerName: null,
        siteName: null,
    }

    componentDidMount() {
        userName = JSON.parse(window.sessionStorage.getItem("userName"));
        this.initData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    /**
     * 初始化订单信息
     */
    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_COMPLETED_ORDER_INFO_URL,
            params: {
                orderBy: userName,
                licensePlate: this.state.licensePlate,
                ownerName: this.state.ownerName,
                siteName: this.state.siteName,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
                year: this.props.year,
                month: this.props.month,
                status: 2,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchOrder = () => {
        this.initData()
    }
    resetSearchOrder = () => {
        this.setState({
            licensePlate: null,
            ownerName: null,
            siteName: null,
        });
    }

    render() {
        const columns = [
            {
                title: '车牌',
                dataIndex: 'licensePlate',
                key: 'licensePlate',
                fixed: 'left',
                width: 100,
            },
            {
                title: '车主姓名',
                dataIndex: 'ownerName',
                key: 'ownerName',
                fixed: 'left',
                width: 100,
            },
            {
                title: '车型',
                dataIndex: 'carType',
                key: 'carType',
            },
            {
                title: '车辆类型',
                dataIndex: 'carModel',
                key: 'carModel',
            },
            {
                title: '车辆注册时间',
                dataIndex: 'registrationTime',
                key: 'registrationTime',
            },
            {
                title: '车主手机号',
                dataIndex: 'ownerTel',
                key: 'ownerTel',
            },
            {
                title: '预约站点',
                dataIndex: 'siteName',
                key: 'siteName',
                ellipsis: true,
            },
            {
                title: '预约时间',
                dataIndex: 'orderTime',
                key: 'orderTime',
            },
            {
                title: '取消订单时间',
                dataIndex: 'gmtCancel',
                key: 'gmtCancel',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            }
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Row>
                        <Form.Item
                            label="预约车牌"
                            name="licensePlate"
                            style={{width: 200}}>
                            <Input placeholder="请输入车牌"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="车主姓名"
                            name="ownerName"
                            style={{width: 200}}>
                            <Input placeholder="请输入姓名"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="预约站点"
                            name="siteName"
                            style={{width: 200}}>
                            <Input placeholder="请输入站点名称"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.searchOrder}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '17px'}}
                                    onClick={this.resetSearchOrder}>重置</Button>
                        </Form.Item>
                    </Row>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       scroll={{x: 2000}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
            </div>
        )
    }
}

export default CancelOrderDetailed;