import React from 'react'
import {Button, Form, Input, message, Modal, Row, Space, Table, DatePicker, Select} from "antd";
import style from "../Content.module.css";
import Axios from "../../../utils/axios";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import zh_CN from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import "../../../utils/date"
import Util from "../../../utils/utils";

moment.locale('zh-cn');
let userName = null;
const {TextArea} = Input;

class CarInfo extends React.Component {
    addFormRef = React.createRef();
    viewFormRef = React.createRef();
    editFormRef = React.createRef();
    state = {
        data: null,
        carModelList: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        licensePlate: '',
        carType: '',
        carModel: '',
        addVisible: false,
        viewVisible: false,
        editVisible: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.listCarModel();
            this.initData();
        }
    }

    searchCar = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    listCarModel = () => {
        Axios({
            method: 'get',
            url: '/carModel/listCarModelNoPage',
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        carModelList: res.data.data,
                    });
                } else {
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    initData = () => {
        Axios({
            method: 'get',
            url: '/car/listCar',
            params: {
                belongTo: userName,
                licensePlate: this.state.licensePlate,
                carType: this.state.carType,
                carModel: this.state.carModel,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    addCar = () => {
        this.addFormRef.current.validateFields().then(values => {
            values.belongTo = userName;
            values.registrationTime = new Date(values.registrationTime).format("yyyy-MM-dd");
            Axios({
                method: 'post',
                url: '/car/addCar',
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            addVisible: false,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.addFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    updateCar = () => {
        this.editFormRef.current.validateFields().then(values => {
            values.belongTo = userName;
            values.registrationTime = new Date(values.registrationTime).format("yyyy-MM-dd");
            Axios({
                method: 'post',
                url: '/car/updateCar',
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    showAddCar = () => {
        this.setState({
            addVisible: true,
        });
    }
    showCar = (record) => {
        this.setState({
            viewVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.viewFormRef.current.setFieldsValue(record);
        }, 100);
    }
    showEditCar = (record) => {
        this.setState({
            editVisible: true,
        });
        record.registrationTime = moment(record.registrationTime);
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
        }, 100);
    }
    showDeleteCar = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该车辆信息？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: '/car/deleteCar/' + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    handleCancel = () => {
        this.setState({
            viewVisible: false,
            editVisible: false,
            addVisible: false,
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            licensePlate: '',
            carType: '',
            carModel: '',
        });
    }

    render() {
        const layout = {
            labelCol: {span: 5},
            wrapperCol: {span: 19},
        };
        const columns = [
            {
                title: '车牌',
                dataIndex: 'licensePlate',
                key: 'licensePlate',
            },
            {
                title: '车型',
                dataIndex: 'carType',
                key: 'carType',
            },
            {
                title: '车辆类型',
                dataIndex: 'carModel',
                key: 'carModel',
            },
            {
                title: '车辆注册时间',
                dataIndex: 'registrationTime',
                key: 'registrationTime',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <Space size="small">
                        <a onClick={this.showCar.bind(this, record)}>查看</a>
                        <a onClick={this.showEditCar.bind(this, record)}>修改</a>
                        <a style={{'color': '#d43f3a'}} onClick={this.showDeleteCar.bind(this, record)}>删除</a>
                    </Space>
                ),
            },
        ];

        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Row>
                        <Form layout='inline'>
                            <Form.Item
                                label="车牌"
                                name="licensePlate"
                                style={{width: 200}}>
                                <Input placeholder="请输入车牌"
                                       onChange={this.inputChange}/>
                            </Form.Item>
                            <Form.Item
                                label="车型"
                                name="carType"
                                style={{width: 200}}>
                                <Input placeholder="请输入车型"
                                       onChange={this.inputChange}/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={this.searchCar}>查询</Button>
                                <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                        onClick={this.resetSearch}>重置</Button>
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.showAddCar}>添加</Button>
                            </Form.Item>
                        </Form>
                    </Row>
                    <div>
                        <Modal
                            title={
                                <div
                                    style={{
                                        width: '100%',
                                        cursor: 'move',
                                    }}>
                                    新增
                                </div>
                            }
                            visible={this.state.addVisible}
                            onOk={this.addCar}
                            onCancel={this.handleCancel}>
                            <Form {...layout}
                                  ref={this.addFormRef}>
                                <Form.Item
                                    label="车牌"
                                    name="licensePlate"
                                    rules={[{required: true, message: '请输入车牌'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="车型"
                                    name="carType"
                                    rules={[{required: true, message: '请输入车型'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="车辆类型"
                                    name="carModel"
                                    rules={[{required: true, message: '请选择车辆类型'}]}>
                                    <Select>
                                        {
                                            this.state.carModelList != null ?
                                                this.state.carModelList.map(item => {
                                                    return (<Select.Option key={item.modelName}
                                                                           value={item.modelName}>{item.modelName}</Select.Option>)
                                                }) : null
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="车辆注册时间"
                                    name="registrationTime"
                                    rules={[{required: true, message: '请选择车辆注册时间'}]}>
                                    <DatePicker locale={zh_CN}/>
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            title={
                                <div
                                    style={{
                                        width: '100%',
                                        cursor: 'move',
                                    }}>
                                    查看
                                </div>
                            }
                            visible={this.state.viewVisible}
                            onOk={this.handleCancel}
                            onCancel={this.handleCancel}>
                            <Form {...layout}
                                  ref={this.viewFormRef}>
                                <Form.Item
                                    label="车牌"
                                    name="licensePlate">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="车型"
                                    name="carType">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="车辆类型"
                                    name="carModel">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="车辆注册时间"
                                    name="registrationTime">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="备注"
                                    name="remarks">
                                    <TextArea disabled rows={4}/>
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            title={
                                <div
                                    style={{
                                        width: '100%',
                                        cursor: 'move',
                                    }}>
                                    修改
                                </div>
                            }
                            visible={this.state.editVisible}
                            onOk={this.updateCar}
                            onCancel={this.handleCancel}>
                            <Form {...layout}
                                  ref={this.editFormRef}>
                                <Form.Item
                                    label="车辆ID"
                                    name="id"
                                    hidden="true">
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="车牌"
                                    name="licensePlate"
                                    rules={[{required: true, message: '请输入车牌'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="车型"
                                    name="carType"
                                    rules={[{required: true, message: '请输入车型'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="车辆类型"
                                    name="carModel"
                                    rules={[{required: true, message: '请选择车辆类型'}]}>
                                    <Select>
                                        {
                                            this.state.carModelList != null ?
                                                this.state.carModelList.map(item => {
                                                    return (<Select.Option key={item.modelName}
                                                                           value={item.modelName}>{item.modelName}</Select.Option>)
                                                }) : null
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="车辆注册时间"
                                    name="registrationTime"
                                    rules={[{required: true, message: '请选择车辆注册时间'}]}>
                                    <DatePicker locale={zh_CN}/>
                                </Form.Item>
                                <Form.Item
                                    label="备注"
                                    name="remarks">
                                    <TextArea rows={4}/>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.data}
                           rowKey={columns => columns.id}
                           loading={this.state.loading}
                           style={{'marginTop': '20px'}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                </div>
            </div>
        );
    }
}

export default CarInfo;