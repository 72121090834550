import React from 'react'
import {Button, Form, Input, Row, message, Table, Checkbox} from "antd";
import Axios from "../../../utils/axios";

class OrderInfoByOperator extends React.Component {
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        userName: '',
        realName: '',
        selectedRowKeys: [],
        isExporting: false,
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (userRole !== 0 && userRole !== 1) {
            message.error("你没有权限访问!");
            this.props.history.push("/login");
        } else {
            this.initData();
        }
    }

    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    searchUserInfo = () => {
        this.initData();
    }
    resetSearch = () => {
        this.setState({
            userName: '',
            realName: '',
        });
    }
    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: '/user/listUserIdsByOrderByUser',
                params: {
                    source: this.props.source,
                    invitationUser: this.props.userName,
                    userName: this.state.userName,
                    realName: this.state.realName,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    initData = () => {
        Axios({
            method: 'get',
            url: '/user/listUserByOrderByUser',
            params: {
                source: this.props.source,
                invitationUser: this.props.userName,
                userName: this.state.userName,
                realName: this.state.realName,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchOrder = () => {
        this.initData();
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    exportUserInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: '/user/exportUserInfo/' + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '邀请用户数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll
        };
        const columns = [
            {
                title: '账户',
                dataIndex: 'userName',
                key: 'userName',
                fixed: 'left',
            },
            {
                title: '姓名',
                dataIndex: 'realName',
                key: 'realName',
                fixed: 'left',
            },
            {
                title: '电话',
                dataIndex: 'tel',
                key: 'tel',
            },
            {
                title: '地址',
                dataIndex: 'address',
                key: 'address',
                ellipsis: true,
            },
            {
                title: '身份证',
                dataIndex: 'idCard',
                key: 'idCard',
                ellipsis: true,
            },
            {
                title: '角色',
                dataIndex: 'role',
                key: 'role',
                render: role => {
                    switch (role) {
                        case 0:
                            return '管理员';
                        case 1:
                            return '线上电呼';
                        case 2:
                            return '业务团队';
                        case 3:
                            return '合作站点';
                        case 4:
                            return '车主';
                        case 5:
                            return '大客户';
                        case 6:
                            return '组员';
                        case 7:
                            return '服务网点';
                        case 8:
                            return '旅游团队';
                        case 9:
                            return '旅游团队成员';
                        default:
                            return '车主';
                    }
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: role => {
                    switch (role) {
                        case 0:
                            return '启用中';
                        case 1:
                            return '禁用中';
                        default:
                            return '启用中';
                    }
                }
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 170,
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            }
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Row>
                        <Form.Item
                            label="账户"
                            name="userName"
                            style={{width: 200}}>
                            <Input placeholder="请输入账户"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="姓名"
                            name="realName"
                            style={{width: 200}}>
                            <Input placeholder="请输入姓名"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.searchUserInfo}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '5px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {this.props.type === "OperatorInfo" ? (this.props.userPermission != null && this.props.userPermission.includes('400002') ? (
                                <Button type="primary" style={{'marginLeft': '5px'}}
                                        onClick={this.exportUserInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null) : null
                            }
                            {this.props.type === "TeamInfo" ? (this.props.userPermission != null && this.props.userPermission.includes('400004') ? (
                                <Button type="primary" style={{'marginLeft': '5px'}}
                                        onClick={this.exportUserInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null) : null
                            }
                            {this.props.type === "MajorAccountType" ? (this.props.userPermission != null && this.props.userPermission.includes('400008') ? (
                                <Button type="primary" style={{'marginLeft': '5px'}}
                                        onClick={this.exportUserInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null) : null
                            }
                            {this.props.type === "TravelTeamType" ? (this.props.userPermission != null && this.props.userPermission.includes('400011') ? (
                                <Button type="primary" style={{'marginLeft': '5px'}}
                                        onClick={this.exportUserInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null) : null
                            }
                        </Form.Item>
                    </Row>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       rowSelection={rowSelection}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       scroll={{x: 1500}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <div style={{
                    marginTop: "-38px",
                    marginLeft: "8px",
                    display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                }}>
                    < Checkbox onChange={this.exportAllSelect}
                               checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                </div>
            </div>
        )
    }
}

export default OrderInfoByOperator;