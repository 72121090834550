import React from 'react';
import {Button, Form, Image, Input, InputNumber, message, Modal, Space, Table, Upload} from "antd";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import {ExclamationCircleOutlined} from "@ant-design/icons";

export default class TravelBannerImage extends React.Component {
    addFormRef = React.createRef();
    state = {
        data: null,
        loading: true,
        addVisible: false,
        current: 1,
        pageSize: 10,
        total: 0,
        image: null,
        fileList: [],
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.TRAVEL.LIST_TRAVEL_BANNER_IMAGE_URL,
            params: {
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    addTravelBannerImage = () => {
        this.addFormRef.current.validateFields().then(values => {
            if (values.image === 'undefined') {
                message.error("请上传首页图片！");
                return;
            } else {
                let formData = new FormData();
                formData.append('travelBannerImageDTO', new Blob([JSON.stringify(values)], {type: "application/json"}));
                formData.append('image', this.state.image);
                Axios({
                    method: 'post',
                    url: PATH_URL.TRAVEL.ADD_TRAVEL_BANNER_IMAGE_URL,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.setState({
                                loading: true,
                                addVisible: false,
                                image: null,
                                fileList: [],
                            });
                            this.initData();
                            this.addFormRef.current.resetFields();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        }).catch(info => {
            console.log(info);
        });
    }
    openAddTravelBannerModal = () => {
        this.setState({
            addVisible: true,
        })
    }
    deleteTravelBanner = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该图片？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.TRAVEL.DELETE_TRAVEL_BANNER_IMAGE_URL + "/" + record.id,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    handleCancel = () => {
        this.setState({
            addVisible: false,
        });
    }
    onRemove = (file) => {
        this.setState({
            fileList: []
        })
    }
    fileChange = (e) => {
        this.setState({
            image: (e.fileList.length > 0 && (e.file.size / (1024 * 1024) < 10)) ? e.fileList[0].originFileObj : null,
        })
    }
    beforeUpload = (file, fileList) => {
        if (file.size / (1024 * 1024) >= 10) {
            message.error("文件大小不能超过10M！", 2);
            this.setState({
                fileList: [],
                image: null
            })
        } else {
            this.setState({
                fileList: fileList,
                image: file
            })
        }
        return false;
    }

    render() {
        const columns = [
            {
                title: '名称',
                dataIndex: 'imageName',
                key: 'imageName',
                fixed: 'left',
                width: 150,
                ellipsis: true,
            },
            {
                title: '图片',
                dataIndex: 'image',
                key: 'image',
                render: image => {
                    if (!Util.isEmpty(image)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={image}/>
                        )
                    }
                }
            },
            {
                title: '排序',
                dataIndex: 'imageOrder',
                key: 'imageOrder',
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                width: 160,
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        {this.props.userPermission != null && this.props.userPermission.includes('300129') ? (
                            <a onClick={this.deleteTravelBanner.bind(this, record)}
                               style={{'color': '#d43f3a'}}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        return (
            <div>
                {this.props.userPermission != null && this.props.userPermission.includes('300128') ? (
                    <Button type="primary"
                            onClick={this.openAddTravelBannerModal}>新增</Button>) : null
                }
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       scroll={{x: 1000}}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            新增
                        </div>
                    }
                    width={800}
                    visible={this.state.addVisible}
                    onOk={this.addTravelBannerImage}
                    onCancel={this.handleCancel}>
                    <Form
                        ref={this.addFormRef}>
                        <Form.Item
                            label="图片名称"
                            name="imageName"
                            rules={[{required: true, message: '请输入图片名称'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="图片排序"
                            name="imageOrder"
                            rules={[{required: true, message: '请输入排序'}]}>
                            <InputNumber min={1} max={10} style={{'width': '169px'}}
                                         placeholder="数字越低，排序越前"/>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="首页图片">
                            <Upload
                                name="image"
                                accept=".png,.jpg,.jpeg"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.fileChange}
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}>
                                <Button type="primary">
                                    选择文件
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}