import React from 'react'
import {Button, Form, Input, DatePicker, Table, Checkbox, Space, message, Select, Modal} from "antd";
import zh_CN from "antd/es/date-picker/locale/zh_CN";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const {RangePicker} = DatePicker;

let userRole;

class ExchangeOrderInfo extends React.Component {
    writeOffFormRef = React.createRef();
    state = {
        data: null,
        serviceSiteName: null,
        rollName: null,
        exchangeUserName: null,
        status: null,
        exchangeStartTime: null,
        exchangeEndTime: null,
        writeOffStartTime: null,
        writeOffEndTime: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        selectedRowKeys: [],
        isExporting: false,
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
        writeOffVisible: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (userRole === 7) {
            this.getServiceSite();
        } else {
            if (!Util.isEmpty(userName)) {
                this.initData();
            }
        }
    }

    getServiceSite = () => {
        Axios({
            method: 'get',
            url: PATH_URL.GET_SERVICE_SITE_BY_USER_NAME_URL + "/" + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    console.log(res.data.data)
                    if (res.data.data === null) {
                        message.error("请先绑定网点地址！")
                        return
                    } else {
                        this.setState({
                            serviceSiteName: res.data.data.name
                        })
                        this.initData();
                    }
                } else {
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_EXCHANGE_ORDER_URL,
            params: {
                serviceSiteName: this.state.serviceSiteName,
                rollName: this.state.rollName,
                exchangeUserName: this.state.exchangeUserName,
                status: this.state.status,
                exchangeStartTime: this.state.exchangeStartTime,
                exchangeEndTime: this.state.exchangeEndTime,
                writeOffStartTime: this.state.writeOffStartTime,
                writeOffEndTime: this.state.writeOffEndTime,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchExchangeOrder = () => {
        this.setState({
            loading: true
        })
        if (userRole === 7) {
            this.getServiceSite();
        } else {
            this.initData();
        }
    }
    resetSearch = () => {
        this.setState({
            serviceSiteName: null,
            rollName: null,
            exchangeUserName: null,
            exchangeStartTime: null,
            exchangeEndTime: null,
            writeOffStartTime: null,
            writeOffEndTime: null,
            status: null,
        })
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    exportExchangeOrderInfo = (e) => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: PATH_URL.EXPORT_EXCHANGE_ORDER_INFO_URL + "/" + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '兑换订单数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }
    showDeleteExchangeInfo = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该兑换订单信息？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.DELETE_EXCHANGE_ORDER_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleExchangeSelectTime = (value, dateString) => {
        this.setState({
            exchangeStartTime: dateString[0],
            exchangeEndTime: dateString[1],
        })
    }
    handleWriteOffSelectTime = (value, dateString) => {
        this.setState({
            writeOffStartTime: dateString[0],
            writeOffEndTime: dateString[1],
        })
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: PATH_URL.LIST_EXCHANGE_ORDER_IDS_URL,
                params: {
                    serviceSiteName: this.state.serviceSiteName,
                    rollName: this.state.rollName,
                    exchangeUserName: this.state.exchangeUserName,
                    status: this.state.status,
                    exchangeStartTime: this.state.exchangeStartTime,
                    exchangeEndTime: this.state.exchangeEndTime,
                    writeOffStartTime: this.state.writeOffStartTime,
                    writeOffEndTime: this.state.writeOffEndTime,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    showWriteOffOrder = (record) => {
        this.setState({
            writeOffVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.writeOffFormRef.current.setFieldsValue(record);
        }, 100);
    }
    writeOffOrder = () => {
        this.writeOffFormRef.current.validateFields().then(values => {
            Axios({
                method: 'post',
                url: PATH_URL.WRITE_OFF_EXCHANGE_ORDER_URL,
                data: JSON.stringify({
                    id: values.id,
                    writeOffCode: values.writeOffCode,
                })
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            writeOffVisible: false,
                        })
                        message.success(res.data.message);
                        this.initData();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    handleCancel = () => {
        this.setState({
            writeOffVisible: false,
        });
        if (this.writeOffFormRef.current != null) {
            this.writeOffFormRef.current.resetFields();
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll
        };
        const columns = [
            {
                title: '网点名称',
                dataIndex: 'serviceSiteName',
                key: 'serviceSiteName',
                fixed: 'left',
                width: 150,
                ellipsis: true,
            },
            {
                title: '网点电话',
                dataIndex: 'serviceSiteTel',
                key: 'serviceSiteTel',
                fixed: 'left',
                width: 120,
            },
            {
                title: '网点地址',
                dataIndex: 'serviceSiteAddress',
                key: 'serviceSiteAddress',
                width: 150,
                ellipsis: true,
            },
            {
                title: '兑换卷名称',
                dataIndex: 'rollName',
                key: 'rollName',
                width: 150,
                ellipsis: true,
            },
            {
                title: '兑换卷编号',
                dataIndex: 'rollNumber',
                key: 'rollNumber',
                width: 300,
            },
            {
                title: '兑换账户',
                dataIndex: 'exchangeUserName',
                key: 'exchangeUserName',
                width: 120,
                ellipsis: true,
            },
            {
                title: '兑换人',
                dataIndex: 'exchangeUserRealName',
                key: 'exchangeUserRealName',
                width: 120,
                ellipsis: true,
            },
            {
                title: '兑换人电话',
                dataIndex: 'exchangeUserTel',
                key: 'exchangeUserTel',
                width: 120,
                ellipsis: true,
            },
            {
                title: '兑换时间',
                dataIndex: 'exchangeTime',
                key: 'exchangeTime',
                width: 165,
            },
            {
                title: '兑换状态',
                dataIndex: 'status',
                key: 'status',
                width: 80,
                render: status => {
                    switch (status) {
                        case 0:
                            return '待核销';
                        case 1:
                            return '已核销';
                        case 2:
                            return '已取消';
                        default:
                            return '待核销';
                    }
                }
            },
            {
                title: '核销时间',
                dataIndex: 'writeOffTime',
                key: 'writeOffTime',
                width: 165,
            },
            {
                title: '操作',
                key: 'action',
                width: 50,
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        {
                            userRole === 0 && this.props.userPermission != null && this.props.userPermission.includes('300094') ? (
                                <a style={{'color': '#d43f3a'}}
                                   onClick={this.showDeleteExchangeInfo.bind(this, record)}>删除</a>) : null
                        }
                        {
                            record.status === 0 && userRole !== 0 ? (
                                <a onClick={this.showWriteOffOrder.bind(this, record)}>核销</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        return (
            <div>
                <Form layout='inline'>
                    {
                        userRole === 7 ? null : (
                            <Form.Item
                                label="网点名称"
                                name="serviceSiteName"
                                style={{width: 270, marginTop: '5px', marginBottom: '5px'}}>
                                <Input placeholder="请输入网点名称"
                                       onChange={this.inputChange}/>
                            </Form.Item>
                        )
                    }
                    <Form.Item
                        label="兑换卷名称"
                        name="rollName"
                        style={{width: 270, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入兑换卷名称"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="兑换账户"
                        name="exchangeUserName"
                        style={{width: 270, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入兑换账户"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="订单状态"
                        name="status"
                        style={{width: 150, marginTop: '5px', marginBottom: '5px'}}>
                        <Select onChange={this.statusChange}>
                            <Select.Option value="0">待核销</Select.Option>
                            <Select.Option value="1">已核销</Select.Option>
                            <Select.Option value="2">已取消</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="兑换时间"
                        name="exchangeTime"
                        style={{marginTop: '5px', marginBottom: '5px'}}>
                        <RangePicker locale={zh_CN} showTime onChange={this.handleExchangeSelectTime}/>
                    </Form.Item>
                    <Form.Item
                        label="核销时间"
                        name="writeOffTime"
                        style={{marginTop: '5px', marginBottom: '5px'}}>
                        <RangePicker locale={zh_CN} showTime onChange={this.handleWriteOffSelectTime}/>
                    </Form.Item>
                    <Form.Item
                        style={{marginTop: '5px', marginBottom: '5px'}}>
                        <Button type="primary" onClick={this.searchExchangeOrder}
                                style={{'marginLeft': '10px'}}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                        {
                            userRole === 0 && this.props.userPermission != null && this.props.userPermission.includes('300093') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.exportExchangeOrderInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null
                        }
                        {
                            userRole !== 0 && userRole === 7 ? (<Button type="primary" style={{'marginLeft': '10px'}}
                                                                        onClick={this.exportExchangeOrderInfo}
                                                                        disabled={this.state.isExporting}>导出</Button>) : null
                        }

                    </Form.Item>
                </Form>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            核销
                        </div>
                    }
                    visible={this.state.writeOffVisible}
                    onOk={this.writeOffOrder}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.writeOffFormRef}>
                        <Form.Item
                            label="订单id"
                            name="id"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="核销码"
                            name="writeOffCode"
                            rules={[{required: true, message: '请输入核销码'}]}>
                            <Input/>
                        </Form.Item>
                    </Form>
                </Modal>
                <Table columns={columns}
                       size="small"
                       scroll={{x: 1700}}
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       rowSelection={rowSelection}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <div style={{
                    marginTop: "-38px",
                    marginLeft: "8px",
                    display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                }}>
                    < Checkbox onChange={this.exportAllSelect}
                               checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                </div>
            </div>
        )
    }
}

export default ExchangeOrderInfo;