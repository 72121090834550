import React from 'react'
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import {message, Tabs} from "antd";
import style from "../Content.module.css";
import ExchangeRollManage from "./ExchangeRollManage";
import ExchangeRecord from "./ExchangeRecord";
import TransferRecord from "./TransferRecord";
import RollCirculationRecord from "./RollCirculationRecord";
import UserRollInfo from "./UserRollInfo";

const {TabPane} = Tabs;

class ExchangeManageTab extends React.Component {
    state = {
        activeKey: 1,
        userPermission: null,
    }

    componentDidMount() {
        this.getUserPermission();
    }

    onChange = (key) => {
        this.setState({
            activeKey: key
        })
    }
    getUserPermission = () => {
        Axios({
            method: 'get',
            url: PATH_URL.GET_USER_MENU_URL + "/" + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Tabs defaultActiveKey="1" onChange={this.onChange}>
                        {this.state.userPermission != null && this.state.userPermission.includes('200016') ? (
                            <TabPane tab="兑换卷管理" key="1">
                                <ExchangeRollManage userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200017') ? (
                            <TabPane tab="兑换记录" key="2">
                                <ExchangeRecord userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200018') ? (
                            <TabPane tab="转赠记录" key="3">
                                <TransferRecord userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200019') ? (
                            <TabPane tab="卷流转记录" key="4">
                                <RollCirculationRecord userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200020') ? (
                            <TabPane tab="用户卷信息" key="5">
                                <UserRollInfo userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default ExchangeManageTab;