import React from 'react';
import {Button, Form, Input, message} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import style from './Login.module.css';
import Axios from "../../utils/axios";

class Login extends React.Component {
    state = {
        username: '',
        password: ''
    }
    onSubmit = () => {
        Axios({
            method: 'post',
            url: '/login',
            data: {
                username: this.state.username,
                password: this.state.password,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    window.sessionStorage.setItem("token", res.data.data.token);
                    window.sessionStorage.setItem("userName", res.data.data.userName);
                    window.sessionStorage.setItem("userRole", res.data.data.userRole);
                    window.sessionStorage.setItem("isSend", res.data.data.isSend);
                    switch (res.data.data.userRole) {
                        case 0:
                            this.props.history.push("/home")
                            break;
                        case 1:
                            this.props.history.push("/order")
                            break;
                        case 2:
                            this.props.history.push("/order")
                            break;
                        case 3:
                            this.props.history.push("/order")
                            break;
                        case 4:
                            message.error("您没有权限登录!");
                            this.props.history.push("/login")
                            break;
                        case 5:
                            this.props.history.push("/order")
                            break;
                        case 6:
                            this.props.history.push("/order")
                            break;
                        case 7:
                            this.props.history.push("/exchangeOrderManage")
                            break;
                        case 8:
                            this.props.history.push("/tourismManage")
                            break;
                        case 9:
                            this.props.history.push("/tourismManage")
                            break;
                        default:
                            message.error("您没有权限登录!");
                            this.props.history.push("/login")
                            break;
                    }
                } else {
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        return (
            <div>
                <div className={style.header}></div>
                <div className={style.content}>
                    <Form onFinish={this.onSubmit}>
                        <Form.Item
                            rules={[
                                {required: true, message: '请输入账户!'},
                            ]}
                            name="usernameItem"
                        >
                            <Input
                                size="large"
                                prefix={<UserOutlined className="site-form-item-icon"/>}
                                placeholder="Username"
                                name="username"
                                onChange={this.handleChange}
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {required: true, message: '请输入密码!'},
                            ]}
                            name="passwordItem"
                        >
                            <Input.Password
                                size="large"
                                type="password"
                                name="password"
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                placeholder="Password"
                                onChange={this.handleChange}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                className={style['login-button']}
                            >
                                登陆
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}

export default Login;
