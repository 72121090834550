import React from 'react'
import style from "../Content.module.css";
import {Table, Space, Input, Form, Button, message, Modal, Select, Upload, Image, Checkbox} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons'
import Axios from "../../../utils/axios";
import Util from "../../../utils/utils";

const {TextArea} = Input;

let map = null
class SiteInfo extends React.Component {
    addFormRef = React.createRef();
    viewFormRef = React.createRef();
    editFormRef = React.createRef();
    state = {
        data: null,
        siteName: '',
        status: '',
        recommend: '',
        remarks: '',
        loading: true,
        visible: false,
        viewVisible: false,
        editVisible: false,
        current: 1,
        pageSize: 10,
        total: 0,
        businessUser: null,
        iconFile: null,
        fileList: [],
        addAddress: null,
        editAddress: null,
        selectedRowKeys: [],
        isExporting: false,
        userPermission: null,
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.getUserPermission();
        }
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: '/menu/getUserMenu/' + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: '/site/listSiteIds',
                params: {
                    siteName: this.state.siteName,
                    status: this.state.status,
                    recommend: this.state.recommend,
                    remarks: this.state.remarks,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }

    initData() {
        Axios({
            method: 'get',
            url: '/site/listSite',
            params: {
                siteName: this.state.siteName,
                status: this.state.status,
                recommend: this.state.recommend,
                remarks: this.state.remarks,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }

    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    searchSite = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    openAddSiteModal = () => {
        this.setState({
            visible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.listBusinessUser(null);
            this.initMap('container', null, null, 'addAddress');
        }, 100);
    }
    listBusinessUser = (userName) => {
        Axios({
            method: 'get',
            url: '/user/listBusinessUser',
            params: {
                distribution: false,
                userName: userName,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        businessUser: res.data.data,
                    });
                } else {
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    initMap = (container, longitude, latitude, searchText) => {
        let _this = this;
        map = new window.AMap.Map(container, {
            zoom: 11,//级别
            viewMode: '3D',//使用3D视图
        });
        //输入提示
        let autoOptions = {
            input: searchText
        };
		
        window.AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], function () {
            let auto = new window.AMap.AutoComplete(autoOptions);
            let placeSearch = new window.AMap.PlaceSearch({
                map: map
            });  //构造地点查询类
            auto.on("select", select);//注册监听，当选中某条记录时会触发
            function select(e) {
                placeSearch.setCity(e.poi.adcode);
                placeSearch.search(e.poi.name);  //关键字查询查询
                if (searchText === "addAddress") {
                    _this.setState({
                        addAddress: e.poi.name,
                    });
                } else if (searchText === "editAddress") {
                    _this.setState({
                        editAddress: e.poi.name,
                    });
                }
            }
        });
        if (longitude != null && latitude != null) {
            map.setCenter([longitude, latitude]);
        }

    }
    resetSearch = () => {
        this.setState({
            siteName: '',
            status: '',
            remarks: '',
            recommend: '',
        });
    }
    addSite = () => {
        let _this = this;
        window.AMap.plugin(['AMap.Autocomplete'], function () {
            // 实例化Autocomplete
            let autoOptions = {
                city: '全国',
                input: "addAddress"
            }
            let autoComplete = new window.AMap.Autocomplete(autoOptions);
            if (!Util.isEmpty(_this.state.addAddress)) {
                autoComplete.search(_this.state.addAddress, function (status, result) {
                    if (status === 'complete' && result.tips.length > 0) {
                        _this.addFormRef.current.setFieldsValue({
                            'longitude': result.tips[0].location.lng,
                            'latitude': result.tips[0].location.lat,
                        })
                        _this.addFormRef.current.validateFields()
                            .then(values => {
                                values.address = _this.state.addAddress;
                                let formData = new FormData();
                                formData.append('site', new Blob([JSON.stringify(values)], {type: "application/json"}));
                                formData.append('iconFile', _this.state.iconFile);
                                Axios({
                                    method: 'post',
                                    url: '/site/addSite',
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                    data: formData,
                                }).then(res => {
                                    if (res.status === 200) {
                                        if (res.data.code === 200) {
                                            _this.setState({
                                                loading: true,
                                                visible: false,
                                                iconFile: null,
                                                fileList: [],
                                                addAddress: null,
                                            });
                                            message.success(res.data.message);
                                            _this.initData();
                                            _this.addFormRef.current.resetFields();
                                        } else {
                                            message.error(res.data.message);
                                        }
                                    } else if (res.code === 401) {
                                        message.error(res.message);
                                    }
                                }).catch(err => {
                                    console.log(err);
                                });
                            })
                            .catch(info => {
                                console.log(info);
                            });
                    }
                });
            } else {
                message.error("请输入站点的地址");
            }
        });
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    recommendChange = (value) => {
        this.setState({
            recommend: value,
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            viewVisible: false,
            editVisible: false,
        });
        if (this.addFormRef.current != null) {
            this.addFormRef.current.resetFields();
        }
        if (this.editFormRef.current != null) {
            this.editFormRef.current.resetFields();
        }
        if (this.viewFormRef.current != null) {
            this.viewFormRef.current.resetFields();
        }
		map.destroy()
		
    }
    showSite = (record) => {
        this.setState({
            viewVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.viewFormRef.current.setFieldsValue(record);
        }, 100);
    }
    showEditSite = (record) => {
        this.listBusinessUser(record.userName);
        this.setState({
            editVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
            this.editFormRef.current.setFieldsValue({
                'editAddress': record.address,
            });
            this.setState({
                editAddress: record.address,
            })
            this.initMap('editContainer', record.longitude, record.latitude, 'editAddress');
        }, 100);
    }
    updateSite = () => {
        let _this = this;
        window.AMap.plugin(['AMap.Autocomplete'], function () {
            // 实例化Autocomplete
            let autoOptions = {
                city: '全国',
                input: "editAddress"
            }
            let autoComplete = new window.AMap.Autocomplete(autoOptions);
            if (!Util.isEmpty(_this.state.editAddress)) {
                autoComplete.search(_this.state.editAddress, function (status, result) {
                    if (status === 'complete' && result.tips.length > 0) {
                        _this.editFormRef.current.setFieldsValue({
                            'longitude': result.tips[0].location.lng,
                            'latitude': result.tips[0].location.lat,
                        });
                        _this.editFormRef.current.validateFields()
                            .then(values => {
                                values.address = _this.state.editAddress;
                                let formData = new FormData();
                                formData.append('site', new Blob([JSON.stringify(values)], {type: "application/json"}));
                                formData.append('iconFile', _this.state.iconFile);
                                Axios({
                                    method: 'post',
                                    url: '/site/updateSite',
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                    data: formData,
                                }).then(res => {
                                    if (res.status === 200) {
                                        if (res.data.code === 200) {
                                            _this.setState({
                                                loading: true,
                                                editVisible: false,
                                                iconFile: null,
                                                fileList: [],
                                                editAddress: null,
                                            });
                                            message.success(res.data.message);
                                            _this.initData();
                                            _this.editFormRef.current.resetFields();
                                        } else {
                                            message.error(res.data.message);
                                        }
                                    } else if (res.code === 401) {
                                        message.error(res.message);
                                    }
                                }).catch(err => {
                                    console.log(err);
                                });
                            })
                            .catch(info => {
                                console.log(info);
                            });
                    } else {
                        message.error("请输入正确的地址");
                    }
                });
            } else {
                message.error("请输入站点的地址");
            }
        });
    }
    showChangeSiteStatus = (record, status) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认" + (status === 0 ? "启用" : "禁用") + "该站点？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: '/site/changeSiteStatus/' + record.id,
                    params: {
                        status: status,
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    showChangeSiteRecommend = (record, recommend) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认" + (recommend === 0 ? "取消推荐" : "推荐") + "该站点？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: '/site/changeSiteRecommend/' + record.id,
                    params: {
                        recommend: recommend,
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    showDeleteSite = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该站点？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: '/site/deleteSite/' + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    fileChange = (e) => {
        this.setState({
            iconFile: (e.fileList.length > 0 && (e.file.size / (1024 * 1024) < 10)) ? e.fileList[0].originFileObj : null,
        })
    }
    beforeUpload = (file, fileList) => {
        if (file.size / (1024 * 1024) >= 10) {
            message.error("文件大小不能超过10M！", 2);
            this.setState({
                fileList: [],
                iconFile: null
            })
        } else {
            this.setState({
                fileList: fileList,
                iconFile: file
            })
        }
        return false;
    }
    onRemove = (file) => {
        this.setState({
            fileList: []
        })
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    exportSiteInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: '/site/exportSiteInfo/' + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '站点数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll
        };
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const columns = [
            {
                title: '站点',
                dataIndex: 'siteName',
                key: 'siteName',
                fixed: 'left',
                width: 150,
                ellipsis: true,
            },
            {
                title: '站点电话',
                dataIndex: 'siteTel',
                key: 'siteTel',
                fixed: 'left',
                width: 120,
            },
            {
                title: '站点地址',
                dataIndex: 'address',
                key: 'address',
                fixed: 'left',
                width: 150,
                ellipsis: true,
            },
            {
                title: '所属商家',
                dataIndex: 'businessName',
                key: 'businessName',
                fixed: 'left',
                width: 120,
                ellipsis: true,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    switch (status) {
                        case 0:
                            return '启用中';
                        case 1:
                            return '禁用中';
                        default:
                            return '启用中';
                    }
                }
            },
            {
                title: '推荐站点',
                dataIndex: 'recommend',
                key: 'recommend',
                render: recommend => {
                    switch (recommend) {
                        case 0:
                            return '否';
                        case 1:
                            return '是';
                        default:
                            return '否';
                    }
                }
            },
            {
                title: '图片',
                dataIndex: 'icon',
                key: 'icon',
                render: icon => {
                    if (!Util.isEmpty(icon)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={icon}/>
                        )
                    }
                }
            },
            {
                title: '站点介绍',
                dataIndex: 'introduce',
                key: 'introduce',
                ellipsis: true,
            },
            {
                title: '车主可获积分',
                dataIndex: 'userIntegral',
                key: 'userIntegral',
            },
            {
                title: '业务团队可获积分',
                dataIndex: 'teamIntegral',
                key: 'teamIntegral',
            },
            {
                title: '线上电呼可获积分',
                dataIndex: 'operatorIntegral',
                key: 'operatorIntegral',
            },
            {
                title: '经度',
                dataIndex: 'longitude',
                key: 'longitude',
            },
            {
                title: '纬度',
                dataIndex: 'latitude',
                key: 'latitude',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                width: 220,
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        {this.state.userPermission != null && this.state.userPermission.includes('300021') ? (
                            <a onClick={this.showSite.bind(this, record)}>查看</a>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('300022') ? (
                            <a onClick={this.showEditSite.bind(this, record)}>修改</a>) : null
                        }
                        {record.status === 0 ? (this.state.userPermission != null && this.state.userPermission.includes('300023') ? (
                            <a style={{'color': '#f5ce0a'}}
                               onClick={this.showChangeSiteStatus.bind(this, record, 1)}>禁用</a>) : null) : (this.state.userPermission != null && this.state.userPermission.includes('300024') ? (
                            <a style={{'color': '#f5ce0a'}}
                               onClick={this.showChangeSiteStatus.bind(this, record, 0)}>启用</a>) : null)}
                        {this.state.userPermission != null && this.state.userPermission.includes('300025') ? (
                            <a style={{'color': '#d43f3a'}}
                               onClick={this.showDeleteSite.bind(this, record)}>删除</a>) : null
                        }
                        {
                            record.recommend === 0 ? (this.state.userPermission != null && this.state.userPermission.includes('300106') ? (
                                <a style={{'color': '#f5ce0a'}}
                                   onClick={this.showChangeSiteRecommend.bind(this, record, 1)}>设为推荐</a>) : null) : (this.state.userPermission != null && this.state.userPermission.includes('300107') ?
                                (<a style={{'color': '#f5ce0a'}}
                                    onClick={this.showChangeSiteRecommend.bind(this, record, 0)}>取消推荐</a>) : null)
                        }

                    </Space>
                ),
            },
        ];

        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Form layout='inline'>
                        <Form.Item
                            label="站点名称"
                            name="siteName"
                            style={{width: 270}}>
                            <Input placeholder="请输入站点名称"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="status"
                            style={{width: 150}}>
                            <Select onChange={this.statusChange}>
                                <Select.Option value="0">启用中</Select.Option>
                                <Select.Option value="1">禁用中</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="推荐站点"
                            name="recommend"
                            style={{width: 150}}>
                            <Select onChange={this.recommendChange}>
                                <Select.Option value="0">否</Select.Option>
                                <Select.Option value="1">是</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="备注"
                            name="remarks"
                            style={{width: 270}}>
                            <Input placeholder="请输入备注"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.searchSite}
                                    style={{'marginLeft': '10px'}}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {this.state.userPermission != null && this.state.userPermission.includes('300019') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.openAddSiteModal}>新增</Button>) : null
                            }
                            {this.state.userPermission != null && this.state.userPermission.includes('300020') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.exportSiteInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                    <div>
                        <Modal
                            title={
                                <div
                                    style={{
                                        width: '100%',
                                        cursor: 'move',
                                    }}>
                                    新增
                                </div>
                            }
                            visible={this.state.visible}
                            onOk={this.addSite}
                            onCancel={this.handleCancel}>
                            <Form {...layout}
                                  ref={this.addFormRef}>
                                <Form.Item
                                    label="站点名称"
                                    name="siteName"
                                    rules={[{required: true, message: '请输入站点名称'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="所属商家"
                                    name="userName"
                                    rules={[{required: true, message: '请选择所属商家'}]}>
                                    <Select>
                                        {
                                            this.state.businessUser != null ?
                                                this.state.businessUser.map(item => {
                                                    return (<Select.Option key={item.userName}
                                                                           value={item.userName}>{item.realName}</Select.Option>)
                                                }) : null
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="车主可获积分"
                                    name="userIntegral"
                                    rules={[{
                                        required: true,
                                        validator: async (_, value, callback) => {
                                            if (Util.isNumber(value)) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject('请输入正确的站点积分');
                                            }
                                        }
                                    }]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="业务团队可获积分"
                                    name="teamIntegral"
                                    rules={[{
                                        required: true,
                                        validator: async (_, value, callback) => {
                                            if (Util.isNumber(value)) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject('请输入正确的站点积分');
                                            }
                                        }
                                    }]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="线上电呼叫可获积分"
                                    name="operatorIntegral"
                                    rules={[{
                                        required: true,
                                        validator: async (_, value, callback) => {
                                            if (Util.isNumber(value)) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject('请输入正确的站点积分');
                                            }
                                        }
                                    }]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="站点地址"
                                    name="addAddress"
                                    rules={[{required: true, message: '请输入地址'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="经度"
                                    name="longitude"
                                    hidden="true">
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="纬度"
                                    name="latitude"
                                    hidden="true">
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="站点图示"
                                    name="container">
                                    <div>
                                        <Input style={{'width': '374px', 'height': '200px'}}/>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="站点介绍"
                                    name="introduce">
                                    <TextArea rows={4}/>
                                </Form.Item>
                                <Form.Item
                                    label="站点备注"
                                    name="remarks">
                                    <TextArea rows={4}/>
                                </Form.Item>
                                <Form.Item
                                    label="站点图片">
                                    <Upload
                                        name="file"
                                        accept=".png,.jpg,.jpeg"
                                        maxCount={1}
                                        fileList={this.state.fileList}
                                        onChange={this.fileChange}
                                        onRemove={this.onRemove}
                                        beforeUpload={this.beforeUpload}>
                                        <Button type="primary">
                                            选择文件
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            title={
                                <div
                                    style={{
                                        width: '100%',
                                        cursor: 'move',
                                    }}>
                                    查看
                                </div>
                            }
                            visible={this.state.viewVisible}
                            onOk={this.handleCancel}
                            onCancel={this.handleCancel}>
                            <Form {...layout}
                                  ref={this.viewFormRef}>
                                <Form.Item
                                    label="站点名称"
                                    name="siteName">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="站点电话"
                                    name="siteTel">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="所属商家"
                                    name="businessName">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="车主可获积分"
                                    name="userIntegral">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="业务团队可获积分"
                                    name="teamIntegral">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="线上电呼可获积分"
                                    name="operatorIntegral">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="站点地址"
                                    name="address">
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="站点介绍"
                                    name="introduce">
                                    <TextArea disabled rows={4}/>
                                </Form.Item>
                                <Form.Item
                                    label="站点备注"
                                    name="remarks">
                                    <TextArea disabled rows={4}/>
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            title={
                                <div
                                    style={{
                                        width: '100%',
                                        cursor: 'move',
                                    }}>
                                    修改
                                </div>
                            }
                            visible={this.state.editVisible}
                            onOk={this.updateSite}
                            onCancel={this.handleCancel}>
                            <Form {...layout}
                                  ref={this.editFormRef}>
                                <Form.Item
                                    label="站点ID"
                                    name="id"
                                    hidden="true">
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="站点名称"
                                    name="siteName"
                                    rules={[{required: true, message: '请输入站点名称'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="所属商家"
                                    name="userName"
                                    rules={[{required: true, message: '请选择所属商家'}]}>
                                    <Select>
                                        {
                                            this.state.businessUser != null ?
                                                this.state.businessUser.map(item => {
                                                    return (<Select.Option key={item.userName}
                                                                           value={item.userName}>{item.realName}</Select.Option>)
                                                }) : null
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="车主可获积分"
                                    name="userIntegral"
                                    rules={[{
                                        required: true,
                                        validator: async (_, value, callback) => {
                                            if (Util.isNumber(value)) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject('请输入正确的站点积分');
                                            }
                                        }
                                    }]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="业务团队可获积分"
                                    name="teamIntegral"
                                    rules={[{
                                        required: true,
                                        validator: async (_, value, callback) => {
                                            if (Util.isNumber(value)) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject('请输入正确的站点积分');
                                            }
                                        }
                                    }]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="线上电呼可获积分"
                                    name="operatorIntegral"
                                    rules={[{
                                        required: true,
                                        validator: async (_, value, callback) => {
                                            if (Util.isNumber(value)) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject('请输入正确的站点积分');
                                            }
                                        }
                                    }]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="站点地址"
                                    name="editAddress"
                                    rules={[{required: true, message: '请输入地址'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="经度"
                                    name="longitude"
                                    hidden="true">
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="纬度"
                                    name="latitude"
                                    hidden="true">
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="站点图示"
                                    name="editContainer">
                                    <div>
                                        <Input style={{'width': '374px', 'height': '200px'}}/>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="站点介绍"
                                    name="introduce">
                                    <TextArea rows={4}/>
                                </Form.Item>
                                <Form.Item
                                    label="站点备注"
                                    name="remarks">
                                    <TextArea rows={4}/>
                                </Form.Item>
                                <Form.Item
                                    label="站点图片">
                                    <Upload
                                        name="file"
                                        accept=".png,.jpg,.jpeg"
                                        maxCount={1}
                                        fileList={this.state.fileList}
                                        onChange={this.fileChange}
                                        onRemove={this.onRemove}
                                        beforeUpload={this.beforeUpload}>
                                        <Button type="primary">
                                            选择文件
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.data}
                           rowKey={columns => columns.id}
                           rowSelection={rowSelection}
                           loading={this.state.loading}
                           scroll={{x: 2200}}
                           style={{'marginTop': '20px'}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                    <div style={{
                        marginTop: "-38px",
                        marginLeft: "8px",
                        display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                    }}>
                        < Checkbox onChange={this.exportAllSelect}
                                   checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                    </div>
                </div>
            </div>
        );
    }
}

export default SiteInfo;