import React from 'react'
import {Button, Checkbox, Form, Image, Input, message, Modal, Select, Space, Table, Upload} from "antd";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const {TextArea} = Input;
let map = null
class ServiceSiteInfo extends React.Component {
    addFormRef = React.createRef();
    viewFormRef = React.createRef();
    editFormRef = React.createRef();
    state = {
        data: null,
        name: null,
        status: null,
        remarks: null,
        loading: true,
        visible: false,
        viewVisible: false,
        editVisible: false,
        current: 1,
        pageSize: 10,
        total: 0,
        selectedRowKeys: [],
        isExporting: false,
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
        userPermission: null,
        listServiceAccount: null,
        iconFile: null,
        fileList: [],
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_SERVICE_SITE_URL,
            params: {
                name: this.state.name,
                status: this.state.status,
                remarks: this.state.remarks,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    listServiceAccount = (userName) => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_SERVICE_ACCOUNT_URL,
            params: {
                distribution: false,
                userName: userName,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        listServiceAccount: res.data.data,
                    });
                } else {
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    searchServiceSite = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    resetSearch = () => {
        this.setState({
            name: null,
            status: null,
            remarks: null,
        });
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            viewVisible: false,
            editVisible: false,
        });
        if (this.addFormRef.current != null) {
            this.addFormRef.current.resetFields();
        }
        if (this.editFormRef.current != null) {
            this.editFormRef.current.resetFields();
        }
        if (this.viewFormRef.current != null) {
            this.viewFormRef.current.resetFields();
        }
		map.destroy()
    }
    openAddServiceSiteModal = () => {
        this.setState({
            visible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.listServiceAccount(null);
            this.initMap('container', null, null, 'addAddress');
        }, 100);
    }
    exportServiceSiteInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: PATH_URL.EXPORT_SERVICE_SITE_INFO_URL + "/" + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '网点数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }

    showServiceSite = (record) => {
        this.setState({
            viewVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.viewFormRef.current.setFieldsValue(record);
        }, 100);
    }
    showEditServiceSite = (record) => {
        this.listServiceAccount(record.userName);
        this.setState({
            editVisible: true,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
            this.editFormRef.current.setFieldsValue({
                'editAddress': record.address,
            });
            this.setState({
                editAddress: record.address,
            })
            this.initMap('editContainer', record.longitude, record.latitude, 'editAddress');
        }, 100);
    }
    showChangeServiceSiteStatus = (record, status) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认" + (status === 0 ? "启用" : "禁用") + "该网点？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: PATH_URL.CHANGE_SITE_STATUS_URL + "/" + record.id,
                    params: {
                        status: status,
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    showDeleteServiceSite = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该网点？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.DELETE_SERVICE_SITE_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    addServiceSite = () => {
        let _this = this;
        window.AMap.plugin(['AMap.Autocomplete'], function () {
            // 实例化Autocomplete
            let autoOptions = {
                city: '全国',
                input: "addAddress"
            }
            let autoComplete = new window.AMap.Autocomplete(autoOptions);
            if (!Util.isEmpty(_this.state.addAddress)) {
                autoComplete.search(_this.state.addAddress, function (status, result) {
                    if (status === 'complete' && result.tips.length > 0) {
                        _this.addFormRef.current.setFieldsValue({
                            'longitude': result.tips[0].location.lng,
                            'latitude': result.tips[0].location.lat,
                        })
                        _this.addFormRef.current.validateFields()
                            .then(values => {
                                values.address = _this.state.addAddress;
                                let formData = new FormData();
                                formData.append('serviceSite', new Blob([JSON.stringify(values)], {type: "application/json"}));
                                formData.append('iconFile', _this.state.iconFile);
                                Axios({
                                    method: 'post',
                                    url: PATH_URL.ADD_SERVICE_SITE_URL,
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                    data: formData,
                                }).then(res => {
                                    if (res.status === 200) {
                                        if (res.data.code === 200) {
                                            _this.setState({
                                                loading: true,
                                                visible: false,
                                                iconFile: null,
                                                fileList: [],
                                                addAddress: null,
                                            });
                                            message.success(res.data.message);
                                            _this.initData();
                                            _this.addFormRef.current.resetFields();
                                        } else {
                                            message.error(res.data.message);
                                        }
                                    } else if (res.code === 401) {
                                        message.error(res.message);
                                    }
                                }).catch(err => {
                                    console.log(err);
                                });
                            })
                            .catch(info => {
                                console.log(info);
                            });
                    }
                });
            } else {
                message.error("请输入网点的地址");
            }
        });
    }
    updateServiceSite = () => {
        let _this = this;
        window.AMap.plugin(['AMap.Autocomplete'], function () {
            // 实例化Autocomplete
            let autoOptions = {
                city: '全国',
                input: "editAddress"
            }
            let autoComplete = new window.AMap.Autocomplete(autoOptions);
            if (!Util.isEmpty(_this.state.editAddress)) {
                autoComplete.search(_this.state.editAddress, function (status, result) {
                    if (status === 'complete' && result.tips.length > 0) {
                        _this.editFormRef.current.setFieldsValue({
                            'longitude': result.tips[0].location.lng,
                            'latitude': result.tips[0].location.lat,
                        });
                        _this.editFormRef.current.validateFields()
                            .then(values => {
                                values.address = _this.state.editAddress;
                                let formData = new FormData();
                                formData.append('serviceSite', new Blob([JSON.stringify(values)], {type: "application/json"}));
                                formData.append('iconFile', _this.state.iconFile);
                                Axios({
                                    method: 'post',
                                    url: PATH_URL.UPDATE_SERVICE_SITE_URL,
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                    data: formData,
                                }).then(res => {
                                    if (res.status === 200) {
                                        if (res.data.code === 200) {
                                            _this.setState({
                                                loading: true,
                                                editVisible: false,
                                                iconFile: null,
                                                fileList: [],
                                                editAddress: null,
                                            });
                                            message.success(res.data.message);
                                            _this.initData();
                                            _this.editFormRef.current.resetFields();
                                        } else {
                                            message.error(res.data.message);
                                        }
                                    } else if (res.code === 401) {
                                        message.error(res.message);
                                    }
                                }).catch(err => {
                                    console.log(err);
                                });
                            })
                            .catch(info => {
                                console.log(info);
                            });
                    } else {
                        message.error("请输入正确的地址");
                    }
                });
            } else {
                message.error("请输入网点的地址");
            }
        });
    }
    initMap = (container, longitude, latitude, searchText) => {
        let _this = this;
        map = new window.AMap.Map(container, {
            zoom: 11,//级别
            viewMode: '3D',//使用3D视图
        });

        //输入提示
        let autoOptions = {
            input: searchText
        };
        window.AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], function () {
            let auto = new window.AMap.AutoComplete(autoOptions);
            let placeSearch = new window.AMap.PlaceSearch({
                map: map
            });  //构造地点查询类
            auto.on("select", select);//注册监听，当选中某条记录时会触发
            function select(e) {
                placeSearch.setCity(e.poi.adcode);
                placeSearch.search(e.poi.name);  //关键字查询查询
                if (searchText === "addAddress") {
                    _this.setState({
                        addAddress: e.poi.name,
                    });
                } else if (searchText === "editAddress") {
                    _this.setState({
                        editAddress: e.poi.name,
                    });
                }
            }
        });
        if (longitude != null && latitude != null) {
            map.setCenter([longitude, latitude]);
        }

    }
    fileChange = (e) => {
        this.setState({
            iconFile: (e.fileList.length > 0 && (e.file.size / (1024 * 1024) < 10)) ? e.fileList[0].originFileObj : null,
        })
    }
    beforeUpload = (file, fileList) => {
        if (file.size / (1024 * 1024) >= 10) {
            message.error("文件大小不能超过10M！", 2);
            this.setState({
                fileList: [],
                iconFile: null
            })
        } else {
            this.setState({
                fileList: fileList,
                iconFile: file
            })
        }
        return false;
    }
    onRemove = (file) => {
        this.setState({
            fileList: []
        })
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: PATH_URL.LIST_SERVICE_SITE_IDS_URL,
                params: {
                    siteName: this.state.siteName,
                    status: this.state.status,
                    remarks: this.state.remarks,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll
        };
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const columns = [
            {
                title: '网点名称',
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
                width: 150,
                ellipsis: true,
            },
            {
                title: '网点电话',
                dataIndex: 'serviceSiteTel',
                key: 'serviceSiteTel',
                fixed: 'left',
                width: 120,
            },
            {
                title: '网点地址',
                dataIndex: 'address',
                key: 'address',
                fixed: 'left',
                width: 150,
                ellipsis: true,
            },
            {
                title: '所属用户',
                dataIndex: 'serviceAccount',
                key: 'serviceAccount',
                fixed: 'left',
                width: 120,
                ellipsis: true,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: role => {
                    switch (role) {
                        case 0:
                            return '启用中';
                        case 1:
                            return '禁用中';
                        default:
                            return '启用中';
                    }
                }
            },
            {
                title: '图片',
                dataIndex: 'icon',
                key: 'icon',
                render: icon => {
                    if (!Util.isEmpty(icon)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={icon}/>
                        )
                    }
                }
            },
            {
                title: '网点介绍',
                dataIndex: 'introduce',
                key: 'introduce',
                ellipsis: true,
            },
            {
                title: '经度',
                dataIndex: 'longitude',
                key: 'longitude',
            },
            {
                title: '纬度',
                dataIndex: 'latitude',
                key: 'latitude',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                width: 160,
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300088') ? (
                                <a onClick={this.showServiceSite.bind(this, record)}>查看</a>) : null
                        }
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300089') ? (
                                <a onClick={this.showEditServiceSite.bind(this, record)}>修改</a>) : null
                        }
                        {record.status === 0 ? (this.props.userPermission != null && this.props.userPermission.includes('300090') ? (
                            <a style={{'color': '#f5ce0a'}}
                               onClick={this.showChangeServiceSiteStatus.bind(this, record, 1)}>禁用</a>) : null) : (
                            this.props.userPermission != null && this.props.userPermission.includes('300091') ? (
                                <a style={{'color': '#f5ce0a'}}
                                   onClick={this.showChangeServiceSiteStatus.bind(this, record, 0)}>启用</a>) : null)
                        }
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300092') ? (
                                <a style={{'color': '#d43f3a'}}
                                   onClick={this.showDeleteServiceSite.bind(this, record)}>删除</a>) : null
                        }

                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item
                        label="网点名称"
                        name="name"
                        style={{width: 270}}>
                        <Input placeholder="请输入网点名称"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="状态"
                        name="status"
                        style={{width: 150}}>
                        <Select onChange={this.statusChange}>
                            <Select.Option value="0">启用中</Select.Option>
                            <Select.Option value="1">禁用中</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remarks"
                        style={{width: 270}}>
                        <Input placeholder="请输入备注"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.searchServiceSite}
                                style={{'marginLeft': '10px'}}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300086') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.openAddServiceSiteModal}>新增</Button>) : null
                        }
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300087') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.exportServiceSiteInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null
                        }
                    </Form.Item>
                </Form>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            新增
                        </div>
                    }
                    visible={this.state.visible}
                    onOk={this.addServiceSite}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.addFormRef}>
                        <Form.Item
                            label="网点名称"
                            name="name"
                            rules={[{required: true, message: '请输入网点名称'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="所属用户"
                            name="userName"
                            rules={[{required: true, message: '请选择所属用户'}]}>
                            <Select>
                                {
                                    this.state.listServiceAccount != null ?
                                        this.state.listServiceAccount.map(item => {
                                            return (<Select.Option key={item.userName}
                                                                   value={item.userName}>{item.realName}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="网点地址"
                            name="addAddress"
                            rules={[{required: true, message: '请输入地址'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="经度"
                            name="longitude"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="纬度"
                            name="latitude"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="网点图示"
                            name="container">
                            <div>
                                <Input style={{'width': '374px', 'height': '200px'}}/>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="网点介绍"
                            name="introduce">
                            <TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item
                            label="网点备注"
                            name="remarks">
                            <TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item
                            label="网点图片">
                            <Upload
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.fileChange}
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}>
                                <Button type="primary">
                                    选择文件
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            查看
                        </div>
                    }
                    visible={this.state.viewVisible}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.viewFormRef}>
                        <Form.Item
                            label="网点名称"
                            name="name">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="网点电话"
                            name="serviceSiteTel">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="所属用户"
                            name="serviceAccount">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="网点地址"
                            name="address">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="网点介绍"
                            name="introduce">
                            <TextArea disabled rows={4}/>
                        </Form.Item>
                        <Form.Item
                            label="网点备注"
                            name="remarks">
                            <TextArea disabled rows={4}/>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            修改
                        </div>
                    }
                    visible={this.state.editVisible}
                    onOk={this.updateServiceSite}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.editFormRef}>
                        <Form.Item
                            label="网点ID"
                            name="id"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="网点名称"
                            name="name"
                            rules={[{required: true, message: '请输入网点名称'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="所属用户"
                            name="userName"
                            rules={[{required: true, message: '请选择所属用户'}]}>
                            <Select>
                                {
                                    this.state.listServiceAccount != null ?
                                        this.state.listServiceAccount.map(item => {
                                            return (<Select.Option key={item.userName}
                                                                   value={item.userName}>{item.realName}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="网点地址"
                            name="editAddress"
                            rules={[{required: true, message: '请输入地址'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="经度"
                            name="longitude"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="纬度"
                            name="latitude"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="网点图示"
                            name="editContainer">
                            <div>
                                <Input style={{'width': '374px', 'height': '200px'}}/>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="网点介绍"
                            name="introduce">
                            <TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item
                            label="网点备注"
                            name="remarks">
                            <TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item
                            label="网点图片">
                            <Upload
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.fileChange}
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}>
                                <Button type="primary">
                                    选择文件
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       rowSelection={rowSelection}
                       loading={this.state.loading}
                       scroll={{x: 2200}}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <div style={{
                    marginTop: "-38px",
                    marginLeft: "8px",
                    display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                }}>
                    < Checkbox onChange={this.exportAllSelect}
                               checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                </div>
            </div>
        )
    }
}

export default ServiceSiteInfo;