import React from 'react';
import Util from "../../../utils/utils";
import {Button, DatePicker, Form, message, Modal, Select, Space, Table} from "antd";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import TravelOrderCanceledDetailed from "./TravelOrderCanceledDetailed";

export default class TravelOrderCompleted extends React.Component {
    state = {
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        data: null,
        year: null,
        month: null,
        visible: false,
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.TRAVEL_ACHIEVEMENT.LIST_CANCELED_TRAVEL_ORDER_URL,
            params: {
                year: this.state.year,
                month: this.state.month,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    onDatePickerYearChange = (value, dateString) => {
        this.setState({
            year: dateString
        })
    }
    monthChange = (value) => {
        this.setState({
            month: value
        })
    }
    resetSearch = () => {
        this.setState({
            year: null,
            month: null
        })
    }
    searchTravelOrder = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }
    showCanceledOrderInfo = (record) => {
        this.setState({
            year: record.year,
            month: record.month,
            visible: true,
        })
    }

    render() {
        const columns = [
            {
                title: '年度',
                dataIndex: 'year',
                key: 'year',
            },
            {
                title: '月份',
                dataIndex: 'month',
                key: 'month',
            },
            {
                title: '取消订单数',
                dataIndex: 'sumCanceled',
                key: 'sumCanceled',
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        <a onClick={this.showCanceledOrderInfo.bind(this, record)}>明细</a>
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item
                        label="年度"
                        name="year"
                        style={{width: 200}}>
                        <DatePicker onChange={this.onDatePickerYearChange} picker="year"/>
                    </Form.Item>
                    <Form.Item
                        label="月份"
                        name="month"
                        style={{width: 200}}>
                        <Select onChange={this.monthChange}>
                            <Select.Option value="01">1月</Select.Option>
                            <Select.Option value="02">2月</Select.Option>
                            <Select.Option value="03">3月</Select.Option>
                            <Select.Option value="04">4月</Select.Option>
                            <Select.Option value="05">5月</Select.Option>
                            <Select.Option value="06">6月</Select.Option>
                            <Select.Option value="07">7月</Select.Option>
                            <Select.Option value="08">8月</Select.Option>
                            <Select.Option value="09">9月</Select.Option>
                            <Select.Option value="10">10月</Select.Option>
                            <Select.Option value="11">11月</Select.Option>
                            <Select.Option value="12">12月</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.searchTravelOrder}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                    </Form.Item>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.date}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            明细
                        </div>
                    }
                    visible={this.state.visible}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    width={1000}>
                    <TravelOrderCanceledDetailed year={this.state.year} month={this.state.month}/>
                </Modal>
            </div>
        )
    }
}