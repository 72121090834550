import React from 'react'
import style from './LeftMenu.module.css'
import {Menu, message} from "antd";
import {
    LeftCircleFilled,
    RightCircleFilled,
    ToolOutlined,
    UserOutlined,
    CarOutlined,
    ShoppingCartOutlined,
    PayCircleOutlined,
    MoneyCollectOutlined,
    GiftOutlined,
    ShopOutlined,
    CarFilled,
    TransactionOutlined,
    SecurityScanOutlined,
    AuditOutlined,
    BellOutlined,
    TeamOutlined,
    DollarCircleOutlined,
    AccountBookOutlined,
    CreditCardOutlined,
    RocketOutlined,
    FlagOutlined,
    //TagsOutlined
} from '@ant-design/icons'
import {Link} from "react-router-dom";
import Axios from "../../utils/axios";

let defaultScrollTop = 0;

class LeftMenu extends React.Component {
    menuContentRef = React.createRef();
    state = {
        menuOpen: true,
        collapsed: false,
        selectedKey: 'user',
        userRole: 0,
        userPermission: [],
    }
    menuSwitch = () => {
        this.setState({
            menuOpen: !this.state.menuOpen,
            collapsed: !this.state.collapsed,
        })
        this.state.menuOpen ? (document.getElementById('content').style.width = "96.5%") : (document.getElementById('content').style.width = "89.5%");
        this.state.menuOpen ? (document.getElementById('content').style.marginLeft = "50px") : (document.getElementById('content').style.marginLeft = "150px");
    }

    constructor(props) {
        super(props);
        this.getUserPermission();
    }

    componentDidUpdate() {
        this.menuContentRef.current.scrollTop = defaultScrollTop;
    }

    componentDidMount() {
        // 组件挂载时将defaultScrollTop赋值滚动条的scrollTop
        if (this.menuContentRef) {
            if (window.sessionStorage.getItem("defaultScrollTop")) {
                defaultScrollTop = window.sessionStorage.getItem("defaultScrollTop");
            }
            this.menuContentRef.current.addEventListener("scroll", e => {
                defaultScrollTop = e.target.scrollTop;
                window.sessionStorage.setItem("defaultScrollTop", defaultScrollTop);
            });
            this.menuContentRef.current.scrollTop = defaultScrollTop;
        }

        let userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        const pathname = this.props.history.location.pathname;
        this.setState({
            selectedKey: pathname.split('/')[1],
            userRole: userRole,
        });
    }

    componentWillUnmount() {
        // 组件卸载时移除监听事件，同时拿到defaultScrollTop
        if (this.menuContentRef) {
            this.menuContentRef.current.removeEventListener("scroll", e => {
                this.menuContentRef.scrollTop = e.target.scrollTop;
            });
            defaultScrollTop = this.menuContentRef.current.scrollTop;
        }
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: '/menu/getUserMenu/' + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        const menuOpen = this.state.menuOpen ? style['menu_open'] : style['menu_close'];
        const inlineWith = this.state.collapsed ? "20px" : null;
        const userRole = this.state.userRole;
        return (
            <div id='menu' className={style['menu'] + ' ' + menuOpen}>
                <div className={style['ball']} onClick={this.menuSwitch}>
                    {this.state.menuOpen ? (<LeftCircleFilled style={{'color': '#4aa1f7e3', 'fontSize': '22px'}}/>) : (
                        <RightCircleFilled style={{'color': '#4aa1f7e3', 'fontSize': '22px'}}/>)}
                </div>
                <div className={style['menu_content']} ref={this.menuContentRef}>
                    {(() => {
                        switch (userRole) {
                            case 0:
                                return (
                                    <Menu mode="inline" inlineCollapsed={this.state.collapsed}
                                          style={{
                                              'padding': '0px',
                                              'background': '#f7f7f7',
                                              'border': '0px',
                                              'width': inlineWith
                                          }}
                                          inlineIndent={5} selectedKeys={this.state.selectedKey}>
                                        {this.state.userPermission.includes('100001') ?
                                            (<Menu.Item key="user" icon={<UserOutlined/>} title="用户管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/user"}>
                                                    用户管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100002') ?
                                            (<Menu.Item key="site" icon={<ToolOutlined/>} title="站点管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/site"}>
                                                    站点管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100003') ?
                                            (<Menu.Item key="carModel" icon={<CarOutlined/>} title="车辆类型"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/carModel"}>
                                                    车辆类型
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100004') ?
                                            (<Menu.Item key="order" icon={<ShoppingCartOutlined/>} title="订单管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/order"}>
                                                    订单管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100005') ?
                                            (<Menu.Item key="withdrawal" icon={<MoneyCollectOutlined/>} title="提现管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/withdrawal"}>
                                                    提现管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100006') ?
                                            (<Menu.Item key="activity" icon={<GiftOutlined/>} title="活动管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/activity"}>
                                                    活动管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {/*{this.state.userPermission.includes('100007') ?*/}
                                        {/*    (<Menu.Item key="commodity" icon={<ShopOutlined/>} title="商品管理"*/}
                                        {/*                style={{'height': '30px', 'lineHeight': '30px'}}>*/}
                                        {/*        <Link to={"/commodity"}>*/}
                                        {/*            商品管理*/}
                                        {/*        </Link>*/}
                                        {/*    </Menu.Item>) : null*/}
                                        {/*}*/}
                                        {this.state.userPermission.includes('100008') ?
                                            (<Menu.Item key="carKnowledge" icon={<CarFilled/>} title="车辆知识"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/carKnowledge"}>
                                                    车辆知识
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100009') ?
                                            (<Menu.Item key="integralManage" icon={<TransactionOutlined/>} title="积分管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/integralManage"}>
                                                    积分管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100011') ?
                                            (<Menu.Item key="agreementManage" icon={<AuditOutlined/>} title="协议管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/agreementManage"}>
                                                    协议管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100012') ?
                                            (<Menu.Item key="workOrderManage" icon={<BellOutlined/>} title="工单管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/workOrderManage"}>
                                                    工单管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100013') ?
                                            (<Menu.Item key="serviceSite" icon={<ShopOutlined/>} title="服务网点"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/serviceSite"}>
                                                    服务网点
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100014') ?
                                            (<Menu.Item key="exchangeManage" icon={<DollarCircleOutlined/>} title="兑换管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/exchangeManage"}>
                                                    兑换管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100015') ?
                                            (<Menu.Item key="achievementManage" icon={<AccountBookOutlined/>}
                                                        title="业绩管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/achievementManage"}>
                                                    业绩管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {this.state.userPermission.includes('100016') ?
                                            (<Menu.Item key="substituteDrivingManage" icon={<RocketOutlined/>}
                                                        title="代驾管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/substituteDrivingManage"}>
                                                    代驾管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                        {
                                            <Menu.Item key="tourismManage" icon={<FlagOutlined/>}
                                                       title="旅游管理"
                                                       style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/tourismManage"}>
                                                    旅游管理
                                                </Link>
                                            </Menu.Item>
                                        }
                                        {this.state.userPermission.includes('100010') ?
                                            (<Menu.Item key="jurisdictionManage" icon={<SecurityScanOutlined/>}
                                                        title="权限管理"
                                                        style={{'height': '30px', 'lineHeight': '30px'}}>
                                                <Link to={"/jurisdictionManage"}>
                                                    权限管理
                                                </Link>
                                            </Menu.Item>) : null
                                        }
                                    </Menu>
                                )
                            case 1:
                                return (
                                    <Menu mode="inline" inlineCollapsed={this.state.collapsed}
                                          style={{
                                              'padding': '0px',
                                              'background': '#f7f7f7',
                                              'border': '0px',
                                              'width': inlineWith
                                          }}
                                          inlineIndent={5} selectedKeys={this.state.selectedKey}>
                                        <Menu.Item key="order" icon={<ShoppingCartOutlined/>} title="订单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/order"}>
                                                订单管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="achievement" icon={<PayCircleOutlined/>} title="业绩查询"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/achievement"}>
                                                业绩查询
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="workOrderManage" icon={<BellOutlined/>} title="工单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/workOrderManage"}>
                                                工单管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="substituteDrivingManage" icon={<RocketOutlined/>}
                                                   title="代驾管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/substituteDrivingManage"}>
                                                代驾管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="tourismManage" icon={<FlagOutlined/>} title="旅游管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/tourismManage"}>
                                                旅游管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="myExchangeRoll" icon={<CreditCardOutlined/>} title="我的兑换卷"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/myExchangeRoll"}>
                                                我的兑换卷
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                )
                            case 2:
                                return (
                                    <Menu mode="inline" inlineCollapsed={this.state.collapsed}
                                          style={{
                                              'padding': '0px',
                                              'background': '#f7f7f7',
                                              'border': '0px',
                                              'width': inlineWith
                                          }}
                                          inlineIndent={5} selectedKeys={this.state.selectedKey}>
                                        <Menu.Item key="order" icon={<ShoppingCartOutlined/>} title="订单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/order"}>
                                                订单管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="achievement" icon={<PayCircleOutlined/>} title="业绩查询"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/achievement"}>
                                                业绩查询
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="workOrderManage" icon={<BellOutlined/>} title="工单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/workOrderManage"}>
                                                工单管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="substituteDrivingManage" icon={<RocketOutlined/>}
                                                   title="代驾管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/substituteDrivingManage"}>
                                                代驾管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="tourismManage" icon={<FlagOutlined/>} title="旅游管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/tourismManage"}>
                                                旅游管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="withdrawal" icon={<MoneyCollectOutlined/>} title="提现管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/withdrawal"}>
                                                提现管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="myExchangeRoll" icon={<CreditCardOutlined/>} title="我的兑换卷"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/myExchangeRoll"}>
                                                我的兑换卷
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                )
                            case 3:
                                return (
                                    <Menu mode="inline" inlineCollapsed={this.state.collapsed}
                                          style={{
                                              'padding': '0px',
                                              'background': '#f7f7f7',
                                              'border': '0px',
                                              'width': inlineWith
                                          }}
                                          inlineIndent={5} selectedKeys={this.state.selectedKey}>
                                        <Menu.Item key="order" icon={<ShoppingCartOutlined/>} title="订单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/order"}>
                                                订单管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="workOrderManage" icon={<BellOutlined/>} title="工单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/workOrderManage"}>
                                                工单管理
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                )
                            case 5:
                                return (
                                    <Menu mode="inline" inlineCollapsed={this.state.collapsed}
                                          style={{
                                              'padding': '0px',
                                              'background': '#f7f7f7',
                                              'border': '0px',
                                              'width': inlineWith
                                          }}
                                          inlineIndent={5} selectedKeys={this.state.selectedKey}>
                                        <Menu.Item key="order" icon={<ShoppingCartOutlined/>} title="订单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/order"}>
                                                订单管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="teamMembersManage" icon={<TeamOutlined/>} title="组员管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/teamMembersManage"}>
                                                组员管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="achievement" icon={<PayCircleOutlined/>} title="业绩查询"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/achievement"}>
                                                业绩查询
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="substituteDrivingManage" icon={<RocketOutlined/>}
                                                   title="代驾管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/substituteDrivingManage"}>
                                                代驾管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="workOrderManage" icon={<BellOutlined/>} title="工单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/workOrderManage"}>
                                                工单管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="tourismManage" icon={<FlagOutlined/>} title="旅游管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/tourismManage"}>
                                                旅游管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="myExchangeRoll" icon={<CreditCardOutlined/>} title="我的兑换卷"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/myExchangeRoll"}>
                                                我的兑换卷
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                )
                            case 6:
                                return (
                                    <Menu mode="inline" inlineCollapsed={this.state.collapsed}
                                          style={{
                                              'padding': '0px',
                                              'background': '#f7f7f7',
                                              'border': '0px',
                                              'width': inlineWith
                                          }}
                                          inlineIndent={5} selectedKeys={this.state.selectedKey}>
                                        <Menu.Item key="order" icon={<ShoppingCartOutlined/>} title="订单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/order"}>
                                                订单管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="achievement" icon={<PayCircleOutlined/>} title="业绩查询"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/achievement"}>
                                                业绩查询
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="tourismManage" icon={<FlagOutlined/>} title="旅游管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/tourismManage"}>
                                                旅游管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="workOrderManage" icon={<BellOutlined/>} title="工单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/workOrderManage"}>
                                                工单管理
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                )
                            case 7:
                                return (
                                    <Menu mode="inline" inlineCollapsed={this.state.collapsed}
                                          style={{
                                              'padding': '0px',
                                              'background': '#f7f7f7',
                                              'border': '0px',
                                              'width': inlineWith
                                          }}
                                          inlineIndent={5} selectedKeys={this.state.selectedKey}>
                                        <Menu.Item key="exchangeOrderManage" icon={<ShoppingCartOutlined/>} title="订单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/exchangeOrderManage"}>
                                                订单管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="workOrderManage" icon={<BellOutlined/>} title="工单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/workOrderManage"}>
                                                工单管理
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                )
                            case 8:
                                return (
                                    <Menu mode="inline" inlineCollapsed={this.state.collapsed}
                                          style={{
                                              'padding': '0px',
                                              'background': '#f7f7f7',
                                              'border': '0px',
                                              'width': inlineWith
                                          }}
                                          inlineIndent={5} selectedKeys={this.state.selectedKey}>
                                        <Menu.Item key="tourismManage" icon={<FlagOutlined/>} title="旅游管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/tourismManage"}>
                                                旅游管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="travelTeamMembersManage" icon={<TeamOutlined/>} title="团队成员"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/travelTeamMembersManage"}>
                                                团队成员
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="workOrderManage" icon={<BellOutlined/>} title="工单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/workOrderManage"}>
                                                工单管理
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                )
                            case 9:
                                return (
                                    <Menu mode="inline" inlineCollapsed={this.state.collapsed}
                                          style={{
                                              'padding': '0px',
                                              'background': '#f7f7f7',
                                              'border': '0px',
                                              'width': inlineWith
                                          }}
                                          inlineIndent={5} selectedKeys={this.state.selectedKey}>
                                        <Menu.Item key="tourismManage" icon={<FlagOutlined/>} title="旅游管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/tourismManage"}>
                                                旅游管理
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="workOrderManage" icon={<BellOutlined/>} title="工单管理"
                                                   style={{'height': '30px', 'lineHeight': '30px'}}>
                                            <Link to={"/workOrderManage"}>
                                                工单管理
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                )
                            default:
                                return null
                        }
                    })()}
                </div>
            </div>
        )
    }
}

export default LeftMenu;