import React from 'react'
import {message, Tabs} from "antd";
import style from "../Content.module.css";
import CommodityInfo from "./CommodityInfo";
import CashRecord from "./CashRecord";
import CommodityCategory from "./CommodityCategory";
import Axios from "../../../utils/axios";

const {TabPane} = Tabs;

class CommodityTab extends React.Component {
    state = {
        activeKey: 1,
        userPermission: null,
    }

    componentDidMount() {
        this.getUserPermission();
    }

    onChange = (key) => {
        this.setState({
            activeKey: key
        })
    }
    getUserPermission = () => {
        Axios({
            method: 'get',
            url: '/menu/getUserMenu/' + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Tabs defaultActiveKey="1" onChange={this.onChange}>
                        {this.state.userPermission != null && this.state.userPermission.includes('200008') ? (
                            <TabPane tab="商品类别" key="1">
                                <CommodityCategory userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200009') ? (
                            <TabPane tab="商品信息" key="2">
                                {this.state.activeKey === '2' ?
                                    <CommodityInfo userPermission={this.state.userPermission}/> : null}
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200010') ? (
                            <TabPane tab="兑现记录" key="3">
                                <CashRecord userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                    </Tabs>
                </div>
            </div>
        )
    }

}

export default CommodityTab;