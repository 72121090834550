import React from 'react';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import {ConfigProvider} from "antd";
import zhCN from 'antd/es/locale/zh_CN';
import Home from "./home/Home";
import Login from "./login/Login"
import Setting from "./home/set/Setting";
import Site from "./home/site/Site";
import User from "./home/user/User";
import CarModel from "./home/carModel/CarModel";
import Car from "./home/car/Car";
import Order from "./home/order/Order";
import Achievement from "./home/achievement/Achievement";
import Withdrawal from "./home/withdrawal/Withdrawal";
import Activity from "./home/activity/Activity";
import CommodityManage from "./home/commodity/CommodityManage";
import CarKnowledge from "./home/carKnowledge/CarKnowledge";
import IntegralManage from "./home/integralManage/IntegralManage";
import JurisdictionManage from "./home/jurisdictionManage/JurisdictionManage";
import AgreementManage from "./home/agreement/AgreementManage";
import WorkOrderManage from "./home/workOrder/WorkOrderManage";
import TeamMembersManage from "./home/teamMembers/TeamMembersManage";
import ServiceSite from "./home/serviceSite/ServiceSite";
import ExchangeManage from "./home/exchangeManage/ExchangeManage";
import AchievementManage from "./home/achievementManage/AchievementManage";
import MyExchangeRoll from "./home/myExchangeRoll/MyExchangeRoll";
import ExchangeOrderManage from "./home/exchangeOrder/ExchangeOrderManage";
import SubstituteDrivingManage from "./home/substituteDriving/SubstituteDrivingManage";
import TourismManage from "./home/tourismManage/TourismManage";
import TravelTeamMembersManage from "./home/tourismManage/TravelTeamMembersManage";

class App extends React.Component {
    render() {
        return (
            <ConfigProvider locale={zhCN}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={Home}></Route>
                        <Route path="/home" component={Home}></Route>
                        <Route path="/login" component={Login}></Route>
                        <Route path="/setting" component={Setting}></Route>
                        <Route path="/site" component={Site}></Route>
                        <Route path="/user" component={User}></Route>
                        <Route path="/carModel" component={CarModel}></Route>
                        <Route path="/car" component={Car}></Route>
                        <Route path="/order" component={Order}></Route>
                        <Route path="/achievement" component={Achievement}></Route>
                        <Route path="/achievementManage" component={AchievementManage}></Route>
                        <Route path="/withdrawal" component={Withdrawal}></Route>
                        <Route path="/activity" component={Activity}></Route>
                        <Route path="/commodity" component={CommodityManage}></Route>
                        <Route path="/carKnowledge" component={CarKnowledge}></Route>
                        <Route path="/integralManage" component={IntegralManage}></Route>
                        <Route path="/jurisdictionManage" component={JurisdictionManage}></Route>
                        <Route path="/teamMembersManage" component={TeamMembersManage}></Route>
                        <Route path="/agreementManage" component={AgreementManage}></Route>
                        <Route path="/workOrderManage" component={WorkOrderManage}></Route>
                        <Route path="/serviceSite" component={ServiceSite}></Route>
                        <Route path="/exchangeManage" component={ExchangeManage}></Route>
                        <Route path="/myExchangeRoll" component={MyExchangeRoll}></Route>
                        <Route path="/exchangeOrderManage" component={ExchangeOrderManage}></Route>
                        <Route path="/substituteDrivingManage" component={SubstituteDrivingManage}></Route>
                        <Route path="/tourismManage" component={TourismManage}></Route>
                        <Route path="/travelTeamMembersManage" component={TravelTeamMembersManage}></Route>
                        <Redirect to="/home"></Redirect>
                    </Switch>
                </BrowserRouter>
            </ConfigProvider>
        );
    }
}

export default App;