const Util = {
    isEmpty(value) {
        return value === '' || value === undefined || value === null;
    },
    isEmail: function (email) {
        let reg = new RegExp(/^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/);
        if (reg.test(email)) {
            return true;
        } else {
            return false;
        }
    },
    isNumber: function (number) {
        let reg = new RegExp(/(^[1-9]\d*$)/);
        if (reg.test(number)) {
            return true;
        } else {
            return false;
        }
    },
    isTel: function (tel) {
        let reg = /^1[3456789]\d{9}$/;
        if (reg.test(tel)) {
            return true;
        } else {
            return false;
        }
    },
    isIdCard: function (idCard) {
        let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (!reg.test(idCard)) {
            return false;
        } else {
            return true;
        }
    },
    fillZero(str) {
        let realNum;
        if (str < 10) {
            realNum = '0' + str;
        } else {
            realNum = str;
        }
        return realNum;
    },
    isLicenseNo(str) {
        return /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$)/.test(str);
    }

}
export default Util;
