import React from 'react';
import Util from "../../../utils/utils";
import {Button, Form, Image, Input, message, Modal, Space, Table} from "antd";
import TravelUserInfo from "./TravelUserInfo";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";

export default class TravelOrderCompletedDetailed extends React.Component {
    viewFormRef = React.createRef();
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        viewVisible: false,
        travelName: null,
        remarks: null,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.TRAVEL_ACHIEVEMENT.LIST_TRAVEL_ORDER_DETAILED_URL,
            params: {
                year: this.props.year,
                month: this.props.month,
                groupName: this.props.groupName,
                travelName: this.state.travelName,
                remarks: this.state.remarks,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            travelName: null,
            remarks: null,
        });
    }
    handleCancel = () => {
        this.setState({
            viewVisible: false,
        });
    }
    searchTravelOrder = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    /**
     * 查看出行人
     * @param record
     */
    showTravelUserInfoModel = (record) => {
        this.setState({
            travelOrderId: record.id,
            viewVisible: true,
        });
    }

    render() {
        const columns = [
            {
                title: '出行名称',
                dataIndex: 'travelName',
                key: 'travelName',
                fixed: 'left',
                width: 150,
                ellipsis: true,
            },
            {
                title: '出行时间',
                dataIndex: 'travelDate',
                key: 'travelDate',
                width: 120,
            },
            {
                title: '邀请人',
                dataIndex: 'invitationUserRealName',
                key: 'invitationUserRealName',
                width: 120,
            },
            {
                title: '订单总价格',
                dataIndex: 'price',
                key: 'price',
                width: 100,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    switch (status) {
                        case 0:
                            return '未付款';
                        case 1:
                            return '已付款';
                        case 2:
                            return '已出行';
                        case 3:
                            return '已取消';
                        default:
                            return '未付款';
                    }
                }
            },
            {
                title: '付款凭证',
                dataIndex: 'icon',
                key: 'icon',
                render: icon => {
                    if (!Util.isEmpty(icon)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={icon}/>
                        )
                    }
                }
            },
            {
                title: '创建人',
                dataIndex: 'creatorRealName',
                key: 'creatorRealName',
                width: 120,
            },
            {
                title: '完成时间',
                dataIndex: 'gmtComplete',
                key: 'gmtComplete',
                width: 180
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 180
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                width: 200,
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                width: 90,
                render: (text, record) => (
                    <Space size="small">
                        <a onClick={this.showTravelUserInfoModel.bind(this, record)}>查看出行人</a>
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item
                        label="出行名称"
                        name="travelName"
                        style={{width: 270}}>
                        <Input placeholder="请输入出行名称"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remarks"
                        style={{width: 270}}>
                        <Input placeholder="请输入备注"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.searchTravelOrder}
                                style={{'marginLeft': '10px'}}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                    </Form.Item>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       scroll={{x: 1500}}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            查看出行人
                        </div>
                    }
                    visible={this.state.viewVisible}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    width={1000}>
                    <TravelUserInfo travelOrderId={this.state.travelOrderId}/>
                </Modal>
            </div>
        )
    }
}