import React from 'react'
import {message, Table} from "antd";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";

class RollCirculationRecordModel extends React.Component {
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_ROLL_FLOW_RECORD_URL,
            params: {
                rollNumber: this.props.rollNumber,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }

    render() {
        const columns = [
            {
                title: '兑换卷编码',
                dataIndex: 'rollNumber',
                key: 'rollNumber',
                width: 300,
            },
            {
                title: '兑换卷名称',
                dataIndex: 'rollName',
                key: 'rollName',
            },
            {
                title: '操作账户',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: '流程',
                dataIndex: 'process',
                key: 'process',
            },
            {
                title: '流转说明',
                dataIndex: 'flowExplain',
                key: 'flowExplain',
                width: 200,
                ellipsis: true,
            },
            {
                title: '流转时间',
                dataIndex: 'circulationTime',
                key: 'circulationTime',
                width: 165,
            },
        ];
        return (
            <div>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
            </div>
        )
    }
}

export default RollCirculationRecordModel;