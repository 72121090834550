import React from 'react';
import Util from "../../../utils/utils";
import {Button, Form, Image, Input, message, Modal, Select, Space, Table, Upload, InputNumber, Col, Row} from "antd";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import Editor from 'wangeditor-antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";

const {TextArea} = Input;
let userName = null;
export default class TravelManage extends React.Component {
    addFormRef = React.createRef();
    viewFormRef = React.createRef();
    editFormRef = React.createRef();
    editorRef = React.createRef();
    state = {
        data: null,
        name: null,
        status: null,
        remarks: null,
        loading: true,
        addVisible: false,
        viewVisible: false,
        editVisible: false,
        current: 1,
        pageSize: 10,
        total: 0,
        iconFile: null,
        fileList: [],
        characteristic: null,
        icon: null,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.TRAVEL.LIST_TRAVEL_INFO_URL,
            params: {
                name: this.state.name,
                status: this.state.status,
                remarks: this.state.remarks,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    /**
     * 查询出行信息
     */
    searchTravel = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    /**
     * 新增出行信息
     */
    addTravel = () => {
        this.addFormRef.current.validateFields().then(values => {
            if (values.iconFile === 'undefined') {
                message.error("请上传首页图片！");
                return;
            } else {
                let formData = new FormData();
                values.characteristic = this.state.characteristic;
                formData.append('travelInfoDTO', new Blob([JSON.stringify(values)], {type: "application/json"}));
                formData.append('iconFile', this.state.iconFile);
                Axios({
                    method: 'post',
                    url: PATH_URL.TRAVEL.ADD_TRAVEL_INFO_URL,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.setState({
                                loading: true,
                                addVisible: false,
                                iconFile: null,
                                fileList: [],
                            });
                            this.initData();
                            this.addFormRef.current.resetFields();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        }).catch(info => {
            console.log(info);
        });
    }
    openAddTravelModal = () => {
        this.setState({
            addVisible: true,
        })
        setTimeout(() => {
            this.initEditor('');
        }, 100);
    }
    /**
     * 删除行程
     * @param record
     */
    deleteTravel = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该行程？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.TRAVEL.DELETE_TRAVEL_INFO_URL + "/" + record.id,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    /**
     * 禁用或启用行程
     * @param record
     * @param status
     */
    changeTravelStatus = (record, status) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认" + (status === 0 ? "启用" : "禁用") + "该行程？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: PATH_URL.TRAVEL.UPDATE_TRAVEL_INFO_STATUS_URL + "/" + record.id,
                    params: {
                        status: status,
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    showUpdateTravel = (record) => {
        this.setState({
            editVisible: true,
        })
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
            this.initEditor(record.characteristic);
        }, 100);
    }
    /**
     * 更新行程信息
     */
    updateTravel = () => {
        this.editFormRef.current.validateFields().then(values => {
            let formData = new FormData();
            values.characteristic = this.state.characteristic;
            formData.append('travelInfoDTO', new Blob([JSON.stringify(values)], {type: "application/json"}));
            formData.append('iconFile', this.state.iconFile);
            Axios({
                method: 'post',
                url: PATH_URL.TRAVEL.UPDATE_TRAVEL_INFO_URL,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                            iconFile: null,
                            fileList: [],
                        });
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    /**
     * 查看行程信息
     * @param record
     */
    showTravel = (record) => {
        this.setState({
            viewVisible: true,
            icon: record.icon,
        })
        setTimeout(() => {
            this.viewFormRef.current.setFieldsValue(record);
            this.initEditor(record.characteristic);
        }, 100);
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            name: null,
            status: null,
            remarks: null,
        });
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    handleCancel = () => {
        this.setState({
            addVisible: false,
            viewVisible: false,
            editVisible: false,
        });
    }
    onRemove = (file) => {
        this.setState({
            fileList: []
        })
    }
    initEditor = (html) => {
        const _this = this;
        this.editor = new Editor(this.editorRef.current)
        this.editor.customConfig.menus = [
            'head',  // 标题
            'bold',  // 粗体
            'fontSize',  // 字号
            'fontName',  // 字体
            'italic',  // 斜体
            'underline',  // 下划线
            'strikeThrough',  // 删除线
            'foreColor',  // 文字颜色
            'backColor',  // 背景颜色
            'link',  // 插入链接
            'list',  // 列表
            'justify',  // 对齐方式
            'quote',  // 引用
            'image',  // 插入图片
            'table',  // 表格
            'undo',  // 撤销
            'redo'  // 重复
        ]
        this.editor.customConfig.uploadImgServer = PATH_URL.BASE_URL+'/travel/uploadFile'
        this.editor.customConfig.uploadFileName = 'imageFile'
        // 将图片大小限制为 10M
        this.editor.customConfig.uploadImgMaxSize = 10 * 1024 * 1024
        this.editor.customConfig.onchange = (html) => {
            // html为拿到的在div富文本框中输入的内容（使用样式时会自动带上标签）
            // 如：<p><span style="font-weight: bold;">演示<span style="color: rgb(249, 150, 59);">文本</span></span></p>
            _this.setState({
                characteristic: html,
            })
        }
        this.editor.create();
        this.editor.txt.html(html);
    }
    fileChange = (e) => {
        this.setState({
            iconFile: (e.fileList.length > 0 && (e.file.size / (1024 * 1024) < 10)) ? e.fileList[0].originFileObj : null,
        })
    }
    beforeUpload = (file, fileList) => {
        if (file.size / (1024 * 1024) >= 10) {
            message.error("文件大小不能超过10M！", 2);
            this.setState({
                fileList: [],
                iconFile: null
            })
        } else {
            this.setState({
                fileList: fileList,
                iconFile: file
            })
        }
        return false;
    }

    render() {
        const columns = [
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
                width: 150,
                ellipsis: true,
            },
            {
                title: '价格',
                dataIndex: 'price',
                key: 'price',
                width: 120,
                ellipsis: true,
            },
            {
                title: '标签',
                dataIndex: 'label',
                key: 'label',
                width: 120,
                ellipsis: true,
            },
            {
                title: '推荐等级',
                dataIndex: 'grade',
                key: 'grade',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: role => {
                    switch (role) {
                        case 0:
                            return '启用中';
                        case 1:
                            return '禁用中';
                        default:
                            return '启用中';
                    }
                }
            },
            {
                title: '图片',
                dataIndex: 'icon',
                key: 'icon',
                render: icon => {
                    if (!Util.isEmpty(icon)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={icon}/>
                        )
                    }
                }
            },
            {
                title: '产品介绍',
                dataIndex: 'introduce',
                key: 'introduce',
                ellipsis: true,
            },
            {
                title: '产品特色',
                dataIndex: 'characteristic',
                key: 'characteristic',
                ellipsis: true,
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                width: 160,
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        <a onClick={this.showTravel.bind(this, record)}>查看</a>
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300116') ? (
                                <a onClick={this.showUpdateTravel.bind(this, record)}>修改</a>) : null
                        }
                        {record.status === 0 ? (
                            this.props.userPermission != null && this.props.userPermission.includes('300117') ? (
                                <a onClick={this.changeTravelStatus.bind(this, record, 1)}
                                   style={{'color': '#f5ce0a'}}>禁用</a>) : null
                        ) : (
                            this.props.userPermission != null && this.props.userPermission.includes('300118') ? (
                                <a onClick={this.changeTravelStatus.bind(this, record, 0)}
                                   style={{'color': '#f5ce0a'}}>启用</a>) : null
                        )
                        }
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300119') ? (
                                <a onClick={this.deleteTravel.bind(this, record)}
                                   style={{'color': '#d43f3a'}}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item
                        label="出行名称"
                        name="name"
                        style={{width: 270}}>
                        <Input placeholder="请输入出行名称"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="状态"
                        name="status"
                        style={{width: 150}}>
                        <Select onChange={this.statusChange}>
                            <Select.Option value="0">启用中</Select.Option>
                            <Select.Option value="1">禁用中</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remarks"
                        style={{width: 270}}>
                        <Input placeholder="请输入备注"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.searchTravel}
                                style={{'marginLeft': '10px'}}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300115') ? (
                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.openAddTravelModal}>新增</Button>) : null
                        }

                    </Form.Item>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       scroll={{x: 1500}}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            新增
                        </div>
                    }
                    width={800}
                    visible={this.state.addVisible}
                    onOk={this.addTravel}
                    onCancel={this.handleCancel}>
                    <Form
                        ref={this.addFormRef}>
                        <Form.Item
                            label="出行名称"
                            name="name"
                            rules={[{required: true, message: '请输入出行名称'}]}>
                            <Input/>
                        </Form.Item>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="出行价格"
                                    name="price"
                                    rules={[{required: true, message: '请输入出行价格'}]}>
                                    <InputNumber style={{'width': '169px'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="出行人数"
                                    name="numberOfTrips"
                                    rules={[{required: true, message: '请输入出行人数'}]}>
                                    <InputNumber style={{'width': '169px'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="推荐等级"
                                    name="grade"
                                    rules={[{required: true, message: '请输入等级'}]}>
                                    <InputNumber min={1} max={10} style={{'width': '169px'}}
                                                 placeholder="数字越高，排序越前"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="产品标签"
                                   name="label"
                                   rules={[{required: false, message: '请输入标签'}]}>
                            <Input placeholder="多个标签用英文;号隔开"/>
                        </Form.Item>
                        <Form.Item
                            label="产品介绍"
                            name="introduce"
                            rules={[{required: true, message: '请输入产品介绍'}]}>
                            <TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="产品特色"
                                   name="characteristic">
                            <div className='rich-text-field' ref={this.editorRef}></div>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="产品备注"
                                   name="remarks">
                            <TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="首页图片">
                            <Upload
                                name="iconFile"
                                accept=".png,.jpg,.jpeg"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.fileChange}
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}>
                                <Button type="primary">
                                    选择文件
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            修改
                        </div>
                    }
                    width={800}
                    visible={this.state.editVisible}
                    onOk={this.updateTravel}
                    onCancel={this.handleCancel}>
                    <Form
                        ref={this.editFormRef}>
                        <Form.Item
                            label="行程ID"
                            name="id"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="出行名称"
                            name="name"
                            rules={[{required: true, message: '请输入出行名称'}]}>
                            <Input/>
                        </Form.Item>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="出行价格"
                                    name="price"
                                    rules={[{required: true, message: '请输入出行价格'}]}>
                                    <InputNumber style={{'width': '169px'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="出行人数"
                                    name="numberOfTrips"
                                    rules={[{required: true, message: '请输入出行人数'}]}>
                                    <InputNumber style={{'width': '169px'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="推荐等级"
                                    name="grade"
                                    rules={[{required: true, message: '请输入等级'}]}>
                                    <InputNumber min={1} max={10} style={{'width': '169px'}}
                                                 placeholder="数字越高，排序越前"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="产品标签"
                                   name="label"
                                   rules={[{required: false, message: '请输入标签'}]}>
                            <Input placeholder="多个标签用英文;号隔开"/>
                        </Form.Item>
                        <Form.Item
                            label="产品介绍"
                            name="introduce"
                            rules={[{required: true, message: '请输入产品介绍'}]}>
                            <TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="产品特色"
                                   name="characteristic">
                            <div className='rich-text-field' ref={this.editorRef}></div>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="产品备注"
                                   name="remarks">
                            <TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="首页图片">
                            <Upload
                                name="iconFile"
                                accept=".png,.jpg,.jpeg"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.fileChange}
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}>
                                <Button type="primary">
                                    选择文件
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            查看
                        </div>
                    }
                    width={800}
                    visible={this.state.viewVisible}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}>
                    <Form
                        ref={this.viewFormRef}>
                        <Form.Item
                            label="出行名称"
                            name="name"
                            rules={[{required: true, message: '请输入出行名称'}]}>
                            <Input disabled/>
                        </Form.Item>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="出行价格"
                                    name="price"
                                    rules={[{required: true, message: '请输入出行价格'}]}>
                                    <InputNumber style={{'width': '169px'}} disabled/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="出行人数"
                                    name="numberOfTrips"
                                    rules={[{required: true, message: '请输入出行人数'}]}>
                                    <InputNumber style={{'width': '169px'}} disabled/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="推荐等级"
                                    name="grade"
                                    rules={[{required: true, message: '请输入等级'}]}>
                                    <InputNumber min={1} max={10} style={{'width': '169px'}}
                                                 placeholder="数字越高，排序越前" disabled/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="产品标签"
                                   name="label"
                                   rules={[{required: false, message: '请输入标签'}]}>
                            <Input placeholder="多个标签用英文;号隔开" disabled/>
                        </Form.Item>
                        <Form.Item
                            label="产品介绍"
                            name="introduce"
                            rules={[{required: true, message: '请输入产品介绍'}]}>
                            <TextArea rows={4} disabled/>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="产品特色"
                                   name="characteristic">
                            <div className='rich-text-field' ref={this.editorRef}></div>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="产品备注"
                                   name="remarks">
                            <TextArea rows={4} disabled/>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="首页图片">
                            <Image
                                width={50}
                                height={65}
                                src={this.state.icon}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}