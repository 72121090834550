import React from 'react';
import style from "../Content.module.css";
import {Button, Form, message, Row, DatePicker, Select, Table, Space, Input, Image, Col, Modal, Checkbox} from "antd";
import "../../../utils/date"
import 'moment/locale/zh-cn';
import moment from 'moment';
import zh_CN from "antd/es/date-picker/locale/zh_CN";
import Axios from "../../../utils/axios";
import Util from "../../../utils/utils";

moment.locale('zh-cn');

const {TextArea} = Input;
const {RangePicker} = DatePicker;
let userRole = null;
let userName = null;

class WithdrawalInfo extends React.Component {
    viewFormRef = React.createRef();
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        withdrawalStartTime: '',
        withdrawalEndTime: '',
        status: '',
        type: '',
        userName: '',
        realName: '',
        viewVisible: false,
        alipayImg: null,
        wechatImg: null,
        selectedRowKeys: [],
        isExporting: false,
        userPermission: null,
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
    }

    constructor(props) {
        super(props);
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.getUserPermission();
        }
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: '/menu/getUserMenu/' + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: '/withdrawal/listWithdrawalIds',
                params: {
                    userName: userRole === 0 ? '' : userName,
                    realName: this.state.realName,
                    status: this.state.status,
                    type: this.state.type,
                    withdrawalStartTime: this.state.withdrawalStartTime,
                    withdrawalEndTime: this.state.withdrawalEndTime,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    initData = () => {
        Axios({
            method: 'get',
            url: '/withdrawal/listWithdrawal',
            params: {
                userName: userRole === 0 ? '' : userName,
                realName: this.state.realName,
                status: this.state.status,
                type: this.state.type,
                withdrawalStartTime: this.state.withdrawalStartTime,
                withdrawalEndTime: this.state.withdrawalEndTime,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchWithdrawalInfo = () => {
        this.initData();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleWithdrawalSelectTime = (value, dateString) => {
        this.setState({
            withdrawalStartTime: dateString[0],
            withdrawalEndTime: dateString[1],
        })
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    typeChange = (value) => {
        this.setState({
            type: value,
        })
    }
    resetSearch = () => {
        this.setState({
            userName: '',
            withdrawalStartTime: '',
            withdrawalEndTime: '',
            status: '',
            type: '',
            realName: '',
        })
    }
    handleCancel = () => {
        this.setState({
            viewVisible: false,
            alipayImg: null,
            wechatImg: null,
        });
    }
    showWithdrawalInfo = (record) => {
        this.setState({
            viewVisible: true,
            alipayImg: record.alipayImg,
            wechatImg: record.wechatImg,
        });
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.viewFormRef.current.setFieldsValue(record);
            this.viewFormRef.current.setFieldsValue({
                'status': record.status.toString(),
                'type': record.type.toString(),
            });
        }, 100);
    }
    showPass = (record) => {
        Modal.confirm({
            title: '审核',
            content: "确定通过？",
            onOk: () => {
                Axios({
                    method: 'post',
                    url: '/withdrawal/examineWithdrawal',
                    params: {
                        id: record.id,
                        status: 0,
                        userName: record.userName,
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    showNoPass = (record) => {
        Modal.confirm({
            title: '审核',
            content: "确定不通过？",
            onOk: () => {
                Axios({
                    method: 'post',
                    url: '/withdrawal/examineWithdrawal',
                    params: {
                        id: record.id,
                        status: 2,
                        userName: record.userName,
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    exportWithdrawalInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: '/withdrawal/exportWithdrawalInfo/' + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '提现数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll,
        };
        const columns = [
            {
                title: '账户',
                dataIndex: 'userName',
                key: 'userName',
                fixed: 'left',
                width: 120,
            },
            {
                title: '姓名',
                dataIndex: 'realName',
                key: 'realName',
                fixed: 'left',
                width: 120,
            },
            {
                title: '电话',
                dataIndex: 'tel',
                key: 'tel',
                width: 120,
            },
            {
                title: '提现时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 170,
            },
            {
                title: '提现积分',
                dataIndex: 'integral',
                key: 'integral',
            },
            {
                title: '提现方式',
                dataIndex: 'type',
                key: 'type',
                render: type => {
                    switch (type) {
                        case 0:
                            return '银行卡';
                        case 1:
                            return '支付宝';
                        case 2:
                            return '微信';
                        default:
                            return '银行卡';
                    }
                }
            },
            {
                title: '银行名称',
                dataIndex: 'bank',
                key: 'bank',
                width: 200,
                ellipsis: true,
            },
            {
                title: '银行卡号',
                dataIndex: 'bankCardNumber',
                key: 'bankCardNumber',
                width: 200,
            },
            {
                title: '银行户名',
                dataIndex: 'bankUserName',
                key: 'bankUserName',
            },
            {
                title: '支付宝收款码',
                dataIndex: 'alipayImg',
                key: 'alipayImg',
                width: 100,
                render: alipayImg => {
                    if (!Util.isEmpty(alipayImg)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={alipayImg}/>
                        )
                    }
                }
            },
            {
                title: '微信收款码',
                dataIndex: 'wechatImg',
                key: 'wechatImg',
                width: 100,
                render: wechatImg => {
                    if (!Util.isEmpty(wechatImg)) {
                        return (<Image
                                width={15}
                                height={20}
                                src={wechatImg}/>
                        )
                    }
                }
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                width: 200,
                ellipsis: true,
            },
            {
                title: '提现状态',
                dataIndex: 'status',
                key: 'status',
                fixed: 'right',
                width: 100,
                render: status => {
                    switch (status) {
                        case 0:
                            return '已提现';
                        case 1:
                            return '申请中';
                        case 2:
                            return '审核不通过';
                        default:
                            return '审核不通过';
                    }
                }
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        {this.state.userPermission != null && this.state.userPermission.includes('300044') ? (
                            <a onClick={this.showWithdrawalInfo.bind(this, record)}>查看</a>) : null
                        }
                        {(userRole === 0 && record.status === 1 && this.state.userPermission != null && this.state.userPermission.includes('300045')) ? (
                            <a onClick={this.showPass.bind(this, record)}>通过</a>) : null}
                        {(userRole === 0 && record.status === 1 && this.state.userPermission != null && this.state.userPermission.includes('300046')) ? (
                            <a onClick={this.showNoPass.bind(this, record)}>不通过</a>) : null}
                    </Space>
                ),
            }
        ]
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Form layout='inline'>
                        <Form.Item
                            label="账户"
                            name="userName"
                            style={{width: 200, marginTop: '5px', marginBottom: '5px'}}
                            hidden={userRole === 2 ? true : false}>
                            <Input placeholder="请输入账户"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="姓名"
                            name="realName"
                            style={{width: 200, marginTop: '5px', marginBottom: '5px'}}
                            hidden={userRole === 2 ? true : false}>
                            <Input placeholder="请输入姓名"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="提现时间"
                            name="withdrawalTime"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <RangePicker locale={zh_CN} onChange={this.handleWithdrawalSelectTime}/>
                        </Form.Item>
                        <Form.Item
                            label="提现状态"
                            name="status"
                            style={{width: 200, marginTop: '5px', marginBottom: '5px'}}>
                            <Select onChange={this.statusChange}>
                                <Select.Option value="0">已提现</Select.Option>
                                <Select.Option value="1">申请中</Select.Option>
                                <Select.Option value="2">审核不通过</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="提现方式"
                            name="type"
                            style={{width: 200, marginTop: '5px', marginBottom: '5px'}}>
                            <Select onChange={this.typeChange}>
                                <Select.Option value="0">银行卡</Select.Option>
                                <Select.Option value="1">支付宝</Select.Option>
                                <Select.Option value="2">微信</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <Button type="primary" onClick={this.searchWithdrawalInfo}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {
                                this.state.userPermission != null && this.state.userPermission.includes('300043') ? (
                                    <Button type="primary" style={{'marginLeft': '5px'}}
                                            onClick={this.exportWithdrawalInfo}>导出</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                查看
                            </div>
                        }
                        visible={this.state.viewVisible}
                        onOk={this.handleCancel}
                        destroyOnClose={true}
                        onCancel={this.handleCancel}>
                        <Form
                            ref={this.viewFormRef}>
                            <Row>
                                <Col span={11}>
                                    <Form.Item
                                        label="团队账户"
                                        name="userName">
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                                <Col span={11} style={{'marginLeft': '30px'}}>
                                    <Form.Item
                                        label="团队姓名"
                                        name="realName">
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={11}>
                                    <Form.Item
                                        label="团队电话"
                                        name="tel">
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                                <Col span={11} style={{'marginLeft': '30px'}}>
                                    <Form.Item
                                        label="提现积分"
                                        name="integral">
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={11}>
                                    <Form.Item
                                        label="提现状态"
                                        name="status">
                                        <Select disabled>
                                            <Select.Option value="0">已提现</Select.Option>
                                            <Select.Option value="1">申请中</Select.Option>
                                            <Select.Option value="2">审核不通过</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11} style={{'marginLeft': '30px'}}>
                                    <Form.Item
                                        label="提现方式"
                                        name="type">
                                        <Select disabled>
                                            <Select.Option value="0">银行卡</Select.Option>
                                            <Select.Option value="1">支付宝</Select.Option>
                                            <Select.Option value="2">微信</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label="提现时间"
                                name="gmtCreate"
                                style={{"width": "463px"}}>
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="银行名称"
                                name="bank"
                                style={{"width": "463px"}}>
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="银行卡号"
                                name="bankCardNumber"
                                style={{"width": "463px"}}>
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="银行户名"
                                name="bankUserName"
                                style={{"width": "463px"}}>
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="提现备注"
                                name="remarks"
                                style={{"width": "463px"}}>
                                <TextArea disabled rows={4}/>
                            </Form.Item>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="支付宝付款码"
                                        name="alipayImg">
                                        {
                                            Util.isEmpty(this.state.alipayImg) ? <div></div> :
                                                <Image
                                                    width={230}
                                                    height={250}
                                                    src={this.state.alipayImg}/>
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="微信付款码"
                                        name="wechatImg">
                                        {
                                            Util.isEmpty(this.state.wechatImg) ? <div></div> :
                                                <Image
                                                    width={230}
                                                    height={250}
                                                    src={this.state.wechatImg}/>
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.data}
                           rowKey={columns => columns.id}
                           rowSelection={rowSelection}
                           loading={this.state.loading}
                           style={{'marginTop': '20px'}}
                           scroll={{x: 2000}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                    <div style={{
                        marginTop: "-38px",
                        marginLeft: "8px",
                        display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                    }}>
                        < Checkbox onChange={this.exportAllSelect}
                                   checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                    </div>
                </div>
            </div>
        )
    }
}

export default WithdrawalInfo;