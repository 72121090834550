import React from 'react'
import {Button, Checkbox, Form, Image, Input, message, Modal, Select, Space, Table, Upload} from "antd";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import TravelUserInfo from "./TravelUserInfo";

let userName = null;
let userRole = null;
export default class TravelOrderManage extends React.Component {
    viewFormRef = React.createRef();
    editFormRef = React.createRef();
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        viewVisible: false,
        editVisible: false,
        invitationCodeVisible: false,
        invitationCode: null,
        travelName: null,
        travelLabel: null,
        invitationUserRealName: null,
        travelOrderId: null,
        status: null,
        remarks: null,
        iconFile: null,
        fileList: [],
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
        selectedRowKeys: [],
        isExporting: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        userName = JSON.parse(window.sessionStorage.getItem("userName"));
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.TRAVEL_ORDER.LIST_TRAVEL_ORDER_INFO_URL,
            params: {
                invitationUserRealName: this.state.invitationUserRealName,
                travelName: this.state.travelName,
                travelLabel: this.state.travelLabel,
                status: this.state.status,
                remarks: this.state.remarks,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            invitationUserRealName: null,
            travelName: null,
            travelLabel: null,
            status: null,
            remarks: null,
        });
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    handleCancel = () => {
        this.setState({
            viewVisible: false,
            editVisible: false,
            invitationCodeVisible: false,
        });
    }
    searchTravelOrder = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    deleteTravelOrder = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该订单？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.TRAVEL_ORDER.DELETE_TRAVEL_ORDER_INFO_URL + "/" + record.id,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    /**
     * 取消出行订单
     * @param record
     */
    cancelTravelOrder = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认取消该出行订单？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: PATH_URL.TRAVEL_ORDER.CANCEL_TRAVEL_ORDER_INFO_URL + "/" + record.id,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    /**
     * 置为已出行
     * @param record
     */
    traveledTravelOrder = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认置为已出行？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: PATH_URL.TRAVEL_ORDER.TRAVELED_TRAVEL_ORDER_INFO_URL + "/" + record.id,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    showUploadPaymentVoucherModel = (record) => {
        this.setState({
            editVisible: true,
        })
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
        }, 100);
    }
    /**
     * 查看出行人
     * @param record
     */
    showTravelUserInfoModel = (record) => {
        this.setState({
            travelOrderId: record.id,
            viewVisible: true,
        });
    }
    /**
     * 上传付款凭证
     * @param record
     */
    uploadPaymentVoucher = () => {
        this.editFormRef.current.validateFields().then(values => {
            if (values.iconFile === 'undefined') {
                message.error("请输入首页图片！");
                return false;
            } else {
                let formData = new FormData();
                formData.append('travelOrderDTO', new Blob([JSON.stringify(values)], {type: "application/json"}));
                formData.append('iconFile', this.state.iconFile);
                Axios({
                    method: 'post',
                    url: PATH_URL.TRAVEL_ORDER.UPLOAD_PAYMENT_VOUCHER_URL,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData,
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.setState({
                                loading: true,
                                editVisible: false,
                                iconFile: null,
                                fileList: [],
                            });
                            this.initData();
                            this.editFormRef.current.resetFields();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        }).catch(info => {
            console.log(info);
        });
    }
    fileChange = (e) => {
        this.setState({
            iconFile: (e.fileList.length > 0 && (e.file.size / (1024 * 1024) < 10)) ? e.fileList[0].originFileObj : null,
        })
    }
    beforeUpload = (file, fileList) => {
        if (file.size / (1024 * 1024) >= 10) {
            message.error("文件大小不能超过10M！", 2);
            this.setState({
                fileList: [],
                iconFile: null
            })
        } else {
            this.setState({
                fileList: fileList,
                iconFile: file
            })
        }
        return false;
    }
    onRemove = () => {
        this.setState({
            fileList: []
        })
    }
    showInvitationCode = () => {
        Axios({
            method: 'get',
            url: PATH_URL.TRAVEL.GET_TRAVEL_USER_INVITATION_CODE_URL + "/" + userName,
        }).then(res => {
            if (res.data != null) {
                this.setState({
                    invitationCodeVisible: true,
                    invitationCode: res.data.data,
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: PATH_URL.TRAVEL_ORDER.LIST_TRAVEL_ORDER_NO_PAGE_INFO_URL,
                params: {
                    invitationUserRealName: this.state.invitationUserRealName,
                    travelName: this.state.travelName,
                    travelLabel: this.state.travelLabel,
                    status: this.state.status,
                    remarks: this.state.remarks,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    export = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: PATH_URL.TRAVEL_ORDER.EXPORT_TRAVEL_ORDER_INFO_URL + "/" + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '旅游报名订单数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll
        };
        const columns = [
            {
                title: '出行名称',
                dataIndex: 'travelName',
                key: 'travelName',
                fixed: 'left',
                width: 150,
                ellipsis: true,
            },
            {
                title: '产品标签',
                dataIndex: 'travelLabel',
                key: 'travelLabel',
                width: 150,
                ellipsis: true,
            },
            {
                title: '出行时间',
                dataIndex: 'travelDate',
                key: 'travelDate',
                width: 120,
            },
            {
                title: '邀请人',
                dataIndex: 'invitationUserRealName',
                key: 'invitationUserRealName',
                width: 120,
            },
            {
                title: '订单总价格',
                dataIndex: 'price',
                key: 'price',
                width: 100,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    switch (status) {
                        case 0:
                            return '未付款';
                        case 1:
                            return '已付款';
                        case 2:
                            return '已出行';
                        case 3:
                            return '已取消';
                        default:
                            return '未付款';
                    }
                }
            },
            {
                title: '付款凭证',
                dataIndex: 'icon',
                key: 'icon',
                render: icon => {
                    if (!Util.isEmpty(icon)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={icon}/>
                        )
                    }
                }
            },
            {
                title: '创建人',
                dataIndex: 'creatorRealName',
                key: 'creatorRealName',
                width: 120,
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 180
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                width: 200,
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                width: 330,
                render: (text, record) => (
                    <Space size="small">
                        {
                            (userRole === 0 && this.props.userPermission != null && this.props.userPermission.includes('300120')) ? (
                                <a onClick={this.showTravelUserInfoModel.bind(this, record)}>查看出行人</a>
                            ) : (userRole !== 0 ? (
                                <a onClick={this.showTravelUserInfoModel.bind(this, record)}>查看出行人</a>) : null)
                        }
                        {
                            (userRole === 0 && (record.status === 0 || record.status === 1) && this.props.userPermission != null && this.props.userPermission.includes('300121')) ? (
                                <a onClick={this.showUploadPaymentVoucherModel.bind(this, record)}>上传付款凭证</a>) : (userRole !== 0 && (record.status === 0 || record.status === 1) ?
                                (<a onClick={this.showUploadPaymentVoucherModel.bind(this, record)}>上传付款凭证</a>) : null)
                        }
                        {
                            (record.status === 1 && userRole === 0 && this.props.userPermission != null && this.props.userPermission.includes('300122')) ? (
                                <a onClick={this.traveledTravelOrder.bind(this, record)}
                                   style={{'color': '#f5ce0a'}}>置为已出行</a>) : null
                        }
                        {
                            (record.status === 0 || record.status === 1) ? (
                                (userRole === 0 && this.props.userPermission != null && this.props.userPermission.includes('300123')) ? (
                                    <a onClick={this.cancelTravelOrder.bind(this, record)}
                                       style={{'color': '#f5ce0a'}}>取消</a>) : (userRole !== 0 ? (
                                    <a onClick={this.cancelTravelOrder.bind(this, record)}
                                       style={{'color': '#f5ce0a'}}>取消</a>) : null)
                            ) : null
                        }
                        {
                            userRole === 0 && this.props.userPermission != null && this.props.userPermission.includes('300124') ? (
                                <a onClick={this.deleteTravelOrder.bind(this, record)}
                                   style={{'color': '#d43f3a'}}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item
                        label="出行名称"
                        name="travelName"
                        style={{width: 250}}>
                        <Input placeholder="请输入出行名称"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="产品标签"
                        name="travelLabel"
                        style={{width: 250}}>
                        <Input placeholder="请输入产品标签"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="邀请人"
                        name="invitationUserRealName"
                        style={{width: 200}}>
                        <Input placeholder="请输入邀请人"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="状态"
                        name="status"
                        style={{width: 130}}>
                        <Select onChange={this.statusChange}>
                            <Select.Option value="0">未付款</Select.Option>
                            <Select.Option value="1">已付款</Select.Option>
                            <Select.Option value="2">已出行</Select.Option>
                            <Select.Option value="3">已取消</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remarks"
                        style={{width: 230}}>
                        <Input placeholder="请输入备注"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item style={{marginTop:"10px"}}>
                        <Button type="primary" onClick={this.searchTravelOrder}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                        <Button type="primary" style={{'marginLeft': '10px'}}
                                onClick={this.showInvitationCode}>我的邀请码</Button>
                        {
                            (userRole === 8 || (userRole === 0 && this.props.userPermission != null && this.props.userPermission.includes('300125'))) ? (

                                <Button type="primary" style={{'marginLeft': '10px'}}
                                        onClick={this.export}>导出</Button>) : null
                        }
                    </Form.Item>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       rowSelection={rowSelection}
                       loading={this.state.loading}
                       scroll={{x: 2000}}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            上传付款凭证
                        </div>
                    }
                    width={500}
                    visible={this.state.editVisible}
                    onOk={this.uploadPaymentVoucher}
                    onCancel={this.handleCancel}>
                    <Form
                        ref={this.editFormRef}>
                        <Form.Item
                            label="订单ID"
                            name="id"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item style={{'marginLeft': '11px'}}
                                   label="付款凭证">
                            <Upload
                                name="iconFile"
                                accept=".png,.jpg,.jpeg"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.fileChange}
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}>
                                <Button type="primary">
                                    选择文件
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            查看出行人
                        </div>
                    }
                    visible={this.state.viewVisible}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    width={1000}>
                    <TravelUserInfo travelOrderId={this.state.travelOrderId}
                                    userPermission={this.props.userPermission}/>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            我的邀请码
                        </div>
                    }
                    visible={this.state.invitationCodeVisible}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}>
                    <Form>
                        <Form.Item
                            label="邀请码">
                            <Input bordered={false} disabled value={this.state.invitationCode}/>
                        </Form.Item>
                    </Form>
                </Modal>
                <div style={{
                    marginTop: "-38px",
                    marginLeft: "8px",
                    display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                }}>
                    <Checkbox onChange={this.exportAllSelect}
                              checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                </div>
            </div>
        )
    }
}