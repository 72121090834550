import React from 'react'
import style from "../Content.module.css";
import {Table, Space, Input, Form, Button, message, Modal, Select, Checkbox, DatePicker} from "antd";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import zh_CN from "antd/es/date-picker/locale/zh_CN";

const {RangePicker} = DatePicker;
let userRole = null;
let userName = null;

class SubstituteDriving extends React.Component {
    state = {
        userName: null,
        realName: null,
        tel: null,
        source: null,
        pickUpStartTime: null,
        pickUpEndTime: null,
        gmtCreateStartTime: null,
        gmtCreateEndTime: null,
        licensePlate: null,
        status: null,
        sponsorRealName: null,
        sponsor: null,
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        userPermission: null,
        isExporting: false,
        selectedRowKeys: [],
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        userName = JSON.parse(window.sessionStorage.getItem("userName"));
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.getUserPermission();
        }
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: PATH_URL.GET_USER_MENU_URL + "/" + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_SUBSTITUTE_DRIVING_URL,
            params: {
                sponsor: userRole === 0 ? this.state.sponsor : userName,
                userName: this.state.userName,
                realName: this.state.realName,
                tel: this.state.tel,
                sponsorRealName: userRole === 0 ? this.state.sponsorRealName : null,
                source: this.state.source,
                pickUpStartTime: this.state.pickUpStartTime,
                pickUpEndTime: this.state.pickUpEndTime,
                gmtCreateStartTime: this.state.gmtCreateStartTime,
                gmtCreateEndTime: this.state.gmtCreateEndTime,
                licensePlate: this.state.licensePlate,
                status: this.state.status,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchSubstituteDriving = () => {
        this.setState({
            loading: true,
        })
        this.initData();
    }
    resetSearch = () => {
        this.setState({
            userName: null,
            realName: null,
            tel: null,
            source: null,
            sponsorRealName: null,
            sponsor: userRole === 0 ? null : userName,
            pickUpStartTime: null,
            pickUpEndTime: null,
            gmtCreateStartTime: null,
            gmtCreateEndTime: null,
            licensePlate: null,
            status: null,
        })
    }
    handlePickUpTime = (value, dateString) => {
        this.setState({
            pickUpStartTime: dateString[0],
            pickUpEndTime: dateString[1],
        })
    }
    handleGmtCreateTime = (value, dateString) => {
        this.setState({
            gmtCreateStartTime: dateString[0],
            gmtCreateEndTime: dateString[1],
        })
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    sourceChange = (value) => {
        this.setState({
            source: value,
        })
    }
    completeSubstituteDriving = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认完成代驾信息？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: PATH_URL.COMPLETE_SUBSTITUTE_DRIVING_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    cancelSubstituteDriving = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认取消代驾信息？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: PATH_URL.CANCEL_SUBSTITUTE_DRIVING_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    showDeleteSubstituteDriving = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除代驾信息？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.DELETE_SUBSTITUTE_DRIVING_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    exportSubstituteDrivingInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: PATH_URL.EXPORT_SUBSTITUTE_DRIVING_INFO_URL + "/" + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '代驾信息.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: PATH_URL.LIST_SUBSTITUTE_DRIVING_IDS_URL,
                params: {
                    userName: this.state.userName,
                    realName: this.state.realName,
                    tel: this.state.tel,
                    sponsorRealName: this.state.sponsorRealName,
                    sponsor: this.state.sponsor,
                    source: this.state.source,
                    pickUpStartTime: this.state.pickUpStartTime,
                    pickUpEndTime: this.state.pickUpEndTime,
                    gmtCreateStartTime: this.state.gmtCreateStartTime,
                    gmtCreateEndTime: this.state.gmtCreateEndTime,
                    licensePlate: this.state.licensePlate,
                    status: this.state.status,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll,
        };
        const columns = [
            {
                title: '用户',
                dataIndex: 'userName',
                key: 'userName',
                fixed: 'left',
                ellipsis: true,
            },
            {
                title: '姓名',
                dataIndex: 'realName',
                key: 'realName',
                fixed: 'left',
                ellipsis: true,
            },
            {
                title: '联系地址',
                dataIndex: 'address',
                key: 'address',
                ellipsis: true,
            },
            {
                title: '联系电话',
                dataIndex: 'tel',
                key: 'tel',
            },
            {
                title: '车牌',
                dataIndex: 'licensePlate',
                key: 'licensePlate',
            },
            {
                title: '预约人',
                dataIndex: 'sponsorRealName',
                key: 'sponsorRealName',
                ellipsis: true,
            },
            {
                title: '预约账户',
                dataIndex: 'sponsor',
                key: 'sponsor',
            },
            {
                title: '预约渠道',
                dataIndex: 'source',
                key: 'source',
                render: source => {
                    switch (source) {
                        case 1:
                            return '线上电呼';
                        case 2:
                            return '业务团队';
                        case 4:
                            return '车主';
                        case 5:
                            return '大客户';
                        default:
                            return '未知';
                    }
                }
            },
            {
                title: '取车时间',
                dataIndex: 'pickUpTime',
                key: 'pickUpTime',
                width: 165,
            },
            {
                title: '车辆注册时间',
                dataIndex: 'registrationTime',
                key: 'registrationTime',
                width: 165,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    switch (status) {
                        case 0:
                            return '待分配';
                        case 1:
                            return '已完成';
                        case 2:
                            return '已取消';
                        default:
                            return '待分配';
                    }
                }
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 165,
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                width: 120,
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        {
                            record.status === 0 && userRole === 0 && this.state.userPermission != null && this.state.userPermission.includes('300103') ? (
                                <a onClick={this.completeSubstituteDriving.bind(this, record, 0)}>完成</a>) :null
                        }
                        {
                            record.status === 0 && userRole === 0 && this.state.userPermission != null && this.state.userPermission.includes('300104') ? (
                                <a style={{'color': '#f5ce0a'}}
                                   onClick={this.cancelSubstituteDriving.bind(this, record, 0)}>取消</a>) : (record.status === 0 && userRole !== 0 ? (
                                <a style={{'color': '#f5ce0a'}}
                                   onClick={this.cancelSubstituteDriving.bind(this, record, 0)}>取消</a>) : null)
                        }
                        {
                            record.status === 2 && this.state.userPermission != null && this.state.userPermission.includes('300105') ? (
                                <a style={{'color': '#d43f3a'}}
                                   onClick={this.showDeleteSubstituteDriving.bind(this, record)}>删除</a>) : (record.status === 2 && userRole !== 0 ? (
                                <a style={{'color': '#d43f3a'}}
                                   onClick={this.showDeleteSubstituteDriving.bind(this, record)}>删除</a>) : null)
                        }

                    </Space>
                ),
            },
        ];
        if (userRole !== 0) {
            columns.splice(6, 3)
        }
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Form layout='inline'>
                        <Form.Item
                            label="取车时间"
                            name="pickUpTime"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <RangePicker locale={zh_CN} showTime onChange={this.handlePickUpTime}/>
                        </Form.Item>
                        <Form.Item
                            label="创建时间"
                            name="gmtCreate"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <RangePicker locale={zh_CN} showTime onChange={this.handleGmtCreateTime}/>
                        </Form.Item>
                        <Form.Item
                            label="用户账户"
                            name="userName"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入用户名称"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="用户姓名"
                            name="realName"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入用户姓名"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        {
                            userRole !== 0 ? null : (
                                <Form.Item
                                    label="预约人"
                                    name="sponsorRealName"
                                    style={{marginTop: '5px', marginBottom: '5px'}}>
                                    <Input placeholder="请输入预约人"
                                           onChange={this.inputChange}/>
                                </Form.Item>)
                        }
                        {
                            userRole !== 0 ? null : (
                                <Form.Item
                                    label="预约账户"
                                    name="sponsor"
                                    style={{marginTop: '5px', marginBottom: '5px'}}>
                                    <Input placeholder="请输入预约账户"
                                           onChange={this.inputChange}/>
                                </Form.Item>)
                        }
                        {
                            userRole !== 0 ? null : (
                                <Form.Item
                                    label="预约渠道"
                                    name="source"
                                    style={{marginTop: '5px', marginBottom: '5px', width: 180}}>
                                    <Select onChange={this.sourceChange}>
                                        <Select.Option value="1">线上电呼</Select.Option>
                                        <Select.Option value="2">业务团队</Select.Option>
                                        <Select.Option value="4">车主</Select.Option>
                                        <Select.Option value="5">大客户</Select.Option>
                                    </Select>
                                </Form.Item>)
                        }

                        <Form.Item
                            label="联系电话"
                            name="tel"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入联系电话"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="车牌"
                            name="licensePlate"
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入车牌"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="status"
                            style={{marginTop: '5px', marginBottom: '5px', width: 120}}>
                            <Select onChange={this.statusChange}>
                                <Select.Option value="0">待分配</Select.Option>
                                <Select.Option value="1">已完成</Select.Option>
                                <Select.Option value="2">已取消</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{marginTop: '5px', marginBottom: '5px'}}>
                            <Button type="primary" onClick={this.searchSubstituteDriving}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {userRole === 0 && this.state.userPermission != null && this.state.userPermission.includes('300108') ? (
                                <Button type="primary" style={{'marginLeft': '5px'}}
                                        onClick={this.exportSubstituteDrivingInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.data}
                           rowKey={columns => columns.id}
                           rowSelection={userRole === 0 ? rowSelection : null}
                           loading={this.state.loading}
                           style={{'marginTop': '20px'}}
                           scroll={{x: 2000}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                    {
                        userRole === 0 ? (<div style={{
                            marginTop: "-38px",
                            marginLeft: "8px",
                            display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                        }}>
                            < Checkbox onChange={this.exportAllSelect}
                                       checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                        </div>) : null
                    }
                </div>
            </div>
        )
    }
}

export default SubstituteDriving;