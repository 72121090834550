import Axios from "./axios";
import {message} from "antd";

const PermissionUtils = {
    getUserPermission: function (_this,menuCode) {
        Axios({
            method: 'get',
            url: '/menu/getUserMenu/' + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                if (!res.data.data.includes(menuCode)) {
                    message.error("你没有权限访问!");
                    _this.props.history.push("/home");
                }
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
}
export default PermissionUtils;