import React from 'react';
import style from './Header.module.css'
import {message} from "antd";
import Axios from "../../utils/axios";
import {Link} from "react-router-dom";


class Header extends React.Component {

    loginOut = () => {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        Axios({
            method: 'post',
            url: '/loginOut',
            data: {
                userName: userName
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    window.sessionStorage.setItem("token", res.data.data.token);
                    window.sessionStorage.setItem("userName", res.data.data.userName);
                    this.props.history.push("/login");
                } else {
                    message.error(res.data.message);
                }
            } else {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    render() {
        return (
            <div className={style['header']}>
                <span className={style['title']}>JWS车主空间</span>
                <span className={style['login_out']} onClick={this.loginOut}>登出</span>
                <Link to={"/setting"}><span className={style['setting']}>个人设置</span></Link>
            </div>
        )
    }
}

export default Header