import React from 'react'
import {Button, Form, Input, message, Modal, Space, Table} from "antd";
import Util from "../../../utils/utils";
import RollCirculationRecordModel from "./RollCirculationRecordModel";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import {ExclamationCircleOutlined} from "@ant-design/icons";

let userRole;

class TransferRecord extends React.Component {
    state = {
        rollName: null,
        rollNumber: null,
        outUser: null,
        inUser: null,
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        viewRollNumber: null,
        visible: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_TURN_TO_INCREASE_RECORD_URL,
            params: {
                rollName: this.state.rollName,
                rollNumber: this.state.rollNumber,
                inUser: this.state.inUser,
                outUser: userRole !== 0 ? this.props.userName : this.state.outUser,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    searchTransferRecord = () => {
        this.setState({
            loading: true,
        })
        this.initData();
    }
    resetSearch = () => {
        this.setState({
            rollName: null,
            rollNumber: null,
            outUser: null,
            inUser: null,
        });
    }
    showRollCirculationRecord = (e) => {
        this.setState({
            viewRollNumber: e.target.innerText,
            visible: true,
        })

    }
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }
    showDeleteTransferRecord = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该转赠记录？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.DELETE_TURN_TO_INCREASE_RECORD_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }

    render() {
        const columns = [
            {
                title: '卷编号',
                dataIndex: 'rollNumber',
                key: 'rollNumber',
                width: 350,
                ellipsis: true,
                render: rollNumber => {
                    return (<a onClick={this.showRollCirculationRecord.bind(this)}>{rollNumber}</a>)
                }
            },
            {
                title: '卷名称',
                dataIndex: 'rollName',
                key: 'rollName',
                width: 300,
                ellipsis: true,
            },
            {
                title: '转入账户',
                dataIndex: 'inUser',
                key: 'inUser',
            },
            {
                title: '转出账户',
                dataIndex: 'outUser',
                key: 'outUser',
            },
            {
                title: '卷过期时间',
                dataIndex: 'expirationDate',
                key: 'expirationDate',
                width: 100,
                render: expirationDate => {
                    if (expirationDate != null) {
                        return expirationDate.substring(0, 10);
                    } else {
                        return null;
                    }
                }
            },
            {
                title: '转赠时间',
                dataIndex: 'turnToIncreaseTime',
                key: 'turnToIncreaseTime',
                width: 165,
            },
            {
                title: '操作',
                key: 'action',
                width: 80,
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        {
                            this.props.userPermission != null && this.props.userPermission.includes('300100') ? (
                                <a style={{'color': '#d43f3a'}}
                                   onClick={this.showDeleteTransferRecord.bind(this, record)}>删除</a>) : null
                        }

                    </Space>
                ),
            },
        ];
        if (userRole !== 0) {
            columns.splice(3, 2)
        }
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item
                        label="卷编号"
                        name="rollNumber"
                        style={{width: 350, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入兑换卷编号"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="卷名称"
                        name="rollName"
                        style={{width: 250, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入兑换卷名称"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    {
                        userRole !== 0 ? null : (
                            <Form.Item
                                label="转出账户"
                                name="outUser"
                                style={{width: 190, marginTop: '5px', marginBottom: '5px'}}>
                                <Input placeholder="请输入账户"
                                       onChange={this.inputChange}/>
                            </Form.Item>
                        )
                    }
                    <Form.Item
                        label="转入账户"
                        name="inUser"
                        style={{width: 190, marginTop: '5px', marginBottom: '5px'}}>
                        <Input placeholder="请输入账户"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        style={{marginTop: '5px', marginBottom: '5px'}}>
                        <Button type="primary" onClick={this.searchTransferRecord}
                                style={{'marginLeft': '10px'}}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                    </Form.Item>
                </Form>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            卷流程记录
                        </div>
                    }
                    width={1100}
                    visible={this.state.visible}
                    onOk={this.handleCancel}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}>
                    <RollCirculationRecordModel rollNumber={this.state.viewRollNumber}/>
                </Modal>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
            </div>
        )
    }
}

export default TransferRecord;