import React from 'react';
import Util from "../../../utils/utils";
import {Button, Checkbox, Form, Image, Input, message, Select, Table} from "antd";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";

let userRole;
export default class TravelOrderModel extends React.Component {
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        travelName: null,
        status: null,
        remarks: null,
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
        selectedRowKeys: [],
        isExporting: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    resetSearch = () => {
        this.setState({
            travelName: null,
            status: null,
            remarks: null,
        });
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    searchTravelOrder = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.TRAVEL_ORDER.LIST_TRAVEL_ORDER_INFO_URL,
            params: {
                invitationTeamUser: this.props.invitationTeamUser,
                travelName: this.state.travelName,
                status: this.state.status,
                remarks: this.state.remarks,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: PATH_URL.TRAVEL_ORDER.LIST_TRAVEL_ORDER_NO_PAGE_INFO_URL,
                params: {
                    invitationUserRealName: this.state.invitationUserRealName,
                    travelName: this.state.travelName,
                    status: this.state.status,
                    remarks: this.state.remarks,
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    export = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: PATH_URL.TRAVEL_ORDER.EXPORT_TRAVEL_ORDER_INFO_URL + "/" + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '旅游报名订单数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll
        };
        const columns = [
            {
                title: '出行名称',
                dataIndex: 'travelName',
                key: 'travelName',
                fixed: 'left',
                width: 150,
                ellipsis: true,
            },
            {
                title: '出行时间',
                dataIndex: 'travelDate',
                key: 'travelDate',
                width: 120,
            },
            {
                title: '邀请人',
                dataIndex: 'invitationUserRealName',
                key: 'invitationUserRealName',
                width: 120,
            },
            {
                title: '订单总价格',
                dataIndex: 'price',
                key: 'price',
                width: 100,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    switch (status) {
                        case 0:
                            return '未付款';
                        case 1:
                            return '已付款';
                        case 2:
                            return '已出行';
                        case 3:
                            return '已取消';
                        default:
                            return '未付款';
                    }
                }
            },
            {
                title: '付款凭证',
                dataIndex: 'icon',
                key: 'icon',
                render: icon => {
                    if (!Util.isEmpty(icon)) {
                        return (
                            <Image
                                width={15}
                                height={20}
                                src={icon}/>
                        )
                    }
                }
            },
            {
                title: '创建人',
                dataIndex: 'creatorRealName',
                key: 'creatorRealName',
                width: 120,
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 180
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                width: 200,
                ellipsis: true,
            }
        ];
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item
                        label="出行名称"
                        name="travelName"
                        style={{width: 270}}>
                        <Input placeholder="请输入出行名称"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item
                        label="状态"
                        name="status"
                        style={{width: 130}}>
                        <Select onChange={this.statusChange}>
                            <Select.Option value="0">未付款</Select.Option>
                            <Select.Option value="1">已付款</Select.Option>
                            <Select.Option value="2">已出行</Select.Option>
                            <Select.Option value="3">已取消</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remarks"
                        style={{width: 270}}>
                        <Input placeholder="请输入备注"
                               onChange={this.inputChange}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.searchTravelOrder}
                                style={{'marginLeft': '10px'}}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                        {userRole === 0 && this.props.userPermission != null && this.props.userPermission.includes('400010') ? (
                            <Button type="primary" style={{'marginLeft': '5px'}} onClick={this.export}
                                    disabled={this.state.isExporting}>导出</Button>) : null
                        }
                    </Form.Item>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       rowSelection={rowSelection}
                       scroll={{x: 1500}}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <div style={{
                    marginTop: "-38px",
                    marginLeft: "8px",
                    display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                }}>
                    <Checkbox onChange={this.exportAllSelect}
                              checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                </div>
            </div>
        )
    }
}