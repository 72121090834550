import React from 'react'
import {Button, Form, Input, message, Modal, Row, Select, Space, Table} from "antd";
import Axios from "../../../utils/axios";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Util from "../../../utils/utils";

class CashRecord extends React.Component {
    editFormRef = React.createRef();
    state = {
        data: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        userName: null,
        commodityName: null,
        exchangeNumber: null,
        expressCompany: null,
        expressNumber: null,
        status: null,
        editStatus: 0,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    initData = () => {
        Axios({
            method: 'get',
            url: '/cashRecord/listCashRecord',
            params: {
                userName: this.state.userName,
                exchangeNumber: this.state.exchangeNumber,
                commodityName: this.state.commodityName,
                expressCompany: this.state.expressCompany,
                expressNumber: this.state.expressNumber,
                status: this.state.status,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    updateCashRecord = () => {
        this.editFormRef.current.validateFields().then(values => {
            if (values.status === '1') {
                if (Util.isEmpty(values.expressCompany) || Util.isEmpty(values.expressNumber)) {
                    message.error("请填写快递信息！");
                    return;
                }
            }
            Axios({
                method: 'post',
                url: '/cashRecord/updateCashRecord',
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    searchCashRecord = () => {
        this.initData();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    editStatusChange = (value) => {
        this.setState({
            editStatus: value,
        })
    }
    resetSearch = () => {
        this.setState({
            userName: null,
            commodityName: null,
            expressCompany: null,
            expressNumber: null,
            exchangeNumber: null,
            status: null,
        })
    }
    showEditCashRecord = (record) => {
        this.setState({
            editVisible: true,
        })
        // 设置100毫秒的延迟 确保from组件已经加载完毕
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
            this.editFormRef.current.setFieldsValue({
                'status': record.status.toString()
            });
        }, 100);
    }
    showDeleteCashRecord = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该兑换记录？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: '/cashRecord/deleteCashRecord/' + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    handleCancel = () => {
        this.setState({
            editVisible: false,
        });
    }

    render() {
        const layout = {
            labelCol: {span: 5},
            wrapperCol: {span: 19},
        };
        const columns = [
            {
                title: '兑换编号',
                dataIndex: 'exchangeNumber',
                key: 'exchangeNumber',
                fixed: 'left',
                width: 300,
            },
            {
                title: '商品名称',
                dataIndex: 'commodityName',
                key: 'commodityName',
                fixed: 'left',
                width: 100,
            },
            {
                title: '所需积分',
                dataIndex: 'integral',
                key: 'integral',
            },
            {
                title: '用户',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: '姓名',
                dataIndex: 'realName',
                key: 'realName',
            },
            {
                title: '电话',
                dataIndex: 'tel',
                key: 'tel',
            },
            {
                title: '地址',
                dataIndex: 'address',
                key: 'address',
                ellipsis: true,
            },
            {
                title: '快递公司',
                dataIndex: 'expressCompany',
                key: 'expressCompany',
                ellipsis: true,
            },
            {
                title: '快递单号',
                dataIndex: 'expressNumber',
                key: 'expressNumber',
            },
            {
                title: '兑换时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                fixed: 'right',
                width: 100,
                render: status => {
                    switch (status) {
                        case 0:
                            return '待发货';
                        case 1:
                            return '已发货';
                        default:
                            return '待发货';
                    }
                }
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                width: 100,
                render: (text, record) => (
                    <Space size="small">
                        {this.props.userPermission != null && this.props.userPermission.includes('300067') ? (
                            <a onClick={this.showEditCashRecord.bind(this, record)}>修改</a>) : null
                        }
                        {this.props.userPermission != null && this.props.userPermission.includes('300068') ? (
                            <a style={{'color': '#d43f3a'}}
                               onClick={this.showDeleteCashRecord.bind(this, record)}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Row>
                    <Form layout='inline'>
                        <Form.Item
                            label="用户"
                            name="userName"
                            style={{width: 160, marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入账户"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="编号"
                            name="exchangeNumber"
                            style={{width: 150, marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入编号"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="商品"
                            name="commodityName"
                            style={{width: 140, marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入商品"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="快递公司"
                            name="expressCompany"
                            style={{width: 195, marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入快递公司"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="快递单号"
                            name="expressNumber"
                            style={{width: 200, marginTop: '5px', marginBottom: '5px'}}>
                            <Input placeholder="请输入快递单号"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="status"
                            style={{width: 130, marginTop: '5px', marginBottom: '5px'}}>
                            <Select onChange={this.statusChange}>
                                <Select.Option value="0">待发货</Select.Option>
                                <Select.Option value="1">已发货</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{marginTop: '5px', marginBottom: '5px'}}>
                            <Button type="primary" onClick={this.searchCashRecord}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '5px'}}
                                    onClick={this.resetSearch}>重置</Button>
                        </Form.Item>
                    </Form>
                </Row>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            修改
                        </div>
                    }
                    visible={this.state.editVisible}
                    onOk={this.updateCashRecord}
                    onCancel={this.handleCancel}>
                    <Form {...layout}
                          ref={this.editFormRef}>
                        <Form.Item
                            label="兑换记录ID"
                            name="id"
                            hidden="true">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="用户"
                            name="userName">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="姓名"
                            name="realName"
                            rules={[{required: true, message: '请输入姓名'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="电话"
                            name="tel"
                            rules={[{
                                required: true, validator: async (_, value, callback) => {
                                    if (Util.isTel(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确的手机号码');
                                    }
                                }
                            }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="地址"
                            name="address"
                            rules={[{required: true, message: '请输入地址'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="商品"
                            name="commodityName">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="编号"
                            name="exchangeNumber">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="所需积分"
                            name="integral">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="快递公司"
                            name="expressCompany">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="快递单号"
                            name="expressNumber">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="status">
                            <Select onChange={this.editStatusChange}>
                                <Select.Option value="0">待发货</Select.Option>
                                <Select.Option value="1">已发货</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       scroll={{x: 2000}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
            </div>
        )
    }

}

export default CashRecord;