import React from 'react'
import style from "../Content.module.css";
import {message, Tabs} from "antd";
import UserInfo from "./UserInfo";
import OperatorInfo from "./OperatorInfo";
import TeamInfo from "./TeamInfo";
import BusinessInfo from "./BusinessInfo";
import UserOrderInfo from "./UserOrderInfo";
import Axios from "../../../utils/axios";
import MajorAccount from "./MajorAccount";
import TravelTeam from "../tourismManage/TravelTeam";

const {TabPane} = Tabs;

class UserManage extends React.Component {
    state = {
        userPermission: null,
    }

    componentDidMount() {
        this.getUserPermission();
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: '/menu/getUserMenu/' + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Tabs defaultActiveKey="1">
                        {this.state.userPermission != null && this.state.userPermission.includes('200001') ?
                            (<TabPane tab="账户信息" key="1">
                                <UserInfo userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200002') ?
                            (<TabPane tab="线上电呼" key="2">
                                <OperatorInfo userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {
                            this.state.userPermission != null && this.state.userPermission.includes('200003') ?
                                (<TabPane tab="业务团队" key="3">
                                    <TeamInfo userPermission={this.state.userPermission}/>
                                </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200004') ?
                            (<TabPane tab="合作站点" key="4">
                                <BusinessInfo userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200005') ?
                            (<TabPane tab="车主" key="5">
                                <UserOrderInfo userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200013') ?
                            (<TabPane tab="大客户" key="6">
                                <MajorAccount userPermission={this.state.userPermission}/>
                            </TabPane>) : (null)
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200023') ?
                            (<TabPane tab="旅游团队" key="7">
                                <TravelTeam userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default UserManage;