import React from 'react'
import style from "../Home.module.css";
import Header from "../Header";
import LeftMenu from "../LeftMenu";
import Util from "../../../utils/utils";
import OrderInfo from "./OrderInfo";
import {message} from "antd";
import PermissionUtils from "../../../utils/permissionUtils";

class Order extends React.Component {
    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (Util.isEmpty(userName)) {
            this.props.history.push("/login")
        } else {
            let userRole = JSON.parse(window.sessionStorage.getItem("userRole"));
            if (userRole !== 0 && userRole !== 1 && userRole !== 2 && userRole !== 3 && userRole !== 5 && userRole !== 6) {
                message.error("你没有权限访问!");
                this.props.history.push("/home");
            } else if (userRole === 0) {
                PermissionUtils.getUserPermission(this, '100004');
            }
        }
    }

    render() {
        return (
            <div className={style['home']}>
                <Header history={this.props.history}/>
                <LeftMenu history={this.props.history}/>
                <OrderInfo history={this.props.history}/>
            </div>
        )
    }
}

export default Order;