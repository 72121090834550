import React from 'react'
import style from "../Content.module.css";
import Editor from 'wangeditor-antd';
import {Button, Form, Input, message, Modal, Select, Space, Table} from "antd";
import PATH_URL from "../../../utils/url";
import Axios from "../../../utils/axios";
import Util from "../../../utils/utils";
import {ExclamationCircleOutlined} from "@ant-design/icons";

class AgreementInfo extends React.Component {
    addFormRef = React.createRef();
    viewFormRef = React.createRef();
    editFormRef = React.createRef();
    editorRef = React.createRef();
    state = {
        title: null,
        type: null,
        status: null,
        content: null,
        agreementData: null,
        userPermission: null,
        addVisible: false,
        viewVisible: false,
        editVisible: false,
        addGroupSubmitLoading: false,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.getUserPermission();
        }
    }

    getUserPermission = () => {
        Axios({
            method: 'get',
            url: PATH_URL.GET_USER_MENU_URL + "/" + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_AGREEMENT_URL,
            params: {
                title: this.state.title,
                type: this.state.type,
                status: this.state.status,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        agreementData: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    searchAgreementInfo = () => {
        this.setState({
            loading: true,
        });
        this.initData()
    }
    handleCancel = () => {
        this.setState({
            viewVisible: false,
            editVisible: false,
            addVisible: false,
        });
    }
    resetSearch = () => {
        this.setState({
            title: null,
            type: null,
            status: null,
        });
    }
    showAddAgreementInfo = (record) => {
        this.setState({
            addVisible: true,
        });
        setTimeout(() => {
            this.initEditor('');
        }, 100);
    }
    typeChange = (value) => {
        this.setState({
            type: value,
        })
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    showAgreementInfo = (record) => {
        this.setState({
            viewVisible: true,
        })
        setTimeout(() => {
            this.viewFormRef.current.setFieldsValue(record);
            this.viewFormRef.current.setFieldsValue({
                'status': record.status.toString(),
                'type': record.type.toString(),
            });
            this.initEditor(record.content);
        }, 100);
    }
    showEditAgreementInfo = (record) => {
        this.setState({
            editVisible: true,
        })
        setTimeout(() => {
            this.editFormRef.current.setFieldsValue(record);
            this.editFormRef.current.setFieldsValue({
                'status': record.status.toString(),
                'type': record.type.toString(),
            });
            this.initEditor(record.content);
        }, 100);
    }
    showDeleteAgreementInfo = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认删除该协议信息？",
            onOk: () => {
                Axios({
                    method: 'delete',
                    url: PATH_URL.DELETE_AGREEMENT_URL + "/" + record.id,
                    params: {}
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    showChangeAgreementStatus = (record, status) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "确认" + (status === 0 ? "下线" : "发布") + "该协议？",
            onOk: () => {
                Axios({
                    method: 'put',
                    url: PATH_URL.UPDATE_AGREEMENT_STATUS_URL + "/" + record.id,
                    params: {
                        status: status,
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            message.success(res.data.message);
                            this.initData();
                        } else {
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            onCancel: () => {

            },
        });
    }
    addAgreement = () => {
        if (!this.state.addGroupSubmitLoading) {
            this.setState({
                addGroupSubmitLoading: true,
            })
            this.addFormRef.current.validateFields().then(values => {
                if (Util.isEmpty(this.state.content)) {
                    message.error("请输入内容！");
                    return;
                }
                values.content = this.state.content;
                Axios({
                    method: 'post',
                    url: PATH_URL.ADD_AGREEMENT_URL,
                    data: JSON.stringify(values)
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.setState({
                                loading: true,
                                addVisible: false,
                                addGroupSubmitLoading: false,
                            });
                            message.success(res.data.message);
                            this.initData();
                            this.addFormRef.current.resetFields();
                        } else {
                            this.setState({
                                addGroupSubmitLoading: false,
                            });
                            message.error(res.data.message);
                        }
                    } else if (res.code === 401) {
                        this.setState({
                            addGroupSubmitLoading: false,
                        });
                        message.error(res.message);
                    }
                }).catch(err => {
                    this.setState({
                        addGroupSubmitLoading: false,
                    });
                    console.log(err);
                });
            }).catch(info => {
                this.setState({
                    addGroupSubmitLoading: false,
                });
                console.log(info);
            });
        }
    }
    updateAgreement = () => {
        this.editFormRef.current.validateFields().then(values => {
            values.content = this.state.content;
            Axios({
                method: 'post',
                url: PATH_URL.UPDATE_AGREEMENT_URL,
                data: JSON.stringify(values)
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            loading: true,
                            editVisible: false,
                            content: null,
                        });
                        message.success(res.data.message);
                        this.initData();
                        this.editFormRef.current.resetFields();
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }).catch(info => {
            console.log(info);
        });
    }
    initEditor = (html) => {
        const _this = this;
        this.editor = new Editor(this.editorRef.current)
        this.editor.customConfig.menus = [
            'head',  // 标题
            'bold',  // 粗体
            'fontSize',  // 字号
            'fontName',  // 字体
            'italic',  // 斜体
            'underline',  // 下划线
            'strikeThrough',  // 删除线
            'foreColor',  // 文字颜色
            'backColor',  // 背景颜色
            'link',  // 插入链接
            'list',  // 列表
            'justify',  // 对齐方式
            'quote',  // 引用
            'image',  // 插入图片
            'table',  // 表格
            'undo',  // 撤销
            'redo'  // 重复
        ]
        this.editor.customConfig.uploadImgServer = PATH_URL.BASE_URL + "/" + PATH_URL.AGREEMENT_UPLOAD_FILE_URL
        this.editor.customConfig.uploadFileName = 'imageFile'
        // 将图片大小限制为 3M
        this.editor.customConfig.uploadImgMaxSize = 3 * 1024 * 1024
        this.editor.customConfig.onchange = (html) => {
            // html为拿到的在div富文本框中输入的内容（使用样式时会自动带上标签）
            // 如：<p><span style="font-weight: bold;">演示<span style="color: rgb(249, 150, 59);">文本</span></span></p>
            _this.setState({
                content: html,
            })
        }
        this.editor.create();
        this.editor.txt.html(html);
    }

    render() {
        const layout = {
            labelCol: {span: 5},
            wrapperCol: {span: 19},
        };
        const columns = [
            {
                title: '协议标题',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '协议类型',
                dataIndex: 'type',
                key: 'type',
                render: type => {
                    switch (type) {
                        case 0:
                            return '用户条例';
                        case 1:
                            return '隐私条例';
                        default:
                            return '用户条例';
                    }
                }
            },
            {
                title: '协议状态',
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    switch (status) {
                        case 0:
                            return '未发布';
                        case 1:
                            return '已发布';
                        default:
                            return '未发布';
                    }
                }
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <Space size="small">
                        {
                            this.state.userPermission != null && this.state.userPermission.includes('300084') ? (
                                <a onClick={this.showAgreementInfo.bind(this, record)}>查看</a>) : null
                        }
                        {
                            this.state.userPermission != null && this.state.userPermission.includes('300075') ? (
                                <a onClick={this.showEditAgreementInfo.bind(this, record)}>修改</a>) : null
                        }
                        {
                            record.status === 0 ? ((this.state.userPermission != null && this.state.userPermission.includes('300076')) ? (
                                <a style={{'color': '#f5ce0a'}}
                                   onClick={this.showChangeAgreementStatus.bind(this, record, 1)}>发布</a>) : null) : ((this.state.userPermission != null && this.state.userPermission.includes('300077')) ? (
                                <a style={{'color': '#f5ce0a'}}
                                   onClick={this.showChangeAgreementStatus.bind(this, record, 0)}>下线</a>) : null)
                        }
                        {
                            this.state.userPermission != null && this.state.userPermission.includes('300078') ? (
                                <a style={{'color': '#d43f3a'}}
                                   onClick={this.showDeleteAgreementInfo.bind(this, record)}>删除</a>) : null
                        }
                    </Space>
                ),
            },
        ];
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Form layout='inline'>
                        <Form.Item
                            label="协议标题"
                            name="title"
                            style={{width: 220}}>
                            <Input placeholder="请输入协议标题"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="协议类型"
                            name="type"
                            style={{width: 220, marginBottom: '5px'}}>
                            <Select onChange={this.typeChange}>
                                <Select.Option value="0">用户条例</Select.Option>
                                <Select.Option value="1">隐私条例</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="协议状态"
                            name="status"
                            style={{width: 220, marginBottom: '5px'}}>
                            <Select onChange={this.statusChange}>
                                <Select.Option value="0">未发布</Select.Option>
                                <Select.Option value="1">已发布</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.searchAgreementInfo}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {
                                this.state.userPermission != null && this.state.userPermission.includes('300074') ? (
                                    <Button type="primary" style={{'marginLeft': '10px'}}
                                            onClick={this.showAddAgreementInfo}>新增协议</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                新增协议
                            </div>
                        }
                        width={900}
                        destroyOnClose={true}
                        visible={this.state.addVisible}
                        onOk={this.addAgreement}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.addFormRef}>
                            <Form.Item
                                label="协议标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题'}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="协议类型"
                                name="type"
                                rules={[{required: true, message: '请选择类型'}]}>
                                <Select>
                                    <Select.Option value="0">用户条例</Select.Option>
                                    <Select.Option value="1">隐私条例</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="是否发布"
                                name="status"
                                rules={[{required: true, message: '请选择是否发布'}]}>
                                <Select>
                                    <Select.Option value="0">不发布</Select.Option>
                                    <Select.Option value="1">发布</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="协议内容"
                                name="content">
                                <div className='rich-text-field' ref={this.editorRef}></div>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                查看
                            </div>
                        }
                        width={900}
                        destroyOnClose={true}
                        visible={this.state.viewVisible}
                        onOk={this.handleCancel}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.viewFormRef}>
                            <Form.Item
                                label="协议id"
                                name="id"
                                hidden="true">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="协议标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题'}]}>
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                label="协议类型"
                                name="type"
                                rules={[{required: true, message: '请选择类型'}]}>
                                <Select disabled>
                                    <Select.Option value="0">用户条例</Select.Option>
                                    <Select.Option value="1">隐私条例</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="是否发布"
                                name="status"
                                rules={[{required: true, message: '请选择是否发布'}]}>
                                <Select disabled>
                                    <Select.Option value="0">未发布</Select.Option>
                                    <Select.Option value="1">已发布</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="协议内容"
                                name="content">
                                <div className='rich-text-field' ref={this.editorRef}></div>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    cursor: 'move',
                                }}>
                                修改协议
                            </div>
                        }
                        width={900}
                        destroyOnClose={true}
                        visible={this.state.editVisible}
                        onOk={this.updateAgreement}
                        onCancel={this.handleCancel}>
                        <Form {...layout}
                              ref={this.editFormRef}>
                            <Form.Item
                                label="协议id"
                                name="id"
                                hidden="true">
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="协议标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题'}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="协议类型"
                                name="type"
                                rules={[{required: true, message: '请选择类型'}]}>
                                <Select>
                                    <Select.Option value="0">用户条例</Select.Option>
                                    <Select.Option value="1">隐私条例</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="是否发布"
                                name="status"
                                rules={[{required: true, message: '请选择是否发布'}]}>
                                <Select>
                                    <Select.Option value="0">不发布</Select.Option>
                                    <Select.Option value="1">发布</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="内容"
                                name="content">
                                <div className='rich-text-field' ref={this.editorRef}></div>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Table columns={columns}
                           size="small"
                           dataSource={this.state.agreementData}
                           rowKey={columns => columns.id}
                           loading={this.state.loading}
                           style={{'marginTop': '20px'}}
                           pagination={{
                               showTotal: () => `共 ${this.state.total} 条`,
                               onShowSizeChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               },
                               total: this.state.total,
                               current: this.state.current,
                               pageSize: this.state.pageSize,
                               onChange: (current, pageSize) => {
                                   this.setState({
                                       current: current,
                                       pageSize: pageSize
                                   });
                                   setTimeout(() => {
                                       this.initData();
                                   }, 100);
                               }
                           }}/>
                </div>
            </div>
        )
    }
}

export default AgreementInfo;