import React from 'react'
import {Button, Form, message, Modal, Select, Space, Table} from "antd";
import Util from "../../../utils/utils";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import PerformanceDetails from "./PerformanceDetails";

class OrderSummaryTab extends React.Component {
    state = {
        groupName: null,
        status: null,
        loading: true,
        current: 1,
        pageSize: 10,
        total: 0,
        data: null,
        allGroupUserData: null,
        visible: false,
        groupUser: null,
        year: null,
        month: null,
    }

    componentDidMount() {
        let userName = (this.props.userName === undefined || this.props.userName === null) ?
            JSON.parse(window.sessionStorage.getItem("userName")) : this.props.userName;
        if (!Util.isEmpty(userName)) {
            this.initData();
            this.initGroupUserDate();
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    initGroupUserDate = () => {
        let userName = (this.props.userName === undefined || this.props.userName === null) ?
            JSON.parse(window.sessionStorage.getItem("userName")) : this.props.userName;
        console.log(userName)
        Axios({
            method: 'get',
            url: PATH_URL.LIST_GROUP_USER + "/" + userName,
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    let other = {};
                    other.userName = (this.props.userName === undefined || this.props.userName === null) ?
                        JSON.parse(window.sessionStorage.getItem("userName")) : this.props.userName;
                    other.groupName = "其他";
                    let data = res.data.data;
                    data.push(other);
                    this.setState({
                        allGroupUserData: data
                    });
                } else {
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                message.error(res.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    initData = () => {
        Axios({
            method: 'get',
            url: PATH_URL.LIST_ORDER_SUMMARY_URL,
            params: {
                orderBy: (this.props.userName === undefined || this.props.userName === null) ?
                    JSON.parse(window.sessionStorage.getItem("userName")) : this.props.userName,
                groupName: this.state.groupName,
                status: this.state.status,
                year: this.state.year,
                month: this.state.month,
                pageNum: this.state.current,
                pageSize: this.state.pageSize
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    searchOrderSummary = () => {
        this.setState({
            loading: true,
        })
        this.initData();
    }
    resetSearch = () => {
        this.setState({
            groupName: null,
            status: null,
            year: null,
            month: null
        })
    }
    groupChange = (value, option) => {
        this.setState({
            groupName: value,
        })
    }
    statusChange = (value) => {
        this.setState({
            status: value,
        })
    }
    userOrderInfo = (record) => {
        this.setState({
            groupUser: record.userName,
            visible: true,
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }
    onDatePickerYearChange = (value, dateString) => {
        this.setState({
            year: dateString
        })
    }
    monthChange = (value) => {
        this.setState({
            month: value
        })
    }

    render() {
        const columns = [
            {
                title: '组名',
                dataIndex: 'groupName',
                key: 'groupName',
                render: groupName => {
                    if (Util.isEmpty(groupName)) {
                        return "其他";
                    } else {
                        return groupName;
                    }
                }
            },
            {
                title: '账户',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: '已完成订单',
                dataIndex: 'sumCompleted',
                key: 'sumCompleted',
            },
            {
                title: '已取消订单',
                dataIndex: 'sumCanceled',
                key: 'sumCanceled',
            },
            {
                title: '注册时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 170
            },
            {
                title: '用户状态',
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    switch (status) {
                        case 0:
                            return '启用中';
                        case 1:
                            return '已禁用';
                        default:
                            return '启用中';
                    }
                }
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        <a onClick={this.userOrderInfo.bind(this, record)}>明细</a>
                    </Space>
                ),
            }
        ]
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item
                        label="所属组"
                        name="groupUser"
                        style={{width: 250}}>
                        <Select onChange={this.groupChange}
                                showSearch>
                            {
                                this.state.allGroupUserData != null ?
                                    this.state.allGroupUserData.map(item => {
                                        return (<Select.Option key={item.userName}
                                                               value={item.groupName}>{item.groupName}</Select.Option>)
                                    }) : null
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="组员状态"
                        name="status"
                        style={{width: 200}}>
                        <Select onChange={this.statusChange}>
                            <Select.Option value="0">启用中</Select.Option>
                            <Select.Option value="1">已禁用</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.searchOrderSummary}>查询</Button>
                        <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                onClick={this.resetSearch}>重置</Button>
                    </Form.Item>
                </Form>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.userName}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            业绩明细
                        </div>
                    }
                    visible={this.state.visible}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    width={650}>
                    <PerformanceDetails userName={this.state.groupUser}
                                        orderBy={(this.props.userName === undefined || this.props.userName === null) ?
                                            JSON.parse(window.sessionStorage.getItem("userName")) : this.props.userName}/>
                </Modal>
            </div>
        )
    }
}

export default OrderSummaryTab;