import React from 'react'
import style from "../Content.module.css";
import {message, Tabs} from "antd";
import ServiceSiteInfo from "./ServiceSiteInfo";
import Axios from "../../../utils/axios";
import PATH_URL from "../../../utils/url";
import ExchangeOrderInfo from "./ExchangeOrderInfo";

const {TabPane} = Tabs;

class ServiceSiteTab extends React.Component {
    state = {
        activeKey: 1,
        userPermission: null,
    }

    componentDidMount() {
        this.getUserPermission();
    }

    onChange = (key) => {
        this.setState({
            activeKey: key
        })
    }
    getUserPermission = () => {
        Axios({
            method: 'get',
            url: PATH_URL.GET_USER_MENU_URL + "/" + JSON.parse(window.sessionStorage.getItem("userName")),
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    userPermission: res.data.data,
                });
            } else {
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <div className={style['content']} id='content'>
                <div className={style['content_info']}>
                    <Tabs defaultActiveKey="1" onChange={this.onChange}>
                        {this.state.userPermission != null && this.state.userPermission.includes('200014') ? (
                            <TabPane tab="网点管理" key="1">
                                <ServiceSiteInfo userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                        {this.state.userPermission != null && this.state.userPermission.includes('200015') ? (
                            <TabPane tab="订单管理" key="2">
                                <ExchangeOrderInfo userPermission={this.state.userPermission}/>
                            </TabPane>) : null
                        }
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default ServiceSiteTab;