import React from 'react'
import Util from "../../../utils/utils";
import {Button, Checkbox, Form, Input, message, Row, Select, Table} from "antd";
import Axios from "../../../utils/axios";

const qs = require('qs');

class Integral extends React.Component {
    state = {
        data: null,
        roleList: [],
        rolePermission: [],
        current: 1,
        pageSize: 10,
        total: 0,
        loading: true,
        userName: null,
        selectedRowKeys: [],
        isExporting: false,
        selected: [],
        checkboxText: '全选',
        isCheckAll: false,
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    componentDidMount() {
        let userName = JSON.parse(window.sessionStorage.getItem("userName"));
        const rolePermission = this.state.rolePermission;
        if (this.props.userPermission.includes('300053')) {
            rolePermission.push(1)
        }
        if (this.props.userPermission.includes('300054')) {
            rolePermission.push(2)
        }
        if (this.props.userPermission.includes('300055')) {
            rolePermission.push(4)
        }
        this.setState({
            rolePermission: rolePermission
        })
        if (!Util.isEmpty(userName)) {
            this.initData();
        }
    }

    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    exportAllSelect = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        })
        if (e.target.checked) {
            Axios({
                method: 'get',
                url: '/userIntegral/listUserIntegralIds',
                params: {
                    userName: this.state.userName,
                    realName: this.state.realName,
                    roleList: this.state.roleList.length === 0 ? (this.state.rolePermission.length === 0 ? [-1] : this.state.rolePermission) : this.state.roleList,
                },
                paramsSerializer: function (params) {
                    return qs.stringify(params, {arrayFormat: 'repeat'})
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.setState({
                            selectedRowKeys: res.data.data.sort(function (a, b) {
                                return a - b
                            }),
                            checkboxText: '取消全选',
                        });
                    } else {
                        message.error(res.data.message);
                    }
                } else if (res.code === 401) {
                    message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const selected = this.state.selected;
            this.setState({
                selectedRowKeys: selected,
                checkboxText: '全选',
            });
        }
    }
    initData = () => {
        Axios({
            method: 'get',
            url: '/userIntegral/listUserIntegral',
            params: {
                userName: this.state.userName,
                realName: this.state.realName,
                roleList: this.state.roleList.length === 0 ? (this.state.rolePermission.length === 0 ? [-1] : this.state.rolePermission) : this.state.roleList,
                pageNum: this.state.current,
                pageSize: this.state.pageSize,
            },
            paramsSerializer: function (params) {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    this.setState({
                        data: res.data.data.list,
                        loading: false,
                        total: res.data.data.total,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    message.error(res.data.message);
                }
            } else if (res.code === 401) {
                this.setState({
                    loading: false,
                });
                message.error(res.message);
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    resetSearch = () => {
        this.setState({
            userName: null,
            realName: null,
            roleList: [],
        });
    }
    searchIntegralInfo = () => {
        this.setState({
            loading: true,
        });
        this.initData();
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item) < 0) {
                    selected.push(item);
                }
            });
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
        }
    }
    exportIntegralInfo = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error("请选择要导出的数据!");
        } else {
            this.setState({
                loading: true,
                isExporting: true,
            })
            Axios({
                method: 'get',
                url: '/userIntegral/exportIntegralInfo/' + this.state.selectedRowKeys,
                responseType: 'blob'
            }).then(res => {
                if (res.status === 200) {
                    const blob = new Blob([res.data])
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '用户积分数据.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                } else if (res.code === 401) {
                    message.error(res.message);
                    this.setState({
                        loading: false,
                        isExporting: false,
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    isExporting: false,
                })
            });
        }
    }
    roleChange = (value) => {
        const roleList = [];
        roleList.push(value);
        this.setState({
            roleList: roleList,
        })
    }

    render() {
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll,
        };
        const columns = [
            {
                title: '积分账户',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: '姓名',
                dataIndex: 'realName',
                key: 'realName',
            },
            {
                title: '角色',
                dataIndex: 'role',
                key: 'role',
                render: role => {
                    switch (role) {
                        case 1:
                            return '线上电呼';
                        case 2:
                            return '业务团队';
                        case 4:
                            return '车主';
                        default:
                            return '线上电呼';
                    }
                }
            },
            {
                title: '可用积分',
                dataIndex: 'availableIntegral',
                key: 'availableIntegral',
            },
            {
                title: '不可用积分',
                dataIndex: 'executedIntegral',
                key: 'executedIntegral',
            }
        ];
        return (
            <div>
                <Row>
                    <Form layout='inline'>
                        <Form.Item
                            label="积分账户"
                            name="userName"
                            style={{width: 200}}>
                            <Input placeholder="请输入账户"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        <Form.Item
                            label="姓名"
                            name="realName"
                            style={{width: 200}}>
                            <Input placeholder="请输入姓名"
                                   onChange={this.inputChange}/>
                        </Form.Item>
                        {this.state.rolePermission != null && this.state.rolePermission.length > 1 && this.props.userPermission != null && this.props.userPermission.includes('300052') ? (
                            <Form.Item
                                label="角色"
                                name="role"
                                style={{width: 200}}>
                                <Select onChange={this.roleChange}>
                                    {this.props.userPermission != null && this.props.userPermission.includes('300053') ? (
                                        <Select.Option value="1">线上电呼</Select.Option>) : null
                                    }
                                    {this.props.userPermission != null && this.props.userPermission.includes('300054') ? (
                                        <Select.Option value="2">业务团队</Select.Option>) : null
                                    }
                                    {this.props.userPermission != null && this.props.userPermission.includes('300055') ? (
                                        <Select.Option value="4">车主</Select.Option>) : null
                                    }
                                </Select>
                            </Form.Item>) : null
                        }
                        <Form.Item>
                            <Button type="primary" onClick={this.searchIntegralInfo}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={this.resetSearch}>重置</Button>
                            {this.props.userPermission != null && this.props.userPermission.includes('300072') ? (
                                <Button type="primary" style={{'marginLeft': '5px'}}
                                        onClick={this.exportIntegralInfo}
                                        disabled={this.state.isExporting}>导出</Button>) : null
                            }
                        </Form.Item>
                    </Form>
                </Row>
                <Table columns={columns}
                       size="small"
                       dataSource={this.state.data}
                       rowKey={columns => columns.id}
                       rowSelection={rowSelection}
                       loading={this.state.loading}
                       style={{'marginTop': '20px'}}
                       pagination={{
                           showTotal: () => `共 ${this.state.total} 条`,
                           onShowSizeChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           },
                           total: this.state.total,
                           current: this.state.current,
                           pageSize: this.state.pageSize,
                           onChange: (current, pageSize) => {
                               this.setState({
                                   current: current,
                                   pageSize: pageSize
                               });
                               setTimeout(() => {
                                   this.initData();
                               }, 100);
                           }
                       }}/>
                <div style={{
                    marginTop: "-38px",
                    marginLeft: "15px",
                    display: (this.state.data === null || this.state.data.length === 0) ? 'none' : ''
                }}>
                    < Checkbox onChange={this.exportAllSelect}
                               checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                </div>
            </div>
        )
    }
}

export default Integral;